import { Box, Typography } from '@mui/material';
import { Subscriptions } from 'modules/admin/subscriptions';
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom";
import { adminGetCustomerSubscription } from 'modules/admin/_redux/admin/adminActions';
import { memo } from 'react';

const CustomerSubScriptionsMemo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location
  const memoizedState = useMemo(() => state, [state])
  const { customer_subscriptions, actionsLoading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(adminGetCustomerSubscription(memoizedState.id))
  }, [dispatch, memoizedState])
  return (
    <div
      className="dashboard-container"
    >
      <Box style={{ marginBottom: '2rem' }}>
        <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}>Customer: {state.domain}</Typography>
        <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}>Subscriptions List</Typography>
      </Box>
      <div className='super-customer'>
        <Subscriptions customer_id={memoizedState.id} customer_subscriptions={customer_subscriptions} actionsLoading={actionsLoading} />
      </div>
    </div>
  );
};

export const CustomerSubScriptions = memo(CustomerSubScriptionsMemo);
