import { axiosInstance } from 'helpers/base.service';

export const SUPPORT = 'support';


//fetch customer 
export function supportsTicketsFetched({page, rowsPerPage}) {
  return axiosInstance().get(`${SUPPORT}/?page=${page}&size=${rowsPerPage}`);
}

//add ticket 
export function supportTicketAdd(payload) {
  return axiosInstance().post(`${SUPPORT}/add`, payload);
}

//add ticket 
export function supportTicketMarkAsRead(payload) {
  return axiosInstance().patch(`${SUPPORT}/actions/read/${payload}`);
}

export function supportTicketMarkAsResolved(payload) {
  return axiosInstance().patch(`${SUPPORT}/actions/close/${payload}`);
}



export function supportTicketMarkInProgress(id) {
  return axiosInstance().patch(`${SUPPORT}/actions/in-progress/${id}`);
}
