import { useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress, DialogContent } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import close from '../../../assets/images/pret.svg';
import { modalActions } from 'modules/modals';
import { adminGetSubscriptionDetails } from '../_redux/admin/adminActions';

function ViewSubscription(props) {
    const { data  :{row: {domain, id}, customer_id} } = props;
    const dispatch = useDispatch();
    const {
        subscription, actionsLoading,
    } = useSelector(
        (state) => ({
            subscription: state.admin.customer_subscription,
            actionsLoading: state.admin.actionsLoading,
        }),
        shallowEqual,
    );


    useEffect(() => {
        dispatch(adminGetSubscriptionDetails(customer_id, id));
    }, [customer_id, dispatch, id]);

    return (
        <div className="site-modal">
            <div
                className="site-header"
            >
                <Typography
                    sx={{
                        position: 'absolute', right: 0, padding: ' 25px 25px ', cursor: 'pointer',
                    }}
                >
                    <img onClick={() => dispatch(modalActions.hideModal())} src={close} alt="close button" />
                </Typography>
                <DialogTitle
                    style={{
                        fontSize: '36px', fontWeight: '700', lineHeight: '0.9', padding: '25px 15px 0', textAlign: 'center',
                    }}
                    id="scroll-dialog-title"
                >{subscription.companyName}
                </DialogTitle>
                <DialogTitle
                    style={{
                        fontSize: '18px', fontWeight: '300', padding: '5px 15px 5px', textAlign: 'center',
                    }}
                    id="scroll-dialog-title"
                >{domain}
                </DialogTitle>
            </div>
            {
                actionsLoading
                && <CircularProgress />}
            {
                !actionsLoading
                && (
                    <DialogContent>
                        <Box sx={{ display: 'flex', borderTop: '1px solid #c4c4c4', paddingTop: '30px' }}>
                            <div style={{ width: '33%', fontSize: '15px' }}>
                                <p>Payment Plan</p>
                                <p style={{ fontWeight: 'bold' }}>{subscription.paymentPlan || ''}</p>
                            </div>
                            <div style={{
                                width: '33%', alignItems: 'center', textAlign: 'center', fontSize: '15px',
                            }}
                            >
                                <p style={{ fontWeight: 'bold' }}>Licences</p>
                                <p>{subscription.licences} assigned</p>
                            </div>
                            <div style={{ width: '33%', fontSize: '15px' }}>
                                <p style={{ fontWeight: 'bold' }}>Estimated Monthly Cost</p>
                                <p>{subscription.costPerMonth} USD per month</p>
                                <p style={{ fontWeight: 'bold' }}>Plan Status: <span>{subscription.active ? 'Active' : 'Inactive'}</span></p>
                            </div>
                        </Box>
                        <Box sx={{ display: 'flex', borderTop: '1px solid #c4c4c4', paddingTop: '30px' }}>
                            <div style={{ width: '33%', fontSize: '15px' }}>
                                <p>Billing Details</p>
                                <p style={{ fontWeight: 'bold' }}>Next Billing Date</p>
                                <p>{subscription.nextBillingDate}</p>
                            </div>
                            <div style={{
                                width: '33%', alignItems: 'center', textAlign: 'center',
                            }}
                            >
                                <p style={{ fontWeight: 'bold' }}>Payment account ID</p>
                                <p>{subscription.accountId}</p>
                            </div>
                            <div style={{ width: '33%' }}>
                                <Link state={{ id: customer_id, domain: domain }} to={`/dashboard/customers/transactions/${customer_id}`}>View customer transactions</Link>
                            </div>
                        </Box>
                    </DialogContent>)
            }
        </div>
    );
}

export default ViewSubscription;
