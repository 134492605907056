import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './index.scss';
import { makeStyles } from '@mui/styles';
import TransferAccount from './TransferAccount';
import GoogleWorkspace from '../../../assets/images/googleWorkspace.svg';
import AddGoogleWorkspace from './AddGoogleWorkspace';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { modalActions } from 'modules/modals';
import { setGlobalValue } from '../_redux/accounts/accountsActions';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.5rem 61px',
    borderRadius: '10px',
    background: '#fff',
    boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.15)',
    margin: '0 auto',
    width: (props) => `${props?.registrationTab ? '35%' : '60%'}`,
    marginTop: '4rem',
    marginBottom: '4rem',
    '@media (min-width: 568px)': {
      padding: '0rem 20px',
    },
  },
  tabs: {
    '& .MuiButtonBase-root.MuiTab-root': {
      fontSize: 20,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .Mui-selected': {
    },
  },
  tabTwo: {
    '&.MuiButtonBase-root.MuiTab-root': {
      backgroundColor: 'green',
    },
  },
  tabThree: {
    '&.MuiButtonBase-root.MuiTab-root': {
      backgroundColor: 'yellow',
    },
  },
  tabPanel: {
    backgroundColor: 'rgba(1,1,1,0.1)',
    paddingTop: 12,
  },
}));

export default function Account({
  formField, setFieldValue, values, errors,
}) {
  const [value, setValue] = React.useState(0);

  const { changeTab, setGlobalValue: settab } = useSelector((state) => state.workspace);
  const dispatch = useDispatch()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value) {
      dispatch(setGlobalValue(value))
    }
  }, [value, dispatch])

  useEffect(() => {
    if (settab) {
      setValue(settab);
      dispatch(modalActions.hideModal())
    }
  }, [dispatch, settab])

  const [registrationTab, setRegistrationTab] = useState(false);
  const props = { registrationTab };
  const classes = useStyles(props);

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Box>
        <Tabs className="global-tabs" style={{ border: 'none', justifyContent: 'center' }} value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            className={classes.tabs}
            label={<img src={GoogleWorkspace} alt="googleworkspace" />}
            {...a11yProps(0)}
            onClick={() => {
              setRegistrationTab(true)
              setValue(0)
            }}
          />
          <Tab disabled={!changeTab} className={classes.tabs} label="Transfer Account" {...a11yProps(1)} onClick={() => {
            setRegistrationTab(false)
            setValue(1)
          }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} style={{ textAlign: 'left' }}>
        <AddGoogleWorkspace errors={errors} setFieldValue={setFieldValue} values={values} formField={formField} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TransferAccount errors={errors} setFieldValue={setFieldValue} values={values} formField={formField} />
      </TabPanel>
    </Box>
  );
}
