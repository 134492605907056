import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { confirmUserUsingToken } from '../_redux/authActions';

const Styles = makeStyles(() => ({
  wrapper: {
    paddingTop: '16rem',
    width: '700px',
    maxWidth: '700px',
    height: '90vh',
    '@media (max-width: 568px)': {
      paddingTop: '6rem',
      paddingBottom: '1rem',
    },
  },
}));

function ConfirmPageOnEmailVerification() {
  const classes = useAuthStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const cl = Styles();

  const token = location.search.substring(5);

  const { actionsLoading } = useSelector((state) => state.oauth);

  useEffect(() => {
    if(token){
      dispatch(confirmUserUsingToken({ token }));
    }
  }, [location, token, dispatch]);

  return (
    <Container width="700px" maxWidth="700px" className={cl.wrapper}>
      <Box className={classes.root}>
        <ApiMessages />
        <SuccessApiMessages/>
        <center>
          <br />
          {actionsLoading &&<p>Redirecting ...</p>}
        </center>
      </Box>
    </Container>
  );
}

export default ConfirmPageOnEmailVerification;
