import * as Yup from 'yup';
import FormModel from './FormModel';

const {
  formField: {
    email,
    password,
    country,
    companyName,
    firstName,
    lastName,
  },
} = FormModel;

const Models = [
  Yup.object().shape({
    email: Yup
      .string('Enter s email')
      .email('Enter a valid email')
      .required(`${email.requiredErrorMsg}`),
    password: Yup
      .string('Enter your password')
      .required(`${password.requiredErrorMsg}`)
      .min(8, 'Password is too short - should be 8 chars minimum.'),
    verifyPassword: Yup
      .string()
      .equals([Yup.ref('password'), null], 'Passwords must match')
      .required('Password Confirmation is required'),
    phoneNumber: Yup
      .string()
      .required('Phone Number is required'),
  }),
  Yup.object().shape({
    companyName: Yup
      .string('Enter your companyName')
      .required(`${companyName.requiredErrorMsg}`),
    country: Yup
      .string('Enter your country')
      .required(`${country.requiredErrorMsg}`),
    city: Yup.string().when('country', {
      is: (country) => country?.length > 0,
      then: Yup.string().required('City is required'),
    }),
    domainName: Yup
      .string()
      .required('Domain name is required'),
    postalCode: Yup
      .string()
      .required('Postal Code is required'),
    streetAddressOne: Yup
      .string()
      .required('Street Address One is required'),
    streetAddressTwo: Yup
      .string()
      .required('Street Address Two is required'),
  }),
  Yup.object().shape({
    title: Yup
      .string('Enter your Title')
      .max(20),
    contactfirstName: Yup
      .string('Enter your First Name')
      .required(`${firstName.requiredErrorMsg}`),
    contactlastName: Yup
      .string('Enter your Last Name')
      .required(`${lastName.requiredErrorMsg}`),
    contactEmail: Yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required(`${email.requiredErrorMsg}`),
    alternativeEmail: Yup
      .string()
      .required('Alternative Email is required'),
  }),
];

export default Models;
