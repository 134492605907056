import {  handleResponseError } from 'helpers/base.service';
import * as requestFromServer from './authCrud';
import { oauthenticationSlice, callTypes } from './authSlice';
import qs from 'qs';



const { actions } = oauthenticationSlice;


export const showApiMessages = (queryParams) => (dispatch) => {
  dispatch(actions.startShowApiMessages({ callType: callTypes.action }));
  requestFromServer
    .showApiMessages(queryParams)
    .then((res) => {
      const { response } = res;

      if (!response) {
        return res.toString();
      }

      const { data } = response;

      if (!data) {
        return res.toString();
      }
      const { messages } = data;
      dispatch(actions.showApiMessages(messages || data?.error));
    })
    .catch((error) => {
      dispatch(actions.showApiMessages());
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const successShowApiMessages = (queryParams) => (dispatch) => {
  dispatch(showApiMessages())
  dispatch(actions.startShowApiMessages({ callType: callTypes.action }));
  requestFromServer
    .successShowApiMessages(queryParams)
    .then((res) => {
      const { data } = res;
      const { messages } = data;
      dispatch(actions.successShowApiMessages(messages));
    })
    .catch((error) => {
      dispatch(actions.successShowApiMessages());
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const register = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .register(queryParams)
    .then(response => {
      if (response.data.status_code === 200 || response.data.status_code === 201) {
        setTimeout(() => {
          window.location.href = '/signup/success';
        }, 1000);
      }
      dispatch(successShowApiMessages(response))
      dispatch(actions.register(response.data));
    })
    .catch((error) => {
      handleResponseError(error)
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const login = (queryParams) => (dispatch) => {
  const payloadData = qs.stringify({
    ...queryParams,
    grant_type: 'password',
  });
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .login(payloadData)
    .then(response => {
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem('user', JSON.stringify(response.data));
        window.location.href = '/dashboard';
      }
      dispatch(successShowApiMessages(response))
      dispatch(actions.loginUser(response.data));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      handleResponseError(error)
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const googleLogin = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .googleLogin(queryParams)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        localStorage.setItem('user', JSON.stringify(res?.data));
        if (res.data?.role_titles.includes('Super Administrator')) {
          window.location.href = '/dashboard';
        } else if (res.data?.role_titles.includes('Administrator')) {
          window.location.href = '/dashboard';
        }
      }
      dispatch(actions.googleLogin(res.data));
    })
    .catch((error) => {
      handleResponseError(error)
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getCurrentUserProfile = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .getCurrentUserProfile()
    .then(response => {
      dispatch(actions.currentUserProfile(response.data.data));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      handleResponseError(error)
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const googleSignupWithAdditionalData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .googleSignupWithAdditionalData(queryParams)
    .then((res) => {
      const { data } = res.data;
      dispatch(actions.googleSignup({ data }));
      setTimeout(() => {
        window.location.href = `/dashboard`;
      }, 1000);
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const forgotPassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .forgotPassword(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.forgotPassword({ data }));
      dispatch(successShowApiMessages(response))
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const resendOtp = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .resendOtp(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.resendOtp({ data }));
      setTimeout(() => {
        window.location.href = '/signup/success';
      }, 2000);
      dispatch(successShowApiMessages(response))
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const resetPassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .resetPassword(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.resetPassword({ data }));
      dispatch(successShowApiMessages(response))
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setPassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .setPassword(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(successShowApiMessages(response))
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
      dispatch(actions.setPassword({ data }));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const confirmUser = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .confirmUser(queryParams)
    .then((response) => {
      const { data } = response.data;
      if (response.status === 200 || response.status === 201) {
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
        dispatch(successShowApiMessages(response))

      }
      dispatch(actions.confirmUser({ data }));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const confirmUserUsingToken = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .confirmUserUsingToken(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(successShowApiMessages(response))
      dispatch(actions.confirmUserUsingToken({ data }));
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const profileEdited = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editProfile(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(getCurrentUserProfile());
      dispatch(actions.profileEdited({ data }));
      dispatch(successShowApiMessages(response))
    })
    .catch((error) => {
       handleResponseError(error)
       dispatch(showApiMessages(error))
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
