import {
  Box, Button, Card, CircularProgress, Grid, Link, Typography,
} from '@mui/material';
import { getAllSubscriptions } from 'modules/adduser/_redux/accounts/accountsActions';
import { checkWalletBalance } from 'modules/payment/wallet/_redux/walletsActions';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import foldericon from 'assets/images/design/icon.svg';
import yourplanicon from 'assets/images/design/plan.svg';
import { Link as LinkTo } from 'react-router-dom';
import PlanTable from '../Table/table';
import DashboardCard, { CardText } from './card';
import { modalActions } from 'modules/modals';
import Tooltip from 'components/tooltip';

function AdminDashboard() {
  const currentUser = useSelector((state) => state.oauth);
  const dispatch = useDispatch();
  const { currentUserProfile, actionsLoading : userloading } = currentUser;

  const {
    actionsLoading, subscriptions,
  } = useSelector(
    (state) => ({
      actionsLoading: state.workspace.actionsLoading,
      subscriptions: state.workspace.subscriptions,
      lastError: state.workspace.lastError,
    }),
    shallowEqual,
  );

  const {
    wallet: { walletBalance },
  } = useSelector(
    (state) => ({
      walletBalanceLoading: state.wallet.actionsLoading,
      wallet: state.wallet,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (currentUserProfile !== null) {
      const { progressState } = currentUserProfile;
      switch (progressState) {
        case 'PendingPhoneNumber':
          window.location.href = '/google-signup';
          break;
        default:
          break;
      }
    }
  }, [currentUserProfile]);

  // get user details
  useEffect(() => {
    dispatch(getAllSubscriptions());
  }, [dispatch]);
  useEffect(() => {
    dispatch(checkWalletBalance());
  }, [dispatch]);

  return (
    <Box>
      <Box style={{ padding: '2rem 0', margin: '10px 15px 0 0',}} >
        <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}>Your</Typography>
        <Typography style={{ fontSize: '1.2em', color: '#000' }}>Dashboard</Typography>
      </Box>
      {userloading && <CircularProgress />}
      {currentUserProfile?.companyName === null && (
        <>
          <Typography style={{ fontSize: '1.2em' }}>You've not created a workspace yet</Typography>
          <br />
          <LinkTo to="/onboarding?q=2">
            <Button variant="outlined">Create WorkSpace</Button>
          </LinkTo>
        </>
      )}
      {currentUserProfile?.companyName !== null && !userloading && (
        <Grid item container md={12} xs={12} style={{ alignItems: 'stretch', width: '100%' }}>
          <Grid item md={4} className="main-card-style" style={{ alignItems: 'stretch', display: 'flex', width: '100%' }}>
            <Card
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 15px 0 0',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <DashboardCard
                title="Cloud Wallet"
                children={
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="dash-icon"><img style={{ width: '140%' }} alt="" src={foldericon} /></div>
                    <CardText title={`$${walletBalance.balance !== undefined ? walletBalance?.balance : ''}`} subTitle="Balance" marginRight="1rem" marginLeft={0} />
                    <CardText title={`${walletBalance?.currentExpenditure !== undefined ? walletBalance?.currentExpenditure : '' } ${walletBalance?.currency ? walletBalance?.currency : ''}`} subTitle="Expenditure" marginRight={0} marginLeft="1rem" />
                  </Box>
                }
                styleButtonJustify="end"
                buttons={
                  <LinkTo to="/my-wallet" style={{ textDecoration: 'none' }}>
                    <Button
                      size="small"
                      style={{
                        border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
                      }
                      }
                    >
                      View Wallet
                    </Button>
                  </LinkTo>
                }
              />
            </Card>
          </Grid>
          <Grid item xs md={4} style={{ alignItems: 'stretch', display: 'flex' }}>
            <Card
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 15px 0 0',
                fontWeight: '400',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <DashboardCard
                title={currentUserProfile?.companyName}
                subheader={`${subscriptions[0]?.domain === undefined ? '' : subscriptions[0]?.domain }`}
                children={
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardText center title={`${subscriptions[0]?.editionLabel === undefined ? '' : subscriptions[0]?.editionLabel}`} subTitle="Type: Daily" marginRight="1rem" marginLeft={0} />
                    <CardText center title="Due" subTitle={subscriptions !== undefined ? subscriptions[0]?.dueDate : '__' } marginRight={0} marginLeft="1rem" />
                  </Box>
                }
                styleButtonJustify="space-between"
                buttons={
                  currentUserProfile?.progressState === 'PurchaseEdition' ? (
                    <>
                      <Typography>Finish up the purchase <Tooltip tip="You might have to wait for atleast 20mins, then make sure to check your email for any actions"></Tooltip> </Typography>
                      <Button size="small" onClick={() => dispatch(modalActions.workspace_status())} style={{ border: 'thin solid #00ACFF', color: '#00ACFF' }}>Purchase Plan</Button>
                    </>
                  ) : (
                    <>
                      <LinkTo to="/edit/plan" state={{ upgrade: true }} style={{ textDecoration: 'none' }}>
                        <Button
                          size="small"
                          style={{
                            border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
                          }}
                        >Upgrade Plan
                        </Button>
                      </LinkTo>
                      <LinkTo to={subscriptions[0]?.editionLabel === 'Business Starter' ? `#` : '/edit/plan'} state={{ upgrade: false }} style={{ textDecoration: 'none' }}>
                        <Button
                          size="small"
                          disabled={subscriptions[0]?.editionLabel === 'Business Starter'}
                          style={{
                            border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
                          }}
                        >Downgrade Plan
                        </Button>
                      </LinkTo>
                    </>
                  )
                }
              />
            </Card>
          </Grid>
          <Grid item xs md={4} style={{ alignItems: 'stretch', display: 'flex' }}>
            <Card
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 0 0 0',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <DashboardCard
                title="Your plan"
                // eslint-disable-next-line
                children={
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="dash-icon"><img style={{ width: '140%' }} alt="" src={yourplanicon} /></div>
                    <CardText title={subscriptions[0]?.licences} subTitle="License(s)" />
                    <CardText title={currentUserProfile?.users || 0} subTitle="User(s)" />
                  </Box>
                }
                styleButtonJustify="space-between"
                buttons={
                  <>
                    <LinkTo to={`${subscriptions.length === 0 ? '/pricing' : '/subscription'}`} style={{ textDecoration: 'none' }}>
                      <Button
                        size="small"
                        disabled={subscriptions.length === 0}
                        style={{
                          border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
                        }}
                      >View Details
                      </Button>
                    </LinkTo>
                    <Link href="https://admin.google.com/" style={{ textDecoration: 'none' }} target="_blank">
                      <Button
                        size="small"
                        style={{
                          border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
                        }}
                      >Admin Console
                      </Button>
                    </Link>
                  </>
                }
              />
            </Card>
          </Grid>
          <Grid item xs md={12} style={{ alignItems: 'stretch', display: 'flex', paddingTop: '30px' }}>
            <Card
              style={{
                display: 'block',
                width: '100%',
                margin: '0 0 100px 0',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <PlanTable actionsLoading={actionsLoading} subscriptions={subscriptions} />
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default AdminDashboard;
