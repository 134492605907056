import { makeStyles } from '@mui/styles';
import { breakpoints } from 'theme/theme';

export const useAuthStyles = makeStyles(() => ({
  container: {
    padding: '0 7.2vw',
    marginTop: '10%',
    marginBottom: '10%',
    [breakpoints.down('md')]: {
      width: '100%',
      padding: '0 7.2vw',
      marginTop: '30%',
      marginBottom: '30%'
    }
  },
  login: {
    padding: '1.5rem 61px',
    borderRadius: '10px',
    width: '40%',
    margin: 'auto',
    textAlign: 'center',
    background: '#fff',
    boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.15)',
    [breakpoints.down('md')]: {
      width: '100%',
      padding: '5vh 7.2vw'

    }
  },
  root: {
    padding: '1.5rem 61px',
    borderRadius: '10px',
    width: '45%',
    margin: 'auto',
    textAlign: 'center',
    background: '#fff',
    boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.15)',
    [breakpoints.down('md')]: {
      width: '100%',
      padding: '2 0px 7.2vw',
      marginTop: '1vh'
    }
  },

  buttonProgress: {
    margin: '0 0 0 15px',

  },
  titleHeader: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    lineHeight: '29px',
    marginBottom: '30px',
    textAlign: 'center',
    letterSpacing: '-0.015em',
  },
  titleSubTitle: {
    fontSize: '18px',
    marginBottom: '30px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '-0.015em',
  },
  loginTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontSize: '1.3rem',
    fontWeight: '500',
    lineHeight: '29px',
    textAlign: 'center',
    letterSpacing: '-0.015em',
    color: '#041C26',
  },
  form: {
    textAlign: 'center',
    marginTop: '20px'
  },
  altText: {
    fontWeight: 'bold',
    fontSize: '1.15em',
    lineHeight: '1.2em',
    letterSpacing: '-0.015em',
    margin: '1rem !important',
  },
  submit: {
    backgroundColor: '#18A0FB',
    borderRadius: '6px',
    marginTop: '20px !important',
    color: 'white',
  },
}));
