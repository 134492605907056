import { Button, CircularProgress } from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { modalActions } from 'modules/modals/_actions';
import { supportTicketMarkAsRead, supportTicketMarkAsResolved, supportTicketMarkInProgress } from 'modules/support/_redux/support/supportActions';
import { useDispatch, useSelector } from 'react-redux';

export const ViewTicket = ({ data }) => {
    const dispatch = useDispatch();
    const { actionsLoading, inprogress, markasreso } = useSelector((state) => state.support);
    const { description, lastName, firstName, title ,id, email, readBy, ticketId, phoneNumber } = data?.row

    const markasread = () => {
        dispatch(supportTicketMarkAsRead(id));
    };

    const markInProgress = () => {
        dispatch(supportTicketMarkInProgress(id));
    };

    const markasresolved = () => {
        dispatch(supportTicketMarkAsResolved(id));
    };

    const classes = useAuthStyles();


    const closeModal = () => {
        dispatch(modalActions.hideModal());
    };

    return (
        <div className="site-modal-tickets">
            <ApiMessages/>
            <p>Ticker Number: {ticketId}</p>
            <p>Name: {title} {firstName} {lastName}</p>
            <p>Phone Number: {phoneNumber}</p>
            <p>Email: <a target="_blank" href={`mailto:${email}`} rel="noreferrer">{email}</a></p>
            <p>Description: {description}</p>
            <p>Read By: {readBy}</p>

            <div className="select-file p-10">

                <div className="btn-group-actions site-modal__actions">
                    <Button
                        color="primary"
                        onClick={() => markasread()} 
                        variant="outlined"
                    >
                        Mark as read
                        {(actionsLoading) && (
                            <CircularProgress
                                size={24}
                                color="loader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => markInProgress()} 
                        variant="outlined"
                    >
                        Move to In Progress
                        {(inprogress) && (
                            <CircularProgress
                                size={24}
                                color="loader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => markasresolved()} 
                        variant="outlined"
                    >
                        Mark as resolved
                        {(markasreso) && (
                            <CircularProgress
                                size={24}
                                color="loader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        className="danger"              
                        variant="outlined"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};
