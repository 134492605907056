import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import planReducer from 'modules/pricing/_redux/planSlice';
import workspacesReducer from '../modules/adduser/_redux/accounts/accountsSlice';
import walletReducer from '../modules/payment/wallet/_redux/walletsSlice';
import adminReducer from 'modules/admin/_redux/admin/adminSlice';
import { modals } from 'modules/modals/_reducer'
import supportReducer from 'modules/support/_redux/support/supportSlice';
import oauthenticationReducer from 'modules/authentication/_redux/authSlice';

export const rootReducer = combineReducers({
  oauth: oauthenticationReducer,
  workspace: workspacesReducer,
  wallet: walletReducer,
  plan: planReducer,
  admin: adminReducer,
  modals: modals,
  support: supportReducer
});

export function* rootSaga() {
  yield all([oauthenticationReducer.saga()]);
}
