import {
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  Grid,
  Box,
  CardActions,
  Button,
  CircularProgress,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadWalletModal from '../loadWallet/modal';
import * as actions from './_redux/walletsActions';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    alignSelf: 'center',
    margin: '100px 0',
  },
  content: {
    paddingLeft: '0.5rem',
  },
  costText: {
    alignSelf: 'center',
  },
  contentPaymentPlan: {
    paddingLeft: '0.5rem',
  },
  card: {
    background: '#18A0FB',
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
}));

function LoadWalletPayment() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    actionsLoading, wallet: { walletBalance },
  } = useSelector(
    (state) => ({
      actionsLoading: state.wallet.actionsLoading,
      wallet: state.wallet,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(actions.getPendingPayments());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.checkWalletBalance());
  }, [dispatch]);

  return (
    <Container maxWidth="lg" style={{ alignSelf: 'center', padding: '100px 0' }}>
      <Box className={classes.root}>
        <Grid container spacing={2} className={classes.hero}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="h4" style={{ fontSize: '28px', fontWeight: 'normal' }}>Payment</Typography>
            <Typography style={{ fontSize: '18px', fontWeight: 'normal' }}>
              Choose Payment mode to complete transaction and start enjoying
              Google Workspace
            </Typography>
            <br />
            <Box className={classes.card}>
              <Card style={{ background: '#18A0FB' }}>
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ color: 'white', fontSize: '1.2em', fontWeight: 'normal' }}>
                    Your Cloud Wallet
                  </Typography>
                  <br />
                  <Divider style={{ backgroundColor: '#fff' }} />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      m: 1,
                    }}
                  >
                    <Box style={{ textAlign: 'left', borderRight: '1px solid #fff', width: '50%' }}>
                      <Typography sx={{ mb: 1.5, color: 'white' }} color="text.secondary">
                        AVAILABLE BALANCE
                      </Typography>
                      <Typography variant="h5" sx={{ color: 'white', fontSize: '36px' }}>{actionsLoading
                        ? <CircularProgress
                            size={24}
                            color="loader"
                            className={classes.buttonProgress}
                        /> : `${walletBalance?.currency} ${walletBalance?.balance}`}
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        color: `${((walletBalance?.balance <= walletBalance?.currentBill) || walletBalance?.balance === 0)  ? 'red' : '#fbc02d'}`, fontWeight: '500', fontSize: '2.25em', textAlign: 'right', width: '350px',
                      }}
                    >
                      <CheckCircleOutlineIcon />
                      {((walletBalance?.balance <= walletBalance?.currentBill) || walletBalance?.balance === 0) ? 'YOU HAVE INSUFFICIENT FUNDS' : 'YOU HAVE SUFFICIENT FUNDS'}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <br />
            <CardActions style={{ justifyContent: 'end', padding: '10px 0' }}>
              <Link to="/my-wallet" style={{ textDecoration: 'none' }}>
                <Button
                  sx={{ marginLeft: { xs: '2px', md: '20px' } }}
                  style={{
                    padding: '4px 20px',
                    border: 'thin solid #233168',
                    color: '#233168',
                    cursort: 'pointer',
                  }}
                >
                  View Wallet
                </Button> &nbsp;
              </Link>
              <LoadWalletModal />
            </CardActions>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default LoadWalletPayment;
