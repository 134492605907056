import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import { getAllEditions, getAllSubscriptions } from 'modules/adduser/_redux/accounts/accountsActions';
import { Button, CircularProgress } from '@mui/material';
import { useLocation, Link as LinkTo } from 'react-router-dom';
import PriceModal from './pricingModal';
import './index.scss';
import { getdowngradeChoices, getupgradeChoices } from './_redux/planActions';
import Cookies from 'js-cookie';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';

function EditPlan() {
  const dispatch = useDispatch();

  const location = useLocation();
  const { upgrade } = location.state;

  const [plans, setPlans] = useState([]);

  const onSelectPlan = (tier) => {
    Cookies.set('currentPlan', JSON.stringify(tier));
  };

  const { allEditions } = useSelector((state) => state.workspace);
  const { usermessageloading } = useSelector((state) => state.oauth, shallowEqual,);
  const { currentPlan, upgradeChoices, downgradeChoices, actionsLoading } = useSelector((state) => state.plan);
  const {
    subscriptions,
  } = useSelector(
    (state) => ({
      subscriptions: state.workspace.subscriptions,
      lastError: state.workspace.lastError,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getAllEditions());
  }, [dispatch]);


  useEffect(() => {
    if (upgrade) {
      dispatch(getupgradeChoices());
    } else
      dispatch(getdowngradeChoices());
  }, [dispatch, upgrade]);

  useEffect(() => {
    dispatch(getAllSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    if (allEditions) {
      if (upgrade) {
        setPlans(upgradeChoices);
      } else {
        setPlans(downgradeChoices);
      }
    }
  }, [allEditions, upgradeChoices, downgradeChoices, subscriptions, upgrade]);

  const conditionalStyle = (upgradedTo, selected, tier, downgradedTo) => {
    if (upgrade) {
      if (upgradedTo) {
        return 'pricing__card-active pricing__card';
      }
      if (selected) {
        return 'pricing__card-selected pricing__card';
      }
      return 'pricing__card';
    }
    if (downgradedTo) {
      return 'pricing__card-active pricing__card';
    }
    if (selected) {
      return 'pricing__card-selected pricing__card';
    }
    return 'pricing__card';
  };

  return (
    <div style={{ padding: '150px 7.2vw' }}>
      {/* Hero unit */}
      <Typography
        component="p"
        variant="p"
        align="center"
        color="text.primary"
        style={{ paddingTop: '15px', fontWeight: '500', fontSize: '1.25em' }}
      >
        Confirm Your Edition
      </Typography>
      <Typography
        variant="p"
        align="center"
        color="text.primary"
        component="p"
        style={{ padding: '10px 15vw 20px' }}
      >
        Workspace plans start as low as $6 per user per month for Business
        Starter, $12 per user per month for
        Business Standard, and $18 per
        user per month for Business Plus. Quickly build an effective pricing
        table for your potential
        customers with this layout.
      </Typography>      
      {(actionsLoading) && (
        <CircularProgress
          size={24}
          color="darkloader"
        // className={classes.buttonProgress}
        />
      )}
      <ApiMessages />
      {(usermessageloading) && (
        <CircularProgress
          size={24}
          color="darkloader"
        // className={classes.buttonProgress}
        />
      )}
      <Grid container spacing={5} style={{ marginTop: '50px', justifyContent: 'center' }} alignItems="stretch">
        {plans && plans?.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            className={conditionalStyle(tier.upgradedTo, tier.selected, tier, tier.downgradedTo)}
            style={{ cursor: 'pointer', marginLeft: { xs: '1rem', alignItems: 'stretch', pointerevents: 'none' } }}
            key={tier?.label}
            xs={11.5}
            sm={tier?.label === 'Enterprise' ? 12 : 6}
            md={3}
          >
            <Card
              style={{
                background: 'white',
                color: 'black',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <CardHeader
                title={tier?.label}
                subheader={tier?.subheader ? tier?.subheader : <br />}
                titleTypographyProps={{
                  component: 'h3', variant: 'h3', align: 'center', color: 'black',
                }}
                action={
                  tier?.label === 'Business Standard' ? <StarIcon /> : null
                }
                subheaderTypographyProps={{
                  align: 'center',
                  color: 'text.primary',
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >{tier?.label === 'Enterprise' ? (
                  <Typography variant="h6" color="text.secondary">Contact Sales Team</Typography>
                ) : (
                  <>
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      ${tier?.amount}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {tier?.rateLabel}
                    </Typography>
                  </>
                )}
                </Box>
                <CardActions style={{ justifyContent: 'center' }}>
                  <PriceModal
                    text="View More"
                    fullWidth={true}
                    title={currentPlan?.title}
                    testid={currentPlan?.buttonTestid}
                    nextStage="/edit-plan"
                    variant="outlined"
                  />
                </CardActions>
                <center>
                  <LinkTo to={`${tier?.label === 'Enterprise' ? '/support' : '/change-plan'}`} state={{ upgrade }} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="outlined"
                      data-testid={tier?.testid}
                      fullWidth
                      onClick={() => onSelectPlan(tier)}
                    >
                      {(currentPlan?.title || currentPlan?.label) === tier?.label ? 'Current Plan' : 'Select'}
                    </Button>
                  </LinkTo>
                </center>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default EditPlan;
