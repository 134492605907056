import { handleResponseError } from 'helpers/base.service';
import { showApiMessages, successShowApiMessages } from 'modules/authentication/_redux/authActions';
import * as requestFromServer from './supportCrud';
import { supportSlice, callTypes } from './supportSlice';

const { actions } = supportSlice;

export const supportsTicketsFetched = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .supportsTicketsFetched(queryParams)
    .then((response) => {
      const { data: {content, totalElements} } = response.data;
      dispatch(actions.supportsTicketsFetched({content, totalElements}));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const supportTicketAdd = (queryParams, resetForm) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .supportTicketAdd(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(successShowApiMessages(response))
      dispatch(actions.supportTicketAdd(data));
      resetForm()
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const supportTicketMarkAsRead = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .supportTicketMarkAsRead(queryParams)
    .then((response) => {
      const { message } = response.data;
      dispatch(successShowApiMessages(response))
      dispatch(actions.supportTicketMarkAsRead(message));
      dispatch(supportsTicketsFetched())
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const supportTicketMarkAsResolved = (queryParams) => (dispatch) => {
  dispatch(actions.startCallMarkAsResolved({ callType: callTypes.action }));
  return requestFromServer
    .supportTicketMarkAsResolved(queryParams)
    .then((response) => {
      const { message } = response.data;
      dispatch(supportsTicketsFetched())
      dispatch(successShowApiMessages(response))
      dispatch(actions.supportTicketMarkAsResolved(message));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const supportTicketMarkInProgress = (queryParams) => (dispatch) => {
  dispatch(actions.startCallInProgress({ callType: callTypes.action }));
  return requestFromServer
    .supportTicketMarkInProgress(queryParams)
    .then((response) => {
      const { message } = response.data;
      dispatch(supportsTicketsFetched())
      dispatch(successShowApiMessages(response))
      dispatch(actions.supportTicketMarkInProgress(message));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

