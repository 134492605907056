import { useSelector } from "react-redux"

export const ApiMessages = () => {
    const { showApiMessages } = useSelector(state => state.oauth)
    return (
        <>
                   {showApiMessages &&
                <div className="showapimessages">
                    {showApiMessages.map(item => {
                        return (
                            <p>{item}</p>
                        )
                    })}
                </div>
            }

        </>
    )
}