import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Status from 'components/common/Status';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, Link, Tooltip } from '@mui/material';
import { Pagination } from 'components/pagination/pagination';
import CustomersActions from 'components/dashboard/Actions/customerActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchCustomers } from "../_redux/admin/adminActions";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'domain',
        numeric: false,
        disablePadding: false,
        label: 'Domain',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'cwBalance',
        numeric: false,
        disablePadding: false,
        label: 'CW Bal(USD)',
    },
    {
        id: 'chargedDate',
        numeric: false,
        disablePadding: false,
        label: 'Charged Date',
    },
    {
        id: 'dueDate',
        numeric: false,
        disablePadding: false,
        label: 'Due Date',
    },
    {
        id: 'spend',
        numeric: false,
        disablePadding: false,
        label: 'Spent / Wk(USD)',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
    },
];

function EnhancedTableHead(props) {
    const {
        order, orderBy, onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            sx={{ color: '#041c26', fontWeight: 'bold', fontSize: '.8em' }}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                boxShadow: '2px 4px 8px rgb(0 0 0 / 20%)',
                borderRadius: '20px',
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <>
                    <FilterListIcon /> <span style={{ marginLeft: '10px' }}>Filter</span>
                </>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function CustomersTable() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [selected,] = useState([]);
    const [page, setPage] = useState(0);
    const [dense,] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const dispatch = useDispatch();

    const { customers, totalElements, actionsLoading } = useSelector((state) => state.admin);

    useEffect(() => {
        dispatch(fetchCustomers({ page, rowsPerPage }))
    }, [dispatch, page, rowsPerPage])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        if(totalElements < rowsPerPage){
            return;
        } else setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{
                width: '100%', mb: 0, border: 'none', boxShadow: 'none',
            }}
            >
                <EnhancedTableToolbar
                    numSelected={selected.length} />
                <TableContainer
                    sx={{
                        boxShadow: '5px 11px 30px rgb(0 0 0 / 20%)',
                        borderRadius: '20px',
                        margin: '30px 0'
                    }}
                >
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={totalElements}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {!actionsLoading ? (
                                <>
                                    {stableSort(customers, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell style={{ fontWeight: 'bold', textTransform: 'capitalize' }} align="left">{row.name}</TableCell>
                                                    <TableCell align="left"><Link target='_blank' without rel="noreferrer" className='domain' href={`https://${row.domain}`}>{row.domain}</Link></TableCell>
                                                    <TableCell align="left">{row.email}</TableCell>
                                                    <TableCell align="left">{row.cwBalance}</TableCell>
                                                    <TableCell align="left">{row.chargedDate}</TableCell>
                                                    <TableCell align="left">{row.dueDate}</TableCell>
                                                    <TableCell align="left">{row.spent}</TableCell>
                                                    <TableCell align="left"><Status status={row.status} /></TableCell>
                                                    <TableCell align="left"><CustomersActions row={row} id={row.id} /></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </>)

                                :
                                <p style={{ padding: '20px' }}>Loading...</p>
                            }

                        </TableBody>
                    </Table>
                    <Pagination
                        data={customers}
                        page={page}
                        totalElements={totalElements}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage} />
                </TableContainer>
            </Paper>
        </Box>
    );
}
