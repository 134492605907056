import axios from 'axios';
import { isEmpty } from 'lodash';
import qs from 'qs';
import jwt_decode from 'jwt-decode';
import * as routeHelpers from "../routes/routerHelpers";
import { LogoutHandler } from 'components/header/header';
import { getCurrentUserProfile } from 'modules/authentication/_redux/authActions';
import Swal from 'sweetalert2';
import store from 'store';

export const LOGIN_URL = '/oauth/token';
export const EXIT_URL = "/exit";


export function logout() {
  const user = routeHelpers.getUser();
  const { accessTokenString } = user
  // Authorization head should be fulfilled in interceptor.
  const args = {
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessTokenString}`,
      Accept: 'application/json',
    },
    url: EXIT_URL
  };

  const requestConfig = {
    ...requestDefaults,
    ...args
  }

  return axios(requestConfig).then(handleResponse);
}


let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];
/**
 * When the refresh is successful, we start retrying the requests one by one and empty the queue
 */
export function onAccessTokenFetched(user) {
  const { accessTokenString } = user;
  subscribers.forEach((callback) => callback(accessTokenString));
  subscribers = [];
}


export function addSubscriber(callback) {
  subscribers.push(callback);
}

/**
 * Handle user details from access token
 */
export function handleUserResponse(token, refreshToken) {
  // Decode token to get user data and save in storage
  const user = jwt_decode(token);

  user.access_token = token;
  user.refresh_token = refreshToken;

  routeHelpers.setUser(user);

  return user;
}


export function authHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user?.access_token) {
    return {
      Authorization: `Bearer ${user.access_token}`,
    };
  }
  return {};
}

export const requestDefaults = {
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng'),
  },
  auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD,
  },
};

/**
 * Fetch new access Token from remote using refresh_token
 */
export function refreshAccessToken(refreshTokenString) {
  const args = {
    method: 'post',
    baseURL: process.env.REACT_APP_API_BASE,
    url: LOGIN_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    data: qs.stringify({
      refresh_token: refreshTokenString,
      grant_type: 'refresh_token',
    }),
    auth: {
      username: process.env.REACT_APP_API_USERNAME,
      password: process.env.REACT_APP_API_PASSWORD,
    },
    validateStatus: (status) => {
      return true; // I'm always returning true, you may want to do it depending on the status received
    },
  };

  return axios(args).then((response) => {
    return response;
  }).catch((error) => {
    return error;
  });
}


export const axiosInstance = () => {
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      ...authHeader(),
    },
  })

  instance.interceptors.response.use((response) => (response),
    (error) => {

      const {response: {status = 0}} = error;

            if (!error.response) {
                Swal.fire(
                    'Network Error!',
                    'Connection to server lost',
                    'error'
                );
            }


            if (status === 500) {
                Swal.fire(
                    'Server error. Consult Admin',
                    'error'
                );

            }

            if (status === 403) {
                Swal.fire(
                    'Failed!',
                    'error'
                );
            }
        
      console.log(`%c interceptor error: ${error}`, 'color: white; background:red; font-size: 15px');
      
      if (isTokenExpiredError(error)) {
        return resetTokenAndReattemptRequest(error);
      }
      return Promise.reject(error);
    },
  );
  return instance;
};

export function resetTokenAndReattemptRequest(error) {
  try {
    const originalRequest = error.config;

    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
          since there another request that already attempt to
          refresh the token */
      addSubscriber((accessTokenString) => {
        axiosInstance.defaults.headers.Authorization = `Bearer ${accessTokenString}`;
        originalRequest.headers.Authorization = `Bearer ${accessTokenString}`;
        resolve(axios(originalRequest));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const user = routeHelpers.getUser();
      const { refresh_token } = user

      refreshAccessToken(refresh_token).then((response) => {
        if (!response || (response.status !== 200)) {
          LogoutHandler()
        }

        if (response.status === 200) {
          const {
            access_token,
            refresh_token,
          } = response.data;

          // Handle user data
          const user = handleUserResponse(access_token, refresh_token);

          return user;
        }

        const tokenError = response.data.message || response.statusText;
        return Promise.reject(tokenError);
      }).then((user) => {
        // window.location.reload()
       store.dispatch(getCurrentUserProfile())
        return user;
      }).then((user) => {
        isAlreadyFetchingAccessToken = false;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        onAccessTokenFetched(user);

        setTimeout(() => {
          window.location.href = '/pricing';
        }, 2000);
        return user;
      });
    }

    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}




export const axiosBlobInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    ...authHeader(),
  },
});

export const axiosFileUploadInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    'Content-Type': 'multipart/form-data',

    accept: 'application/json',
    ...authHeader(),
  },
});

export const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD,
  },
});

export function handleResponse(res) {
  if (res.status === 200 || res.status === 201) {
    const { data } = res.data;
    return data;
  }
  const { response } = res;
  const { data } = response;
  const { message } = data;
  return Promise.reject(message);
}

export function handleResponseError(error) {
  const { response } = error;

  if (!response) {
    return error.toString();
  }

  const { data } = response;

  if (!data) {
    return error.toString();
  }

  const { messages, message } = data;

  // messages?.map((mess) => toast.error(mess));

  return message || messages || data?.error;
}



export function isTokenExpiredError(error) {
  const { response } = error;
  if (!response) {
    return true;
  }

  const { status, data } = response;
  const user = routeHelpers.getUser();

  if (isEmpty(user)) {
    return false;
  }

  const { exp } = user;
  const currentUnixTime = new Date().getTime() / 1000;

  if (data.error === 'invalid_token' || status === 401 || currentUnixTime > exp) {
    return true;
  }

  return false;
}
