import { Button, CircularProgress } from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { deleteSystemUser } from 'modules/admin/_redux/admin/adminActions';
import { modalActions } from 'modules/modals/_actions';
import { useDispatch, useSelector } from 'react-redux';

export const DeleteUser = ({ modalID, data }) => {
    const dispatch = useDispatch();
    const { actionsLoading } = useSelector((state) => state.admin);

    const deleteQuestion = () => {
        dispatch(deleteSystemUser(data, modalID));
    };

    const classes = useAuthStyles();


    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal-twisted">
            <p>Are you sure you want to delete {data.firstName} {data.lastName}?</p>
            <div className="select-file p-10">

                <div className="btn-group-actions site-modal__actions">
                    <Button
                        color="primary"
                        onClick={() => deleteQuestion()} 
                        variant="outlined"
                    >
                        Continue
                        {(actionsLoading) && (
                            <CircularProgress
                                size={24}
                                color="loader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        className="danger"              
                        variant="outlined"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};
