import { TextField, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './index.scss';

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: '3px',
    border: 'solid 1px #eff0f1',
  },
}));

function TextFieldItem({
  name,
  label,
  icon,
  hasIcon,
  type,
  value,
  onChange,
  onBlur,
  minRows,
  validationField,
  error,
  disabled = false,
  fullWidth = true,
  placeholder,
  style,
}) {
  const classes = useStyles();

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <TextField
        fullWidth={fullWidth}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              { hasIcon && <i className={`fa fa-lg fa-fw fa-${icon} icon-input`} aria-hidden="true" />}
            </InputAdornment>
          ),
        }}
        id={name}
        label={label}
        placeholder={placeholder || label}
        type={type}
        minRows={minRows}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={validationField && Boolean(error)}
        helperText={validationField && error}
        size="small"
        variant="outlined"
        className={classes.input}
        disabled={disabled}
        sx={{ margin: '8px 0 8px 0' }}
        style={style}
      />
    </div>
  );
}

export default TextFieldItem;
