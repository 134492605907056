import MyWalletDashboard from './Admin';

function MyWalletHomepage() {
  return (
    <div
      className="dashboard-container"
    >
      <MyWalletDashboard />
    </div>
  );
}

export default MyWalletHomepage;
