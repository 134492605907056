import { handleResponseError } from 'helpers/base.service';
import * as requestFromServer from './walletsCrud';
import { walletsSlice, callTypes } from './walletsSlice';

const { actions } = walletsSlice;

const checkoutType = 'redirect';

export const checkWalletBalance = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getWalletBalance(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.walletBalanceFetched(data));
    })
    .catch((error) => {
      handleResponseError(error);
      error.clientMessage = "Can't create workspace";
      handleResponseError(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getPendingPayments = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPendingPayments(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.walletPendingPaymentsFetched(data));
    })
    .catch((error) => {
      handleResponseError(error);
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const redirectToPayment = (queryParams) => (dispatch) => {
  dispatch(actions.startRedirect({ callType: callTypes.action }));
  return requestFromServer
    .redirectToPayment(queryParams)
    .then((res) => {
      const { data: { data } } = res;
      dispatch(actions.redirectToPaymentData(data));
      window?.Tingg?.renderCheckout({
        checkoutType,
        merchantProperties: data,
      });
    })
    .catch((error) => {
      handleResponseError(error);
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getTransactions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTransactions(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.walletTransactionssFetched(data));
    })
    .catch((error) => {
      handleResponseError(error);
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const getInvoices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getInvoices(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.walletInvoicesFetched(data));
    })
    .catch((error) => {
      handleResponseError(error);
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const downInvoice = (queryParams) => (dispatch) => {
  dispatch(actions.startDownload({ callType: callTypes.action }));
  return requestFromServer
    .downloadInvoice(queryParams)
    .then((response) => {
      const { data } = response;

      function dataURItoBlob(data) {
        const byteString = window.atob(data);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf' }, 'file.pdf');
        return blob;
      }

      const blob = dataURItoBlob(data);
      const url = URL.createObjectURL(blob);

      // to open the PDF in a new window
      setTimeout(() => {
        window.open(url, '_blank');
      }, 800);
      dispatch(actions.walletInvoiceDownloaded(data));
    })
    .catch((error) => {
      handleResponseError(error);
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

