import { Navigate } from 'react-router-dom';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem('user');

  return (
    isAuthenticated ? <Component {...restOfProps} /> : <Navigate to="/login" />
  );
}

export default ProtectedRoute;
