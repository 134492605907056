import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import TextFieldItem from 'components/common/inputs/TextField';
import { Formik, Form } from 'formik';
import {  useDispatch, useSelector } from 'react-redux';
import { confirmUser } from '../_redux/authActions';
import * as yup from 'yup';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { Link } from 'react-router-dom';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';

const validationSchema = yup.object({
  otp: yup
    .string('Enter your otp code')
    .required('otp code is required'),
});

function SuccessOnSignup() {
  const dispatch = useDispatch();
  const classes = useAuthStyles();
  const { actionsLoading } = useSelector((state) => state.oauth);

  return (
    <div style={{ padding: '200px 7.2vw', }}>
      <Box className={classes.login}>
        <center>
          <p>Account has been Created. Enter an OTP sent to your Email.</p>
        </center>
        <ApiMessages/>
        <SuccessApiMessages/>
        <Formik
          initialValues={{
            otp: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(confirmUser(values));
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <TextFieldItem
                name="otp"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                validationField={props.touched.otp}
                value={props.values.otp}
                error={props.errors.otp}
                fullWidth={true}
                required
                placeholder="123456"
                label="OTP Code"
                type="text"
              />
              <br />
              <center>
                <Button variant="outlined" type="submit">
                  Proceed
                  {actionsLoading && (
                    <CircularProgress
                      size={24}
                      color="loader"
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </center>
            </Form>
          )}
        </Formik>
        <br />
        <center>
          <Typography variant="p">
            Code not received?
            <Link to='/signup/resend-otp' style={{ color: 'blue', fontWeight: 'normal' }}>
              Click here to resend
            </Link>
          </Typography>
        </center>
      </Box>
    </div>
  );
}

export default SuccessOnSignup;
