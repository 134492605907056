import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import intro from 'assets/video/intro.mp4';

export default function VideoComponent() {
  return (
    <div style={{position: 'relative', width: '100%', textAlign: 'center', borderRadius: '10px !important'}}>
      <Video
        loop
        style={{position: 'relative', width: '100%', textAlign: 'center', borderRadius: '10px !important'}}
        autoPlay
        muted
        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
        poster=""
        onCanPlayThrough={() => {
          // Do stuff
        }}
      >
        <source plays src={intro} type="video/mp4" />
        <track label="English" kind="subtitles" srcLang="en" src={intro} default />
      </Video>
    </div>
  );
}
