import { createSlice } from '@reduxjs/toolkit';

const initialWalletState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  walletBalance: { currentBill: 0 },
  pdf: null,
  walletTransactions: [],
  walletInvoices: [],
  walletPendingPayments: null,
  lastError: null,
  loading: false,
  downloadStatus: false,
  totalElements: null,
  totalElementsInvoices: null
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const walletsSlice = createSlice({
  name: 'wallet',
  initialState: initialWalletState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = null;
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
        state.loadingRedirect = false;
        // state.lastError = action.payload.error.response.data;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
        state.loadingRedirect = true;
      }
    },
    startDownload: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.action) {
        state.downloadStatus = true;
      }
    },
    startRedirect: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.loading = true;
      } else {
        state.loading = true;
      }
    },
    redirectToPaymentData: (state, action) => {
      state.loading = false;
      state.redirectToPaymentData = action.payload;
      state.error = null;
    },
    // fetch pending payements
    walletPendingPaymentsFetched: (state, action) => {
      state.actionsLoading = false;
      state.walletPendingPayments = action.payload;
      state.error = null;
    },
    // wallet
    walletBalanceFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.walletBalance = action.payload;
    },
    walletTransactionssFetched: (state, action) => {
      const { content, totalElements } = action.payload;
      state.actionsLoading = false;
      state.totalElements = totalElements;
      state.error = null;
      state.walletTransactions = content;
    },
    walletInvoicesFetched: (state, action) => {
      const { content, totalElements : elements } = action.payload;
      state.totalElementsInvoices = elements;
      state.actionsLoading = false;
      state.error = null;
      state.walletInvoices = content;
    },
    walletInvoiceDownloaded: (state, action) => {
      state.downloadStatus = false;
      state.pdf = action.payload;
      state.error = null;
    },
  },
});

const { reducer } = walletsSlice;
export default reducer;
