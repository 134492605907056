import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as yup from 'yup';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import TextFieldItem from 'components/common/inputs/TextField';
import { CircularProgress } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import close from '../../../assets/images/pret.svg';
import { redirectToPayment } from '../wallet/_redux/walletsActions';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import Tooltip from 'components/tooltip';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';

const validationSchema = yup.object({
  amount: yup
    .number('Enter amount')
    .required('Amount is required'),
});

function LoadWalletModal() {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const dispatch = useDispatch();
  const {
    loading,
  } = useSelector(
    (state) => ({
      loadingRedirect: state.wallet.loading,
      wallet: state.wallet,
    }),
    shallowEqual,
  );

  const {
    wallet: { walletBalance: { currentBill, monthlyBill } },
  } = useSelector(
    (state) => ({
      walletBalanceLoading: state.wallet.actionsLoading,
      wallet: state.wallet,
    }),
    shallowEqual,
  );
  
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="outlined">
        Load Wallet
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ borderRadius: '20px' }}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Typography
          onClick={handleClose}
          sx={{
            position: 'absolute', right: 0, padding: ' 50px 45px ', cursor: 'pointer',
          }}
        >
          <img src={close} alt="close button" />
        </Typography>
        <DialogTitle style={{ fontSize: '1.2em', fontWeight: '700', padding: '50px 25px' }} id="scroll-dialog-title">Load  Wallet</DialogTitle>
        <ApiMessages/>
        <SuccessApiMessages/>
        <Formik
          initialValues={{
            amount: monthlyBill,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(redirectToPayment(values));
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} style={{ width: '100%', padding: '0 50px' }}>
              <TextFieldItem
                name="amount"
                label="Enter Amount"
                onChange={props.handleChange}
                min={currentBill}
                type="number"
                onBlur={props.handleBlur}
                validationField={props.touched.amount}
                value={props.values.amount}
                error={props.errors.amount}
                fullWidth={false}
              />
              <DialogActions style={{ padding: '15px 0 50px' }}>
              <Tooltip tip={'The above field has been autopopulated with monthly bill'}/> 
                <Button
                  onClick={handleClose}
                  style={{
                    padding: '4px 20px',
                    border: 'thin solid #233168',
                    color: '#233168',
                    cursort: 'pointer',
                  }}
                >Cancel
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                >Load Wallet
                  {(loading) && (
                  <CircularProgress
                    size={24}
                    color="loader"
                  />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default LoadWalletModal;
