import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useGoogleLogin } from '@react-oauth/google';
import Divider from 'components/Divider';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { googleLogin } from '../_redux/authActions';

const useStyles = makeStyles(() => ({
  divIndent: {
    marginTop: '1.6rem',
    padding: '10px 0',
    textAlign: 'left',
    justifyContent: 'left !important',
    border: 'solid 1px #C4C4C4 !important',
    backgroundColor: '#fff',
    width: 'fit-content !important',
    alignSelf: 'center',
  },
  box: {
    marginTop: '0.8rem',
    paddingBottom: '20px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  textIndent: {
    paddingLeft: '0.6rem',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '-0.015em',
    color: '#606060',
  },
}));

function GoogleComponent({ action }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const location = useLocation();
  const { search } = location;
  const code = new URLSearchParams(search).get('code');
  const redirectUrl = location.pathname === "/login" ? process.env.REACT_APP_REDIRECT_URI_LOGIN : process.env.REACT_APP_REDIRECT_URI_ONBOARDING;

  useEffect(() => {
    if (code) {
      dispatch(googleLogin({ code, redirectUrl  }))
    }
  }, [code, dispatch, redirectUrl])

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'email',
    ux_mode: 'redirect'
  });
  
  return (
    <Box className={classes.box}>
      <Button
        variant="text"
        fullWidth
        type="button"
        className={classes.divIndent}
        sx={{ margin: '1rem 0 1rem 0' }}
        onClick={() => login()}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.5224 12.2878C23.5224 11.4773 23.4439 10.6407 23.3132 9.85645H11.9932V14.4838H18.4767C18.2153 15.974 17.3525 17.2811 16.0715 18.1177L19.9407 21.1242C22.2152 19.0066 23.5224 15.9217 23.5224 12.2878Z" fill="#4280EF" />
          <path d="M11.9932 24.0002C15.2349 24.0002 17.9538 22.9283 19.9407 21.0983L16.0715 18.1179C14.9996 18.8499 13.614 19.2682 11.9932 19.2682C8.85597 19.2682 6.2155 17.1506 5.24819 14.3271L1.27441 17.3859C3.31359 21.4381 7.44423 24.0002 11.9932 24.0002Z" fill="#34A353" />
          <path d="M5.24827 14.3004C4.75154 12.8102 4.75154 11.1893 5.24827 9.69917L1.27449 6.61426C-0.424829 10.0129 -0.424829 14.0128 1.27449 17.3853L5.24827 14.3004Z" fill="#F6B704" />
          <path d="M11.9932 4.75854C13.6925 4.73239 15.3656 5.38597 16.5944 6.56242L20.0192 3.11151C17.8493 1.07233 14.9735 -0.025687 11.9932 0.000456276C7.44423 0.000456276 3.31359 2.5625 1.27441 6.61471L5.24819 9.69962C6.2155 6.85 8.85597 4.75854 11.9932 4.75854Z" fill="#E54335" />
        </svg>
        <Typography className={classes.textIndent}>{action}</Typography>
      </Button>
      <Divider>OR</Divider>
    </Box>
  );
}

export default GoogleComponent;
