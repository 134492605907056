import { useSelector } from "react-redux"

export const SuccessApiMessages = () => {
    const { successShowApiMessages } = useSelector(state => state.oauth)
    return (
        <>
            {successShowApiMessages &&
                <div className="successshowapimessages">
                    {successShowApiMessages.map(item => {
                        return (
                            <p>{item}</p>
                        )
                    })}
                </div>
            }

        </>
    )
}