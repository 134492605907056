import { TablePagination } from '@mui/material';
import './pagination.scss'


export const Pagination = ({ data, rowsPerPage, handleChangeRowsPerPage, handleChangePage, page, totalElements }) => {
    return (
        <div className='pagination'>
            <div className='pagination-lite'>
                {/* Showing {page === 0 ? 1 : rowsPerPage + 1} to {rowsPerPage} of {data.length} entries
                page:{page + 1} */}
            </div>
            <div className='pagination-filter'>
                <TablePagination
                    rowsPerPageOptions={[5,10, 15, 25]}
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    )

}