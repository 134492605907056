import { createSlice } from '@reduxjs/toolkit';

const initialPlanState = {
  listLoading: false,
  actionsLoading: false,
  currentPlan: null,
  totalCount: 0,
  entities: null,
  planUpgrade: null,
  planDowngrade: null,
  lastError: null,
  loading: false,
  upgradeChoices:[],
  downgradeChoices:[]
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const planSlice = createSlice({
  name: 'plan',
  initialState: initialPlanState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = null;
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
        state.loadingRedirect = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    planDowngrade: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.planDowngrade = action.payload;
    },
    planUpgrade: (state, action) => {
      state.actionsLoading = false;
      state.planUpgrade = action.payload;
      state.error = null;
    },
    updateCurrentPlan: (state, action) => {
      state.actionsLoading = false;
      state.currentPlan = action.payload;
      state.error = null;
    },
    upgradeChoices: (state, action) => {
      state.actionsLoading = false;
      state.upgradeChoices = action.payload;
      state.error = null;
    },
    downgradeChoices: (state, action) => {
      state.actionsLoading = false;
      state.downgradeChoices = action.payload;
      state.error = null;
    },
  },
});

const { reducer } = planSlice;
export default reducer;
