import { Button, CircularProgress, DialogTitle, Grid, TextareaAutosize, Typography } from "@mui/material";
import { useAuthStyles } from 'assets/scss/components/_auth';
import { Form, Formik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from 'modules/modals';
import close from 'assets/images/pret.svg';
import { ApiMessages } from "components/common/ApiMessages/apimessages";
import { SuccessApiMessages } from "components/common/SuccessApiMessages/successapimessages";

const validationSchema = yup.object({
    message: yup
        .string('Enter Message')
        .required('Message is required'),
});

export const SendToAll = () => {
    const classes = useAuthStyles();
    const { actionsLoading, } = useSelector((state) => state.admin);

    const dispatch = useDispatch();

    return (
        <div className="site-modal">
            <div
                className="site-header"
            >
                <Typography
                    sx={{
                        position: 'absolute', right: 0, cursor: 'pointer'
                    }}
                >
                    <img src={close} onClick={() => dispatch(modalActions.hideModal())}
                        alt="close button" />
                </Typography>
                <DialogTitle style={{ fontSize: '1.2em', fontWeight: '700', padding: '0' }} id="scroll-dialog-title">Bulk Message</DialogTitle>
                <ApiMessages/>
                <SuccessApiMessages/>
            </div>
            <Formik
                initialValues={{
                    message: '',
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    dispatch(modalActions.confirm_send_reminder({values}))
                }}
            >
                {({ handleChange, touched, values, errors, handleBlur, setFieldValue, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item xs>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    name="message"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationField={touched.message}
                                    value={values.message}
                                    error={errors.message}
                                    placeholder="Write your message"
                                    style={{
                                        width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #CACACA', marginTop: '1.5rem',
                                    }}
                                />
                            <p>
                                {errors?.message}

                                </p>
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center" spacing={3}>
                            <Grid style={{ textAlign: 'end' }} item xs>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.submit}
                                >
                                    Send
                                    {(actionsLoading) && (
                                        <CircularProgress
                                            size={24}
                                            color="loader"
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>

                    </Form>
                )}
            </Formik>
        </div>
    );
}