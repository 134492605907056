import { makeStyles } from '@mui/styles';

import { FormControl, Grid } from '@mui/material';
import { InputField } from 'components/FormFields';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { _renderHelperText } from 'utils';

const useStyles = makeStyles((theme) => ({

  formControl: {
    margin: theme.spacing(1),
    minWidth: '70%',
    display: 'flex',
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: '.9em',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: 'normal',
    color: '#000000',
  },
}));

export default function AddGoogleWorkspace(props) {
  const {
    formField: {
      companyName, language, streetAddressTwo, postalCode, streetAddressOne, country, domainName,
    },
    setFieldValue,
    values,
    errors,
  } = props;
  const classes = useStyles();

  return (
    <Grid spacing={3}>
      <FormControl variant="outlined" className={classes.formControl} id="demo-simple-select-outlined">
        <ApiMessages/>
        <SuccessApiMessages/>
        <div className="textfield__wrapped">
          <div className="textfield__wrapped-textfield">
            <InputField
              name={companyName.name}
              label={companyName.label}
              icon="building-o"
              hasIcon
              fullWidth
            />
            <InputField
              name={domainName.name}
              label={domainName.label}
              icon="briefcase"
              hasIcon
              fullWidth
            />
            <InputField
              icon="globe"
              hasIcon
              name={language.name}
              label={language.label}
              fullWidth
            />
            <CountryDropdown
              value={values.country}
              name={country.name}
              onChange={(val) => setFieldValue('country', val)}
              style={{
                padding: '12px 9px',
                width: '100%',
                borderRadius: '6px',
                background: 'white',
                margin: '7px 0',
                borderColor: 'lightgray',
              }}
            />
            {_renderHelperText(errors.country)}
            {values.country && (
              <RegionDropdown
                country={values.country}
                value={values.city}
                defaultOptionLabel="City"
                onChange={(val) => setFieldValue('city', val)}
                label="City"
                style={{
                  padding: '12px 9px',
                  width: '100%',
                  borderRadius: '6px',
                  background: 'white',
                  margin: '7px 0',
                  borderColor: 'lightgray',
                }}
              />
            )}
            {_renderHelperText(errors.city)}
            <InputField
              name={postalCode.name}
              label={postalCode.label}
              fullWidth
              icon="address-card"
              hasIcon
            />
            <InputField
              name={streetAddressOne.name}
              label={streetAddressOne.label}
              fullWidth
              icon="address-card"
              hasIcon
            />
            <InputField
              icon="address-card"
              hasIcon
              name={streetAddressTwo.name}
              label={streetAddressTwo.label}
              fullWidth
            />
          </div>
        </div>
      </FormControl>
    </Grid>
  );
}
