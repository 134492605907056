import { modalConstants } from "./_constants";

const initialState = {
    type: null,
    content: {},
};

export const modals = (state = initialState, action) => {
    switch (action.type) {
        case modalConstants.ADD_USER:
            return {
                content: action.content,
                type: modalConstants.ADD_USER,
            };
        case modalConstants.EDIT_USER:
            return {
                content: action.content,
                type: modalConstants.EDIT_USER,
            };
        case modalConstants.DELETE_USER:
            return {
                content: action.content,
                type: modalConstants.DELETE_USER,
            };
        case modalConstants.VIEW_SUBSCRIPTION:
            return {
                content: action.content,
                type: modalConstants.VIEW_SUBSCRIPTION,
            };
        case modalConstants.UPGRADE_SUBSCRIPTION:
            return {
                content: action.content,
                type: modalConstants.UPGRADE_SUBSCRIPTION,
            };
        case modalConstants.DOWNGRADE_SUBSCRIPTION:
            return {
                content: action.content,
                type: modalConstants.DOWNGRADE_SUBSCRIPTION,
            }; case modalConstants.UPDATE_SUBSCRIPTION:
            return {
                content: action.content,
                type: modalConstants.UPDATE_SUBSCRIPTION,
            };
        case modalConstants.WORKSPACE_STATUS:
            return {
                content: action.content,
                type: modalConstants.WORKSPACE_STATUS,
            };

        case modalConstants.SEND_REMINDER:
            return {
                content: action.content,
                type: modalConstants.SEND_REMINDER,
            };
        case modalConstants.CONFIRM_SEND_REMINDER:
            return {
                content: action.content,
                type: modalConstants.CONFIRM_SEND_REMINDER,
            };
        case modalConstants.CANCEL_SUB:
            return {
                content: action.content,
                type: modalConstants.CANCEL_SUB,
            };
        case modalConstants.CONFIRM_CANCEL_SUB:
            return {
                content: action.content,
                type: modalConstants.CONFIRM_CANCEL_SUB,
            };
        case modalConstants.UNDO_CANCEL_SUB:
            return {
                content: action.content,
                type: modalConstants.UNDO_CANCEL_SUB,
            };
        case modalConstants.UNDO_CONFIRM_CANCEL_SUB:
            return {
                content: action.content,
                type: modalConstants.UNDO_CONFIRM_CANCEL_SUB,
            };
        case modalConstants.SEND_MESSAGE:
            return {
                content: action.content,
                type: modalConstants.SEND_MESSAGE,
            };
        case modalConstants.SEND_TO_ALL:
            return {
                content: action.content,
                type: modalConstants.SEND_TO_ALL,
            };
        case modalConstants.VIEW_TICKET:
            return {
                content: action.content,
                type: modalConstants.VIEW_TICKET,
            };
            
        case modalConstants.CONFIRM_DOMAIN_TRANSFER:
            return {
                content: action.content,
                type: modalConstants.CONFIRM_DOMAIN_TRANSFER,
            };

        case modalConstants.HIDE_MODAL:
        case modalConstants.CLEAR:
            return initialState;
        default:
            return state;
    }
};
