import { Typography } from '@mui/material';

function SaveSuccess({ message }) {
  return (
    <div style={{padding: '200px 7.2vw'}}>
   <Typography variant="h5" style={{ padding: '25px 0' }} gutterBottom>
      {message}
    </Typography>
    </div>
  );
}

export default SaveSuccess;
