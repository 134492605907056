import { Grid } from '@mui/material';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';
import { InputField } from 'components/FormFields';
export default function TransferAccount({ formField: {domainName, token}, setFieldValue, values, errors,}) {
  return (
    <Grid container rowSpacing={1}>
       <ApiMessages/>
        <SuccessApiMessages/>
      <Grid
        item
        xs={12}
        style={{
          padding: '0 2rem 0 0',
          margin: 'auto',
          textAlign: 'left',
        }}
      >
         <InputField
            name={domainName.name}
            label={domainName.label}
            fullWidth
            icon="globe"
            hasIcon
          />
            <InputField
            name={token.name}
            label={token.label}
            fullWidth
            icon="phone"
            hasIcon
          />
      </Grid>
      {/* <Grid
        item
        xs={6}
        style={{
          margin: 'auto',
          textAlign: 'left',
          lineHeight: '40px',
          color: '#000000',
        }}
      >

        <Typography variant="h2" style={{ fontSize: '15px', fontWeight: '700', marginBottom: '10px' }}>
          How it works?
        </Typography>

        <List dense={false}>
          <ItemWithIcon item="We move all your email accounts from the old provider to us by keeping the data intact." />
          <ItemWithIcon item="Admin account details to manage your google accounts remain intact." />
          <ItemWithIcon item="Note: Your existing tenure with the other provider, if any does not get moved to us." />
        </List>

        <Typography style={{ padding: '15px 0' }}>
          Have more concerns? <b> Contact Support Team </b>
        </Typography>
      </Grid> */}
    </Grid>
  );
}
