import { handleResponse, handleResponseError } from 'helpers/base.service';
import Cookies from 'js-cookie';
import { showApiMessages, successShowApiMessages } from 'modules/authentication/_redux/authActions';
import * as requestFromServer from './planCrud';
import { planSlice, callTypes } from './planSlice';

const { actions } = planSlice;

export const planDowngrade = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .planDowngrade(queryParams)
    .then((response) => {
      handleResponse(response);
      const { data } = response.data;
      window.location.href = '/dashboard';
      dispatch(successShowApiMessages(response))
      dispatch(actions.planDowngrade(data));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const planUpgrade = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .planUpgrade(queryParams)
    .then((response) => {
      window.location.href = '/dashboard';
      dispatch(successShowApiMessages(response))
      const { data } = response.data;
      dispatch(actions.planUpgrade(data));
    })
    .catch((error) => {
      handleResponseError(error);
      error.clientMessage = "Can't create workspace";
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCurrentPlan = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCurrentPlan(queryParams)
    .then((response) => {
      dispatch(successShowApiMessages(response))
      Cookies.set('currentPlan', JSON.stringify(response));
      dispatch(actions.updateCurrentPlan(response));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const upgradeChoices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .upgradeChoices(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.upgradeChoices(data));
      dispatch(successShowApiMessages(response))
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const downgradeChoices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .downgradeChoices(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.downgradeChoices(data));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const getupgradeChoices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getupgradeChoices()
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.upgradeChoices(data));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getdowngradeChoices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getdowngradeChoices(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.downgradeChoices(data));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
