import MyWalletInvoicesDashboard from './invoice';

function MyWalletInvoicespage() {
  return (
    <div
      className="dashboard-container"
    >
      <MyWalletInvoicesDashboard />
    </div>
  );
}

export default MyWalletInvoicespage;
