import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { SystemUsers } from 'modules/admin/system_users/system_users';
import { modalActions } from 'modules/modals';
import { useDispatch } from 'react-redux';

const SystemUsersContainer = () => {
  const dispatch = useDispatch();

  const addUser = () => {
    dispatch(modalActions.add_user())
  }

  return (
    <div
      className="dashboard-container"
      maxWidth={false}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box style={{ padding: '2rem 0' }}>
          <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}>Admin</Typography>
          <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}>User List</Typography>
        </Box>
        <Button onClick={() => addUser()} style={{
          border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
        }
        }>
          <AddIcon color="#606060" />
          Add User
        </Button>
      </Box>
      <div className='super-customer'>
        <SystemUsers />
      </div>
    </div>
  );
};

export default SystemUsersContainer;
