export const modalConstants = {  
    HIDE_MODAL: 'HIDE_MODAL',
    ADD_USER: 'ADD_USER',
    EDIT_USER:'EDIT_USER',
    CLEAR: 'CLEAR',
    VIEW_SUBSCRIPTION: 'VIEW_SUBSCRIPTION',
    DELETE_USER: 'DELETE_USER',
    WORKSPACE_STATUS: 'WORKSPACE_STATUS',
    UPGRADE_SUBSCRIPTION: 'UPGRADE_SUBSCRIPTION',
    DOWNGRADE_SUBSCRIPTION: 'DOWNGRADE_SUBSCRIPTION',
    UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
    SEND_REMINDER: 'SEND_REMINDER',
    SEND_MESSAGE: 'SEND_MESSAGE',
    VIEW_TICKET: 'VIEW_TICKET',
    SEND_TO_ALL: 'SEND_TO_ALL',
    CANCEL_SUB: 'CANCEL_SUB',
    CONFIRM_CANCEL_SUB: 'CONFIRM_CANCEL_SUB',
    UNDO_CANCEL_SUB: 'UNDO_CANCEL_SUB',
    UNDO_CONFIRM_CANCEL_SUB: 'UNDO_CONFIRM_CANCEL_SUB',
    CONFIRM_SEND_REMINDER: 'CONFIRM_SEND_REMINDER',
    CONFIRM_DOMAIN_TRANSFER: 'CONFIRM_DOMAIN_TRANSFER',
};
