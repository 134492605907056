import Tooltip from 'components/tooltip';
import { useEffect, useState } from 'react';

function Status({ status }) {
  const [color, setColor] = useState();
  const [text, setText] = useState();
  const [, setTooltip] = useState(false);


  useEffect(() => {
    switch (status) {
      case "ACTIVE":
        setText('active');
        setColor('#5ebd12');
        break
      case "SUSPENDED":
        setText('SUSPENDED')
        setColor('#FFC700');
        break
      case "Insufficient Balance":
        setText('Insufficient Balance')
        setColor('red');
        break;
      case true:
        setText('active');
        setColor('#5ebd12');
        break
      case false:
        setText('inactive');
        setColor('red');
        break
      case "No Subscription":
        setText('No Subscription');
        setColor('red');
        break
      // case "SUSPENDED PENDING_TOS_ACCEPTANCE":
      //   setText('SUSPENDED PENDING_TOS_ACCEPTANCE');
      //   setColor('red');
      //   break
      default:
        setText(status);
        setColor('red');
        setTooltip(true)
        break;
    }
  }, [status]);

  return (
    <>
    <span
      style={{
        backgroundColor: color,
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: 500,
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '8px', 
      }}
      variant="contained"
    > {text}
    </span>
    {status.split(' ') && status.split(' ').includes('PENDING_TOS_ACCEPTANCE') && <Tooltip tip={'Please confirm the terms of engagement on the email link'}></Tooltip>}
    
    </>
  );
}

export default Status;


