import {
  Box, Button, Typography, CircularProgress,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { useFormik } from 'formik';
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { googleSignupWithAdditionalData } from '../_redux/authActions';

const validationSchema = yup.object({
  country: yup
    .string('country your email')
    .required('country is required'),
  phoneNumber: yup
    .string('Enter your phoneNumber')
    .required('phoneNumber is required'),
});

function Googlesignup() {
  
  const dispatch = useDispatch();

  const { actionsLoading } = useSelector(state => state.oauth, shallowEqual)

  const formik = useFormik({
    initialValues: {
      country: '',
      phoneNumber: ''
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(googleSignupWithAdditionalData(values))
    },
  });
  const classes = useAuthStyles();

  return (
    <div style={{ padding: '200px 7.2vw', }}>
      <Box className={classes.login}>
        <Typography
          className={classes.loginTitle}
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontSize: '1.5rem',
            fontWeight: '500',
            lineHeight: '29px',
            textAlign: 'center',
            letterSpacing: '-0.015em',
            color: '#041C26',
          }}
        >
          Enter details to continue
        </Typography>
        <ApiMessages/>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <CountryDropdown
            value={formik.values.country}
            name={'country'}
            onChange={(val) => formik.setFieldValue('country', val)}
            style={{
              padding: '12px 9px',
              width: '100%',
              borderRadius: '6px',
              background: 'white',
              margin: '7px 0',
              borderColor: 'lightgray',
            }}
          />
          {formik.errors.country && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">{formik.errors.country}</p>}

          <PhoneInput
            country="ke"
            name={'phoneNumber'}
            value={formik.values.phoneNumber}
            onChange={(value, data, event, formattedValue) => formik.setFieldValue('phoneNumber', formattedValue)}
            specialLabel="Phone Number"
            enableSearch={true}
            autocompleteSearch={true}
            style={{ margin: '10px 0' }}
            inputStyle={{
              width: '100%',
              padding: '12px 53px',
            }}
            searchStyle={{
              width: '100%',
            }}
            inputProps={{
              name: 'phoneNumber',
              required: true,
            }}
          />
          {formik.errors.phoneNumber && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">{formik.errors.phoneNumber}</p>}

          <div>
            <Typography variant="p" style={{ fontWeight: 'normal' }} className={classes.altText}>
              Forgot Password? <Link to="/forgot-password">Reset</Link>
            </Typography>
          </div>

          <Button
            color="primary"
            variant="outlined"
            type="submit"
            className={classes.submit}
          >
            Submit
            {actionsLoading && (
              <CircularProgress
                size={24}
                color="loader"
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </form>
      </Box>
    </div>
  );
}

export default Googlesignup;
