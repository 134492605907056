import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Menu,
  Avatar,
  Typography,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect, memo, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import IncentroLogo from '../../assets/images/logo.svg';
import { getAllEditions } from 'modules/adduser/_redux/accounts/accountsActions';
import { updateCurrentPlan } from 'modules/pricing/_redux/planActions';
import Cookies from 'js-cookie';
import { getCurrentUserProfile, showApiMessages, successShowApiMessages } from 'modules/authentication/_redux/authActions';
import { googleLogout } from '@react-oauth/google';

const user = localStorage.getItem('user');
const userJson = JSON.parse(user);

const headersData = [
  user !== null && {
    label: 'Dashboard',
    href: '/dashboard',
  },
  ((user !== null) && (userJson?.role_titles !== undefined ? userJson?.role_titles.includes('Super Administrator') : userJson?.authorities.includes('SUPER ADMINISTRATOR'))) && {
    label: 'Users',
    href: '/dashboard/system-users',
  },
  ((user !== null) && (userJson?.role_titles !== undefined ? userJson?.role_titles.includes('Administrator') : userJson?.authorities.includes('ADMINISTRATOR'))) && {
    label: 'My Wallet',
    href: '/my-wallet',
  },
  {
    label: 'Products',
    href: '/pricing',
  },
  ((user !== null) && (userJson?.role_titles !== undefined ? userJson?.role_titles.includes('Super Administrator') : userJson?.authorities.includes('SUPER ADMINISTRATOR'))) && {
    label: 'Tickets',
    href: '/dashboard/tickets',
  },
  {
    label: 'Support',
    href: '/support',
  },
  {
    label: user === null ? 'Log In' : 'Logout',
    href: user === null ? '/login' : '#',
  },
  user === null && {
    label: 'Sign up',
    href: '/onboarding?q=1',
    id: 'lastChild',
  },
];

const useStyles = makeStyles(() => ({
  header: {
    paddingRight: '6vw',
    paddingLeft: '6vw',
    boxShadow: '0 4px 2px -2px gray',
    paddingTop: '20px',
    paddingBottom: '20px',
    textTransform: 'capitalize',
    fontWeight: '500',
    '@media (max-width: 900px)': {
      paddingLeft: '7.2vw',
      paddingRight: '7.2vw',

    },
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    color: '#000',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  drawerContainer: {
    padding: '20px 20px',
  }
}));

function Header() {
  const location = useLocation();
  const {
    header, toolbar,
    drawerContainer,
  } = useStyles();
  const [scroll, setScroll] = useState(false);
  const [activeUrl, setActiveUrl] = useState('');
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.oauth);
  const { allEditions, subscriptions } = useSelector((state) => state.workspace);
  const { currentUserProfile, actionsLoading } = currentUser;
  const { setGlobalValue } = useSelector((state) => state.workspace);

  useEffect(() => {
    if (location.pathname) {
      setActiveUrl(location?.pathname)
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(showApiMessages())
    dispatch(successShowApiMessages())
  }, [dispatch, location, setGlobalValue]);



  useEffect(() => {
    user && dispatch(getCurrentUserProfile());
    user && dispatch(getAllEditions());
  }, [dispatch]);

  useEffect(() => {
    if ((allEditions !== null && user !== null)) {
      const setCurrentEdition = () => {
        const getCurrentEdition = allEditions.filter(item => item.label.toLowerCase() === subscriptions[0]?.editionLabel.toLowerCase())
        dispatch(updateCurrentPlan(getCurrentEdition && getCurrentEdition[0]));
      }
      setCurrentEdition()
    }
  }, [subscriptions, allEditions, dispatch]);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const mobileViewMoization = useMemo(() => scroll, [scroll]);

  const { mobileView, drawerOpen } = state;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    var mediaSize = window.matchMedia("(max-width: 1300px)")

    function set() {
      if (mediaSize.matches) {
        setState((prevState) => ({ ...prevState, mobileView: true }))
      } else if (!mediaSize.matches) {
        setState((prevState) => ({ ...prevState, mobileView: false }))
      }
      else return null
    }
    set();

    mediaSize.addListener(set)

    window.addEventListener('resize', () => set());

    return () => {
      window.removeEventListener('resize', () => set());
    };
  }, []);

  window.addEventListener = ('scroll', (event) => {
    setScroll(window.scrollY > 50);
  });

  const displayDesktop = () => (
    <Toolbar className={toolbar}>
      {incentroLogo}
      <div className="main-menu">{getMenuButtons(activeUrl)}</div>
    </Toolbar>
  );

  const displayMobile = () => {
    const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () => setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar role="navigation" sx={{ boxShadow: 0, display: 'flex', justifyContent: 'space-between' }}>
        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div style={{ padding: '20px' }}>{incentroLogo}</div>
          {getMenuButtonsMobile()}
        </Drawer>

        <div>{incentroLogo}</div>
        <IconButton
          {...{
            edge: 'start',
            color: 'primary',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => headersData.map(({ label, href, i }) => {
    if (href !== undefined) {
      return (
        <>
          <RouterLink style={{ textDecoration: 'none', color: '#000' }} key={i} to={href}>
            <MenuItem style={{ textDecoration: 'none' }} className={`${activeUrl === href ? 'activeUrl' : ''}`}
            >
              {label}
            </MenuItem>
          </RouterLink>
        </>
      )

    }
  })

  const incentroLogo = (
    <RouterLink to="/">
      <img className='main-logo' src={IncentroLogo} alt="" />
    </RouterLink>
  );

  const subMenuItemStyles = {
    border: 'thin solid #EFF0F1',
    margin: '12px 15px',
    borderRadius: '4px',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'text',

  };

  const subMenuItemLinkStyles = {
    ...subMenuItemStyles,
    border: 'thin solid #18A0FB',
    color: '#18A0FB',
    cursor: 'cursor',
    userSelect: 'text'
  };

  const getMenuButtons = (activeUrl) => headersData.map(({ label, href, id }) => (
    href && label === 'Logout' ? (
      <>
        <Button
          key={label}
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ padding: { xs: 0, md: '0 1rem' } }}
        >
          <Avatar alt="Profile photo" src={currentUserProfile?.profileImageData} />
          {currentUserProfile?.companyName !== null && (
            <Typography style={{ padding: '0 0.6rem' }}>{currentUserProfile?.firstName} {currentUserProfile?.lastName}</Typography>
          )}
          <ArrowDropDownIcon color="#606060" />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{ width: '300px' }}
        >
          <MenuItem key={id} style={{ justifyContent: 'center', '&:hover': { backgroundColor: 'red' } }}>
            <Avatar
              alt="Remy Sharp"
              src={currentUserProfile?.profileImageData}
              style={{
                width: '120px',
                height: '120px',
                border: 'thick solid #EFF0F1',
              }}
            />
          </MenuItem>
          {actionsLoading && <p>Loading</p>}
          {!actionsLoading && (
            <div>
              <MenuItem
                style={subMenuItemStyles}
              >
                <b>
                  {currentUserProfile?.firstName} {currentUserProfile?.lastName}
                </b>
              </MenuItem>
              <MenuItem style={subMenuItemStyles}>
                {currentUserProfile?.role}
              </MenuItem>
              <MenuItem style={subMenuItemStyles}>
                {currentUserProfile?.email?.substring(0, 30)} {currentUserProfile?.email?.length > 30 && '...'}
              </MenuItem>
              <RouterLink to="/edit-profile" style={{ textDecoration: 'none' }}>
                <MenuItem style={subMenuItemLinkStyles}>
                  Edit Profile
                </MenuItem>
              </RouterLink>
              <Divider />
              <MenuItem style={{ justifyContent: 'center' }}>
                <Button variant="outlined" onClick={LogoutHandler} fullWidth>Sign Out</Button>
              </MenuItem>
            </div>
          )}
        </Menu>
      </>
    ) : href && (
      <Button
        {...{
          key: label,
          color: 'primary',
          to: href,
          component: RouterLink,
          className: `${activeUrl === href ? 'activeUrl' : ''}`,
          id,
        }
        }
      >
        {label}
      </Button>
    )
  ));

  const getMenuButtonsMobile = (activeUrl) => headersData.map(({ label, href, id }) => (
    href && label === 'Logout' ? (
      <>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{ width: '300px' }}
        >
          <MenuItem key={id} style={{ justifyContent: 'center', '&:hover': { backgroundColor: 'red' } }}>
            <Avatar
              alt="Remy Sharp"
              src={currentUserProfile?.profileImageData}
              style={{
                width: '120px',
                height: '120px',
                border: 'thick solid #EFF0F1',
              }}
            />
          </MenuItem>
          {actionsLoading && <p>Loading</p>}
          {!actionsLoading && (
            <div>
              <MenuItem
                style={subMenuItemStyles}
              >
                <b>
                  {currentUserProfile?.firstName} {currentUserProfile?.lastName}
                </b>
              </MenuItem>
              <MenuItem style={subMenuItemStyles}>
                {currentUserProfile?.role}
              </MenuItem>
              <MenuItem style={subMenuItemStyles}>
                {currentUserProfile?.email?.substring(0, 30)} {currentUserProfile?.email?.length > 30 && '...'}
              </MenuItem>
              <RouterLink to="/edit-profile" style={{ textDecoration: 'none' }}>
                <MenuItem style={subMenuItemLinkStyles}>
                  Edit Profile
                </MenuItem>
              </RouterLink>
              <Divider />
              <MenuItem style={{ justifyContent: 'center' }}>
                <Button variant="outlined" onClick={LogoutHandler} fullWidth>Sign Out</Button>
              </MenuItem>
            </div>
          )}
        </Menu>
        <Button
          key={label}
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ padding: { xs: 0, md: '0 1rem' } }}
        >
          <Avatar alt="Profile photo" src={currentUserProfile?.profileImageData} />
          {currentUserProfile?.companyName !== null && (
            <Typography style={{ padding: '0 0.6rem' }}>{currentUserProfile?.firstName} {currentUserProfile?.lastName}</Typography>
          )}
          <ArrowDropDownIcon color="#606060" />
        </Button>
      </>
    ) : href && (
      <Button
        {...{
          key: label,
          color: 'primary',
          to: href,
          component: RouterLink,
          className: `${activeUrl === href ? 'activeUrl' : ''}`,
          id,
        }
        }
      >
        {label}
      </Button>
    )
  ));

  return (
    <AppBar className={`${header}${mobileViewMoization ? ' MuiAppBar-headerScrolled' : ''}`}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
}

export const MemoizedHeader = memo(Header);

export const LogoutHandler = () => {
  window.localStorage.removeItem('user');
  Cookies.remove('currentPlan');
  setTimeout(() => {
    googleLogout()
    window.location.href = '/';
  }, 1000);
};
