import {
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  Box,
  CardActions,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    alignSelf: 'center',
    margin: '100px 0',
  },
  content: {
    paddingLeft: '0.5rem',
  },
  costText: {
    alignSelf: 'center',
  },
  contentPaymentPlan: {
    paddingLeft: '0.5rem',
  },
  card: {
    background: '#18A0FB',
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
}));

function PaymentSuccess(props) {
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;

  const amount = new URLSearchParams(search).get('amount');
  const currency = new URLSearchParams(search).get('currencyCode');

  return (
    <Container maxWidth="lg" style={{ alignSelf: 'center', padding: '100px 0' }}>
      <Box className={classes.root}>
        <Grid container spacing={2} className={classes.hero}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Box className={classes.card}>
              <Card style={{ background: '#18A0FB' }}>
                <CardContent>
                  <Box style={{ textAlign: 'center' }}>
                    <Typography sx={{ mb: 0, color: 'white', fontSize: '1.25em' }} color="text.secondary">
                      You have successfully credited your Wallet with {amount} {currency}.
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <br />
            <CardActions style={{ justifyContent: 'end', padding: '10px 0' }}>
              <Link to="/my-wallet" style={{ textDecoration: 'none' }}>
                <Button
                  sx={{ marginLeft: { xs: '2px', md: '20px' } }}
                  style={{
                    padding: '4px 20px',
                    border: 'thin solid #233168',
                    color: '#233168',
                    cursort: 'pointer',
                  }}
                >
                  View Wallet
                </Button> &nbsp;
              </Link>
              {/* <LoadWalletModal /> */}
            </CardActions>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default PaymentSuccess;
