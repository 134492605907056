import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { Customers } from 'modules/admin/customers/customers';
import { modalActions } from 'modules/modals';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const SuperAdminDashboard = () => {
  const dispatch = useDispatch()
  const sendMessage = () => {
    dispatch(modalActions.send_to_all())
  }
  return (
    <div className='super'>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box style={{ padding: '2rem 0' }}>
          <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}>Admin</Typography>
          <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}>User List</Typography>
        </Box>
        <Box style={{ padding: '2rem 0' }}>
          <Link to="/dashboard/messages" style={{ textDecoration: 'none', padding: '0 20px' }}>
            <Button style={{
              border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
            }
            }>
              View Messages
            </Button>

          </Link>

          <Button onClick={() => sendMessage()} style={{
            border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
          }
          }>
            <AddIcon color="#606060" />
            Bulk
          </Button>
        </Box>
      </Box>
      <div className='super-customer'>
        <Customers />
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
