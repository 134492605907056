import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import { getAllSubscriptions } from 'modules/adduser/_redux/accounts/accountsActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PriceModal from './pricingModal';
import './index.scss';
import { Link } from 'react-router-dom';
import { mapItems } from 'components/easySetup/easySetup';
import Cookies from 'js-cookie';

const tiers = [
  {
    id: 51,
    title: 'Business Starter',
    subheader: '',
    price: '6',
    description: [
      'Custom and secure business email',
      '100 participant video meetings ',
      '30 GB cloud storage per user',
      'Security and management controls',
      'Standard Support',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'outlined',
    buttonTestid: 'get-started-starter',
  },
  {
    id: 52,
    title: 'Business Standard',
    subheader: 'Most popular',
    price: '12',
    description: [
      'Custom and secure business email',
      '150 participant video meetings + recording',
      '2 TB cloud storage per user',
      'Security and management controls',
      'Standard Support (paid upgrade to Enhanced Support)',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'outlined',
  },
  {
    id: 53,
    title: 'Business Plus',
    price: '18',
    description: [
      'Custom and secure business email + eDiscovery, retention',
      '500 participant video meetings + recording, attendance tracking',
      '5 TB cloud storage per user',
      'Enhanced security and management controls, including Vault and advanced endpoint management',
      'Standard Support (paid upgrade to Enhanced Support)',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'outlined',
  },
  {
    id: 54,
    title: 'Enterprise',
    price: 'Get In Touch',
    description: [
      'Custom and secure business email + eDiscovery, retention, S/MIME encryption',
      '500 participant video meetings + recording, attendance tracking, noise cancellation, in-domain live streaming',
      'As much storage as you need*',
      'Advanced security, management, and compliance controls, including Vault, DLP, data regions, and enterprise endpoint management',
      'Enhanced Support (paid upgrade to Premium Support)',
    ],
    buttonText: 'Contact Sales',
    buttonVariant: 'outlined',
  },
];

function PricingPage() {
  const currentUser = useSelector((state) => state.oauth);
  const dispatch = useDispatch();
  const { currentUserProfile } = currentUser;

  const onSelectPlan = (tier) => {
    const selected = { selected: true, ...tier };
    Cookies.set('currentPlan', JSON.stringify({ ...selected }));
  };

  const {
    actionsLoading, subscriptions,
  } = useSelector(
    (state) => ({
      actionsLoading: state.workspace.actionsLoading,
      subscriptions: state.workspace.subscriptions,
      lastError: state.workspace.lastError,
    }),
    shallowEqual,
  );


  useEffect(() => {
    if (currentUserProfile && currentUserProfile.role === 'Administrator') {
      dispatch(getAllSubscriptions());
    }
  }, [dispatch, currentUserProfile]);

  return (
    <Box className='pricingpage' style={{ padding: '0 7.2vw' }}>
      {/* Hero unit */}
      <Typography
        component="p"
        variant="p"
        align="center"
        color="text.primary"
        style={{ paddingTop: '15px', fontWeight: '500', fontSize: '1.6em' }}
      >
        Choose your edition
      </Typography>
      <Typography
        variant="p"
        align="center"
        color="text.primary"
        component="p"
        style={{ padding: '0 15vw' }}
      >
        Workspace plans start as low as $6 per user per month for Business
        Starter, $12 per user per month for
        Business Standard, and $18 per
        user per month for Business Plus. Quickly build an effective pricing
        table for your potential
        customers with this layout.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        align="center"
        color="text.primary"
        style={{ padding: { xs: '0 10px 50px', md: '0 120px 50px' }, fontWeight: '500', fontSize: '1.4em' }}
      >
        Every plan includes
      </Typography>
      <br />
      <Box style={{ paddingBottom: '4rem' }}>
        {mapItems()}
      </Box>
      {/* End hero unit */}
      {actionsLoading}
      <Grid container spacing={5} alignItems="stretch">
        {actionsLoading ? <center><p>Loading Plan</p></center> : tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            className={`${tier?.title === subscriptions[0]?.editionLabel ? 'pricing__card-active pricing__card' : 'pricing__card'}`}
            style={{ cursor: 'pointer' }}
            key={tier.title}
            xs={11.5}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={3}
          >
            <Card
              style={{
                background: 'white',
                color: 'black',
                height: '100%',
                display: 'flex',
                justifyContent: 'start',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
            >
              <CardHeader
                title={tier.title}
                subheader={tier.subheader ? tier.subheader : <br />}
                titleTypographyProps={{
                  component: 'h3', variant: 'h3', align: 'center', color: 'black',
                }}
                action={
                  tier.title === 'Business Standard' ? <StarIcon /> : null
                }
                subheaderTypographyProps={{
                  align: 'center',
                  color: 'text.primary',
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >{tier.title === 'Enterprise' ? (
                  <Typography variant="h6" color="text.secondary">Contact Sales Team</Typography>
                ) : (
                  <>
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /user/mo
                    </Typography>
                  </>
                )}
                </Box>
                <CardActions style={{ justifyContent: 'center' }} onClick={() => onSelectPlan(tier)}>
                  <Link to={subscriptions?.length > 0 ? `/dashboard` : '/edition/current-plan'} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="outlined"
                      style={{fontSize: '.8em !important', padding: '0.2em 0.4em !important'}}
                      disabled={subscriptions?.length > 0}
                      data-testid={tier?.testid}
                    >
                      {`${tier?.title === subscriptions[0]?.editionLabel ? 'subscribed' : tier?.buttonText}`}
                    </Button>
                  </Link>
                </CardActions>
                <List dense={true}>
                  {tier.description.map((line) => (
                    <ListItem key={line}>
                      <ListItemIcon>
                        <CheckIcon sx={{ color: 'text.primary' }} />
                      </ListItemIcon>
                      <ListItemText primary={line} />
                    </ListItem>
                  ))}
                </List>
                <center>
                  <PriceModal
                    text="View More"
                    tier={tier}
                    fullWidth
                    variant={tier.buttonVariant}
                    title={tier.title}
                    testid={tier.buttonTestid}
                  />
                </center>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PricingPage;
