
import { AddSystemUsers } from 'modules/admin/system_users/add_users';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { modalActions } from '../../_actions';


import './ModalRoot.scss';
import { EditSystemUsers } from 'modules/admin/system_users/edit_user';
import ViewSubscription from 'modules/admin/customers/viewSubscription';
import { DeleteUser } from '../deleteUser/deleteUser';
import { WorkspaceStatus } from 'components/dashboard/Admin/workSpaceStatus';
import { UpgradeSubscription } from 'modules/admin/subscriptions/upgradesubscription';
import { DowngradeSubscription } from 'modules/admin/subscriptions/downgradesubscription';
import { UpdateSubscription } from 'modules/admin/subscriptions/updatesubscription';
import { Dialog } from '@mui/material';
import { SendReminder } from 'modules/admin/subscriptions/sendReminder';
import { SendMessage } from 'modules/admin/subscriptions/sendMessage';
import { ViewTicket } from 'components/dashboard/SuperAdmin/support/view_ticket';
import { SendToAll } from 'modules/admin/customers/sendToAll';
import { CancelSubs } from 'modules/admin/subscriptions/cancelSubscription';
import { ConfirmSubs } from 'modules/admin/subscriptions/confirmSubs';
import { UndoCancelSubs } from 'modules/admin/subscriptions/undocancelSubscription';
import { UndoConfirmSubs } from 'modules/admin/subscriptions/undoconfirmSubs';
import { ConfirmReminder } from 'modules/admin/subscriptions/confirmReminder';
import { ConfirmTransfer } from '../confirmTransfer/confirmTransfer';

const MODAL_COMPONENTS = {
    ADD_USER: AddSystemUsers,
    EDIT_USER: EditSystemUsers,
    VIEW_SUBSCRIPTION: ViewSubscription,
    DELETE_USER: DeleteUser,
    WORKSPACE_STATUS: WorkspaceStatus,
    UPGRADE_SUBSCRIPTION: UpgradeSubscription,
    DOWNGRADE_SUBSCRIPTION: DowngradeSubscription,
    UPDATE_SUBSCRIPTION: UpdateSubscription,
    SEND_REMINDER: SendReminder,
    SEND_MESSAGE: SendMessage,
    VIEW_TICKET: ViewTicket,
    SEND_TO_ALL: SendToAll,
    CANCEL_SUB: CancelSubs,
    CONFIRM_CANCEL_SUB: ConfirmSubs,
    UNDO_CANCEL_SUB: UndoCancelSubs,
    UNDO_CONFIRM_CANCEL_SUB: UndoConfirmSubs,
    CONFIRM_SEND_REMINDER: ConfirmReminder,
    CONFIRM_DOMAIN_TRANSFER: ConfirmTransfer

};

const ModalRoot = ({ type, content, dispatch }) => {
    const [scroll,] = useState('paper');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        if (type) {
            setModalIsOpen(true)
        }
    }, [type])

    if (!type) {
        return null;
    }

    const closeModal = () => {
        setModalIsOpen(false);
        dispatch(modalActions.clear());
        dispatch(modalActions.hideModal(0));
    };

    if (type === 'HIDE_MODAL') {
        closeModal();
        return null;
    }

    const SpecificModal = MODAL_COMPONENTS[type];

    return (
        <Dialog
            open={modalIsOpen}
            maxWidth='100%'
            disableEscapeKeyDown={false}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            onClose={closeModal}
        >
            {SpecificModal && <SpecificModal modalID={1} type={type} data={content} />}
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { modals } = state;
    return (modals);
};

const connectedModalRoot = connect(mapStateToProps)(ModalRoot);
export { connectedModalRoot as ModalRoot };
