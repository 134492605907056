import {
  Grid, Typography,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { InputField } from 'components/FormFields';
import PasswordStrengthBar from 'react-password-strength-bar';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import '../../authentication/signup/signup.scss';
import GoogleComponent from 'modules/authentication/google';
import RedirectGoogleAuth from 'modules/authentication/google/redirectGoogleAuth';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { _renderHelperText } from 'utils';

function Signup(props) {
  const {
    formField: {
      firstName, lastName, email, password, verifyPassword, phoneNumber,
    },
    values,
    setFieldValue,
    errors,
  } = props;
  const classes = useAuthStyles();

  return (
    <>
      <Typography
        className={classes.loginTitle}
        style={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontSize: '1.3rem',
          fontWeight: '500',
          lineHeight: '29px',
          textAlign: 'center',
          letterSpacing: '-0.015em',
          color: '#041C26',
        }}
      >
        Sign up for a free account
      </Typography>
      <ApiMessages/>
      <Typography className={classes.titleSubTitle} style={{ color: '#444B4E' }}>
        Our promise: No hidden charges, always in control of what you spent
      </Typography>
      <GoogleComponent action="Sign in with Google" isLoginPage={true} />
      <Grid container alignItems="center" spacing={3} style={{ marginLEft: 0 }}>
        <Grid item xs>
          <InputField
            name={firstName.name}
            label={firstName.label}
            fullWidth
            icon="user"
            hasIcon
          />
        </Grid>
        <Grid item xs>
          <InputField
            name={lastName.name}
            label={lastName.label}
            icon="user"
            hasIcon
          />
        </Grid>
      </Grid>
      <InputField
        name={email.name}
        label={email.label}
        icon="envelope"
        hasIcon
      />
      <PhoneInput
        country="ke"
        name={phoneNumber.name}
        value={values.phoneNumber}
        onChange={(value, data, event, formattedValue) => setFieldValue('phoneNumber', formattedValue)}
        specialLabel="Phone Number"
        enableSearch={true}
        autocompleteSearch={true}
        style={{ margin: '10px 0' }}
        inputStyle={{
          width: '100%',
          padding: '12px 53px',
        }}
        searchStyle={{
          width: '100%',
        }}
        inputProps={{
          name: 'phoneNumber',
          required: true,
        }}
      />
      {_renderHelperText(errors.phoneNumber)}
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs style={{ display: 'flex' }}>
          <InputField
            name={password.name}
            label={password.label}
            icon="lock"
            hasIcon
            type="password"
          />
          <br />
        </Grid>
        <Grid item xs style={{ display: 'flex' }}>
          <InputField
            name={verifyPassword.name}
            label={verifyPassword.label}
            icon="lock"
            hasIcon
            type="password"
          />
          <br />
        </Grid>
      </Grid>
      {props.values.password ? (
        <PasswordStrengthBar password={values.password} />
      ) : ''}
      <RedirectGoogleAuth />
    </>
  );
}

export default Signup;
