import {
    Box, Button, Card, Grid, Link, Typography,
  } from '@mui/material';
  import { useSelector } from 'react-redux';
import InvoicesTable from './table';
  
  function MyWalletInvoicesDashboard() {
    
    const currentUser = useSelector((state) => state.oauth);

    const { actionsLoading : userloading, currentUserProfile } = currentUser;
   
  
    return (
      <Box>
        <Box style={{ padding: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}></Typography>
            <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}>Invoices</Typography>
          </Box>
        </Box>
        {/* {userloading && <CircularProgress />} */}
        {currentUserProfile?.companyName === null && (
          <>
            <Typography style={{ fontSize: '1.2em' }}>You've not created a workspace yet</Typography>
            <br />
            <Link href="/onboarding?q=2">
              <Button variant="outlined">Create WorkSpace</Button>
            </Link>
          </>
        )}
        {currentUserProfile?.companyName !== null && !userloading && (
          <Grid container xs={12} style={{ alignItems: 'stretch', width: '100%' }}>
            <Grid item xs md={12} style={{ alignItems: 'stretch', display: 'block', paddingTop: '30px' }}>
              <Card
                style={{
                  display: 'block',
                  width: '100%',
                  margin: '0',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <InvoicesTable/>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
  
  export default MyWalletInvoicesDashboard;
  