import { createSlice } from '@reduxjs/toolkit';

const initialWorkspaceState = {
  listLoading: false,
  actionsLoading: false,
  createWorkspaceStatus: undefined,
  createContactInfoStatus: undefined,
  totalCount: 0,
  subscriptions: [],
  entities: null,
  workSpaceForEdit: undefined,
  subscription: {},
  workSpaceSatus: null,
  workSpaceContactInfoSatus: null,
  lastError: null,
  allEditions: [],
  workspacesStatus: {},
  purchasePlan: null,
  purchasePlanStatus: null,
  loading: false,
  cancelsub: null,
  changeTab: false,
  transferWorkspace: null,
  setGlobalValue: 0,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const workpacesSlice = createSlice({
  name: 'workspace',
  initialState: initialWorkspaceState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = null;
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    workspaceFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    subscriptionsFetched: (state, action) => {
      state.actionsLoading = false;
      state.subscriptions = action.payload;
      state.error = null;
    },
    subscriptionFetched: (state, action) => {
      state.actionsLoading = false;
      state.subscription = action.payload;
      state.error = null;
    },
    workspacesFetched: (state, action) => {
      const { totalCount, entity } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entity;
      state.totalCount = totalCount;
    },
    // createWorkSpace
    workspaceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.workSpaceSatus = action.payload;
    },
    // createWorkSpaceInfo
    workspaContactInfoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.workSpaceContactInfoSatus = action.payload;
    },
    // fetch editions
    editionsFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.allEditions = action.payload;
    },
    purchasePlanCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.purchasePlan = action.payload;
    },
    // updateBank
    workspaceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;

    },
    workspaceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    workspacesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id),
      );
    },
    workspacesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;   
      state.workspaceStatus = action.payload; 
    },
    transferWorkspace: (state, action) => {
      state.actionsLoading = false;
      state.error = null;   
      state.transferWorkspace = action.payload; 
    },
    customercancelSub: (state, action) => {
      state.actionsLoading = false;
      state.cancelsub = action.payload;
      state.error = null;
    },
    changeTab: (state, action) => {
      state.actionsLoading = false;
      state.changeTab = true;
      state.error = null;
    },
    setGlobalValue: (state, action) => {
      // state.actionsLoading = false;
      state.setGlobalValue = action.payload;
      state.actionsLoading = false;
      state.error = null;
    },
  },
});

const { reducer } = workpacesSlice;
export default reducer;
