import { useEffect, useState } from 'react';

function StatusTickets({ status }) {
  const [color, setColor] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    switch (status) {
      case "New":
        setText('New');
        setColor('#5ebd12');
        break
      case "In Progress":
        setText('In Progress')
        setColor('#FFC700');
        break
      default:
        setText(status);
        setColor('red');
        break;
    }
  }, [status]);

  return (
    <>
    <span
      style={{
        backgroundColor: color,
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '8px', 
      }}
      variant="contained"
    > {text}
    </span>    
    </>
  );
}

export default StatusTickets;


