import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';
import { modalActions } from 'modules/modals';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      padding: '10px 10px',
      fontFamily: 'Work Sans',
      fontSize: '.85em',
      '& .MuiSvgIcon-root': {
        fontSize: '11px',
        fontFamily: 'WorkSans',
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&:hover': {
        backgroundColor: '#18a0fb',
        color: '#fff',
      },
    },
  },
}));

export default function CustomersSubsActions({ id, row, customer_id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const openAct = Boolean(anchorEl);
  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosedAction = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={openAct ? 'actionmenu' : undefined}
        aria-haspopup="true"
        sx={{ backgroundColor: '#00acff', fontSize: '.8em', fontWeight: 500 }}
        aria-expanded={openAct ? 'true' : undefined}
        variant="contained"
        onClick={handleClickAction}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={openAct}
        onClose={handleClosedAction}

      >
        <MenuItem onClick={() => dispatch(modalActions.view_subscription({ row, customer_id }))}>
          View more
        </MenuItem>
        <MenuItem onClick={() => dispatch(modalActions.upgrade_subscription({ row, customer_id }))} disableRipple>
          Upgrade subscription
        </MenuItem>
        <MenuItem onClick={() => dispatch(modalActions.downgrade_subscription({ row, customer_id }))} disableRipple>
          Downgrade subscription
        </MenuItem>
        <MenuItem onClick={() => dispatch(modalActions.update_subscription({ row, customer_id }))} disableRipple>
          Update subscription
        </MenuItem>
        {row.status === 'PENDING CANCELLATION' &&
          <MenuItem onClick={() => dispatch(modalActions.cancel_sub({ row, customer_id }))} disableRipple>
            Confirm Cancellation
          </MenuItem>
        }
        {row.status === 'PENDING CANCELLATION' &&
          <MenuItem onClick={() => dispatch(modalActions.undo_cancel_sub({ row }))} disableRipple>
            Reject Cancellation
          </MenuItem>
        }
      </StyledMenu>
    </div>
  );
}
