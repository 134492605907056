import { useEffect, useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Box,
  Container,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as action from './_redux/accounts/accountsActions';
import Signup from './Forms/SignUp';

import SaveSuccess from './saveSuccess';

import validationSchema from './FormModel/validationSchema';
import FormModel from './FormModel/FormModel';

import useStyles from './styles';

import AddContactInformation from './Forms/AddContactInformation';
import Account from './Forms/Tabs';
import { getCurrentUserProfile, register } from 'modules/authentication/_redux/authActions';

const steps = ['Register', 'Google WorkSpace', 'Contact Information'];
const { formId, formField } = FormModel;

function _renderStepContent(step, setFieldValue, values, errors) {
  switch (step) {
    case 0:
      return (
        <Container maxWidth="sm">
          <Box style={{ padding: '0 20px' }}>
            <Signup
              formField={formField}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
            />
          </Box>
        </Container>
      );
    case 1:
      return (
        <Container maxWidth="sm">
          <Box style={{ padding: '0 20px' }}>
            <Account
              setFieldValue={setFieldValue}
              values={values}
              formField={formField}
              errors={errors}
            />
          </Box>
        </Container>
      );
    case 2:
      return (
        <Container maxWidth="sm">
          <Box style={{ padding: '0 20px' }}>
            <AddContactInformation
              setFieldValue={setFieldValue}
              values={values}
              formField={formField}
            />
          </Box>
        </Container>
      );
    default:
      return <div>Not Found</div>;
  }
}

function AddGoogleWorkspaceUser(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [success, setSuccess] = useState(false);
  const [usercurrentdata, setCurrentUserData] = useState({});
  const { setGlobalValue } = useSelector((state) => state.workspace);

  const dispatch = useDispatch();

  const {
    currentUserProfile,
  } = useSelector((state) => state.oauth);

  const {
    actionsLoading, workSpaceSatus, authactionsLoading, lastError, workSpaceContactInfoSatus,
  } = useSelector(
    (state) => ({
      actionsLoading: state.workspace.actionsLoading,
      workSpaceSatus: state.workspace.workSpaceSatus,
      authactionsLoading: state.oauth.actionsLoading,
      workSpaceContactInfoSatus: state.workspace.workSpaceContactInfoSatus,
      lastError: state.workspace.lastError,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (activeStep === 1) {
     if (workSpaceSatus) {
        const { data: { exist } } = workSpaceSatus;
        if (!exist) {
          setActiveStep(activeStep + 1);
        }
      }
    }
  }, [activeStep, dispatch, lastError, workSpaceSatus]);

  useEffect(() => {
  if (workSpaceContactInfoSatus) {
      setSuccess(true);
      dispatch(getCurrentUserProfile());
    }
  }, [dispatch, lastError, workSpaceContactInfoSatus]);

  useEffect(() => {
    setCurrentTab(setGlobalValue)
  }, [setGlobalValue])
  
  useEffect(() => {
    if (currentUserProfile !== null) {
      const {
        firstName, lastName, phoneNumber, email, progressState,
      } = currentUserProfile && currentUserProfile;

      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        progressState,
      };

      setCurrentUserData(data);
    }
  }, [currentUserProfile]);

  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    if (usercurrentdata !== null) {
      const { progressState } = usercurrentdata;
      switch (progressState) {
        case 'CreatingWorkspace':
          setActiveStep(1);

          break;
        case 'PurchaseEdition':
          window.location.href = '/dashboard';
          break;
        case 'LoadWallet':
          window.location.href = '/dashboard';
          break;
        default:
          break;
      }
    }
  }, [usercurrentdata]);


  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  
  async function _submitForm(data, actions) {
    await _sleep(1000);
    actions.setSubmitting(true);
    const payload = {
      companyName: data.companyName,
      domainName: data.domainName,
      language: data.language,
      country: data.country,
      city: data.city,
      postalCode: data.postalCode,
      streetAddressOne: data.streetAddressOne,
      streetAddressTwo: data.streetAddressTwo,
      title: data.title,
      firstName: data.contactfirstName,
      lastName: data.contactlastName,
      email: data.contactEmail,
      alternativeEmail: data.alternativeEmail,
      phoneNumber: currentUserProfile?.phoneNumber,
      regionCode: 'n/a',
    };

    dispatch(action.createContactInfo(payload));

    if (actionsLoading !== undefined) {
      if (actionsLoading) {
        actions.setSubmitting(actionsLoading);
      }
      actions.setSubmitting(actionsLoading);
    }
  }

  async function _saveWorkSpace(values, actions) {
    await _sleep(1000);
    actions.setSubmitting(true);
    const payload = {
      companyName: values.companyName,
      domainName: values.domainName,
      language: values.language,
      country: values.country,
      city: values.city,
      postalCode: values.postalCode,
      streetAddressOne: values.streetAddressOne,
      streetAddressTwo: values.streetAddressTwo,
      regionCode: 'n/a',
    };
    const transferPayload = {
      token: values.token,
      domainName: values.domainName,
    };
    actions.setSubmitting(true);
    if(currentTab === 1){
      dispatch(action.transferWorkspace(transferPayload));
    }else{
      dispatch(action.checkDomainWorkspace(payload));
    }

    if (actionsLoading) {
      actions.setSubmitting(true);
    }
    actions.setSubmitting(actionsLoading);
  }
  async function handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else if (activeStep === 0) {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        password: values.password,
        country: 'n/a',
      };
      dispatch(register(JSON.stringify(payload)));
    } else if (activeStep === 1) {
      
      _saveWorkSpace(values, actions);
      actions.setSubmitting(false);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <div className='registration-wrapper'>
    <div  className='registration'
    >
      <Stepper className='registration-stepper'  activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel className={classes.label}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {success ? (
        <SaveSuccess message="The operation was successful" />
      ) : (
        <Formik
          initialValues={{
            firstName: usercurrentdata && usercurrentdata?.firstName,
            lastName: usercurrentdata?.lastName,
            email: usercurrentdata?.email,
            phoneNumber: usercurrentdata?.phoneNumber,
            password: '',
            verifyPassword: '',
          }}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(
            {
              isSubmitting,
              errors,
              setFieldValue,
              onChange,
              values,
            },
          ) => (
            <Form id={formId}>
              {_renderStepContent(activeStep, setFieldValue, values, errors)}
              <div className={classes.buttons}>
                <div className={classes.wrapper}>
                  {activeStep !== 0 && (
                  <>
                    {activeStep === 1 && usercurrentdata.progressState === 'CreatingWorkspace' ? ('') : (
                      <Button
                        sx={{ marginLeft: { xs: '2px', md: '20px' } }}
                        onClick={handleBack}
                        style={{
                          marginTop: '30px',
                          padding: '4px 20px',
                          border: 'thin solid #233168',
                          color: '#233168',
                          cursort: 'pointer',
                        }}
                      >Back
                      </Button>
                    )}
                  </>
                  )}
                </div>
                <div className={classes.wrapper}>
                  <Button
                    disabled={(activeStep === 0 ? authactionsLoading : actionsLoading)}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.buttonSave}
                  >
                    Save
                    {(activeStep === 0 ? authactionsLoading : actionsLoading) && (
                      <CircularProgress
                        size={24}
                        color="loader"
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
    </div>
  );
}

export default AddGoogleWorkspaceUser;
