/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress, DialogContent } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSubscription } from 'modules/adduser/_redux/accounts/accountsActions';
import close from '../../../assets/images/pret.svg';

function ViewModal({ id, row }) {
  const [openModal, setOpenAction] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const dispatch = useDispatch();
  const {
    subscription, actionsLoading,
  } = useSelector(
    (state) => ({
      subscription: state.workspace.subscription,
      actionsLoading: state.workspace.actionsLoading,
    }),
    shallowEqual,
  );

  const handleClickOpen = (scrollType) => () => {
    setOpenAction(true);
    setScroll(scrollType);
  };

  const handleCloseAction = () => {
    setOpenAction(false);
  };

  useEffect(() => {
    if (openModal) {
      dispatch(getSubscription(id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <div style={{ width: '100%' }} role="button" onMouseDown={handleClickOpen('paper')} onKeyDown={handleClickOpen('paper')} aria-pressed="false" onClick={handleClickOpen('paper')} variant="outlined">
        View
      </div>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth="md"
        style={{ borderRadius: '20px' }}
        onClose={handleCloseAction}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Typography
          onClick={handleCloseAction}
          sx={{
            position: 'absolute', right: 0, padding: ' 25px 25px ', cursor: 'pointer',
          }}
        >
          <img src={close} alt="close button" />
        </Typography>
        <DialogTitle
          style={{
            fontSize: '36px', fontWeight: '700', lineHeight: '0.9', padding: '25px 15px 0', textAlign: 'center',
          }}
          id="scroll-dialog-title"
        >{subscription.companyName}
        </DialogTitle>
        <DialogTitle
          style={{
            fontSize: '18px', fontWeight: '300', padding: '5px 15px 5px', textAlign: 'center',
          }}
          id="scroll-dialog-title"
        >{row.domain}
        </DialogTitle>
        {
        actionsLoading
        && <CircularProgress /> }
        {
        !actionsLoading
        && (
        <DialogContent>
          <Box sx={{ display: 'flex', borderTop: '1px solid #c4c4c4', paddingTop: '30px' }}>
            <div style={{ width: '33%', fontSize: '15px' }}>
              <p>Payment Plan</p>
              <p style={{ fontWeight: 'bold' }}>{subscription.paymentPlan}</p>
            </div>
            <div style={{
              width: '33%', alignItems: 'center', textAlign: 'center', fontSize: '15px',
            }}
            >
              <p style={{ fontWeight: 'bold' }}>Licences</p>
              <p>{subscription.licences} assigned</p>
            </div>
            <div style={{ width: '33%', fontSize: '15px' }}>
              <p style={{ fontWeight: 'bold' }}>Estimated Monthly Cost</p>
              <p>{subscription.costPerMonth} USD per month</p>
              <p style={{ fontWeight: 'bold' }}>Plan Status: <span>{subscription.active ? 'Active' : 'Inactive'}</span></p>
            </div>
          </Box>
          <Box sx={{ display: 'flex', borderTop: '1px solid #c4c4c4', paddingTop: '30px' }}>
            <div style={{ width: '33%', fontSize: '15px' }}>
              <p>Billing Details</p>
              <p style={{ fontWeight: 'bold' }}>Next Billing Date</p>
              <p>{subscription.nextBillingDate}</p>
            </div>
            <div style={{
              width: '33%', alignItems: 'center', textAlign: 'center',
            }}
            >
              <p style={{ fontWeight: 'bold' }}>Payment account ID</p>
              <p>{subscription.accountId}</p>
            </div>
            <div style={{ width: '33%' }}>
              <Link to="/my-wallet/transactions">View transactions</Link>
            </div>
          </Box>
        </DialogContent>)
        }
      </Dialog>
    </>
  );
}

export default ViewModal;
