import {
  Box, Button, Card, Grid, Typography,
} from '@mui/material';
import { Link as LinkTo } from 'react-router-dom';
import LoadWalletModal from 'modules/payment/loadWallet/modal';
import { checkWalletBalance } from 'modules/payment/wallet/_redux/walletsActions';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import foldericon from 'assets/images/design/icon.svg';
import DashboardCard, { CardText } from './card';
import TransactionsTable from './Table/table';

function MyWalletDashboard() {
  const currentUser = useSelector((state) => state.oauth);
  const dispatch = useDispatch();
  const { currentUserProfile, actionsLoading : userloading } = currentUser;

  useEffect(() => {
    if (currentUserProfile !== null) {
      const { progressState } = currentUserProfile;
      switch (progressState) {
        case 'PendingPhoneNumber':
          window.location.href = '/google-signup';
          break;
        default:
          break;
      }
    }
  }, [currentUserProfile]);
  
  const {
    wallet: { walletBalance },
  } = useSelector(
    (state) => ({
      walletBalanceLoading: state.wallet.actionsLoading,
      wallet: state.wallet,
    }),
    shallowEqual,
  );

  // get user details
  useEffect(() => {
    dispatch(checkWalletBalance());
  }, [dispatch]);

  return (
    <Box>
      <Box style={{ padding: '2rem 0', display: 'flex', justifyContent: 'space-between', margin: '10px 15px 0 0',
 }}>
        <Box>
          <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}>My</Typography>
          <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}>Wallet</Typography>
        </Box>
        <Box>
          {currentUserProfile?.companyName !== null && (
            <LoadWalletModal />
          )}
        </Box>
      </Box>
      {/* {userloading && <CircularProgress />} */}
      {currentUserProfile?.companyName === null && (
        <>
          <Typography style={{ fontSize: '1.2em' }}>You've not created a workspace yet</Typography>
          <br />
          <LinkTo to="/onboarding?q=2">
            <Button variant="outlined">Create WorkSpace</Button>
          </LinkTo>
        </>
      )}
      {currentUserProfile?.companyName !== null && !userloading && (
        <Grid container item xs={12} style={{ alignItems: 'stretch', width: '100%' }}>
          <Grid item md={3} style={{ alignItems: 'stretch', display: 'flex', width: '100%' }}>
            <Card
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 15px 0 0',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <DashboardCard
                title="Available Balance"
                children={
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="dash-icon"><img style={{ width: '140%' }} alt="" src={foldericon} /></div>
                    <CardText title={`${walletBalance?.balance} ${walletBalance?.currency}`} subTitle="Balance" marginRight="1rem" marginLeft={0} />
                  </Box>
                }
                styleButtonJustify="end"
                buttons={
                  <LinkTo to="/wallet-status" style={{ textDecoration: 'none' }}>
                    <Button
                      size="small"
                      style={{ border: 'thin solid #00ACFF', color: '#00ACFF',  padding: '.1em .9em' }
                  }
                    >
                      Wallet Status
                    </Button>
                  </LinkTo>
                  }
              />
            </Card>
          </Grid>
          <Grid item  md={3} style={{ alignItems: 'stretch', display: 'flex', width: '100%' }}>
            <Card
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 15px 0',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <DashboardCard
                title="Outstanding Bill"
                children={
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="dash-icon"><img style={{ width: '140%' }} alt="" src={foldericon} /></div>
                    <CardText title={`${walletBalance?.currentBill} ${walletBalance?.currency}`} subTitle="" />
                  </Box>
                }
                styleButtonJustify="space-between"
                buttons={
                  <LinkTo to="/my-wallet/invoices" style={{ textDecoration: 'none' }}>
                    <Button
                      size="small"
                      style={{ border: 'thin solid #00ACFF', color: '#00ACFF',  padding: '.1em .9em' }
                  }
                    >
                      Invoices
                    </Button>
                  </LinkTo>
                  }
              />
            </Card>
          </Grid>
          <Grid item md={3} style={{ alignItems: 'stretch', display: 'flex', width: '100%', }}>
            <Card
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 15px 0 15px',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <DashboardCard
                title="Deposits this year"
                subheader={currentUserProfile?.domainName}
                children={
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="dash-icon"><img style={{ width: '140%' }} alt="" src={foldericon} /></div>
                    <CardText title={`${walletBalance?.depositThisYear}`} subTitle={`Currency: ${walletBalance?.currency}`} marginRight="1rem" marginLeft={0} />
                  </Box>
                }
                styleButtonJustify="space-between"
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={3} style={{ alignItems: 'stretch', display: 'flex' }}>
            <Card
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 0 0 15px',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <DashboardCard
                title="Expenditure"
                children={
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="dash-icon"><img style={{ width: '140%' }} alt="" src={foldericon} /></div>
                    <CardText title={`${walletBalance?.currentExpenditure} ${walletBalance?.currency}`} subTitle="yearly" />
                  </Box>
                }
                styleButtonJustify="space-between"
              />
            </Card>
          </Grid>
          <Grid item xs md={12} style={{ alignItems: 'stretch', display: 'block', paddingTop: '30px' }}>
            <Card
              style={{
                display: 'block',
                width: '100%',
                margin: '0',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <TransactionsTable />
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default MyWalletDashboard;
