import {
  Box, Button, Container, Grid, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthStyles } from 'assets/scss/components/_auth';
import './NotFound.scss';
import Bulb from 'assets/images/404-bulb.svg';
import { makeStyles } from '@mui/styles';

const Styles = makeStyles(() => ({
  wrapper: {
    paddingTop: '11rem',
    width: '700px',
    maxWidth: '700px',
    '@media (max-width: 568px)': {
      paddingTop: '6rem',
      paddingBottom: '1rem',
    },
  },
}));

function NotFoundPage() {
  const classes = useAuthStyles();
  const classs = Styles();
  const history = useNavigate();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container width="20vw"  className={classs.wrapper}>
      <Box className={classes.root}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs>
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '4em',
                lineHeight: '117px',
                color: '#18A0FB',
              }}
            >
              404
            </Typography>
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '1.3em',
                lineHeight: '44px',
                color: '#606060',
              }}
            >
              Ooops
            </Typography>
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '1.15em',
                lineHeight: '29px',
                color: '#606060',
              }}
            >
              Something Went Wrong
            </Typography>
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontSize: '1.1em',
                lineHeight: '22px',
                color: '#606060',
              }}
            >
              This is not your fault, the page you’re looking for is not found.
              But don’t worry, we’ve fixed it
            </Typography>
            <Button variant="outlined" onClick={goBack} sx={{ margin: '10px 0 0 0' }}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <img src={Bulb} alt="404" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default NotFoundPage;
