import { axiosBlobInstance, axiosInstance } from 'helpers/base.service';

export const WALLETURL = 'wallet/balance';
export const WALLETPENDINGPAYMENTS = 'wallet/pending-payments';
export const TINGG = 'payments/actions/create-payload';
export const GETTRANSACTIONS = 'transactions';
export const GETINVOICES = 'wallet/invoices';



export function getWalletBalance() {
  return axiosInstance().get(WALLETURL);
}

export function getPendingPayments() {
  return axiosInstance().get(WALLETPENDINGPAYMENTS);
}

export function redirectToPayment(payload) {
  return axiosInstance().post(TINGG, payload);
}

export function getTransactions({page, rowsPerPage}) {
  return axiosInstance().get(`${GETTRANSACTIONS}/?page=${page}&size=${rowsPerPage}`);
}

export function getInvoices({page, rowsPerPage}) {
  return axiosInstance().get(`${GETINVOICES}/?page=${page}&size=${rowsPerPage}`);
}


export function downloadInvoice(id) {
  return axiosBlobInstance.get(`${GETINVOICES}/export/${id}`);
}

