import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import TextFieldItem from 'components/common/inputs/TextField';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../_redux/authActions';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

function ForgotPassword() {
  const dispatch = useDispatch();

  const classes = useAuthStyles();

  const { actionsLoading } = useSelector((state) => state.oauth);

  return (
    <div style={{ padding: '200px 7.2vw', }}>
      <Box className={classes.login}>
        <Typography
          className={classes.loginTitle}
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontSize: '1.5rem',
            fontWeight: '500',
            lineHeight: '29px',
            textAlign: 'center',
            letterSpacing: '-0.015em',
            color: '#041C26',
          }}
        >
          Enter your email to reset password
          <SuccessApiMessages />
        </Typography>
        <ApiMessages/>
        <br />
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(forgotPassword(values));
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <TextFieldItem
                name="email"
                label="Email Address"
                value={props.values.email}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                validationField={props.touched.email}
                error={props.errors.email}
              />
              <center>
                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.submit}
                >
                  Reset
                  {actionsLoading && (
                    <CircularProgress
                      size={24}
                      color="loader"
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </center>
            </Form>
          )}
        </Formik>
        <center>
          <Typography className={classes.textPolicy}>
            By continuing, you agree to Cloud Wallet{' '}
            <Link to="/terms-and-conditions" style={{ textDecoration: 'none', fontWeight: 'normal' }}>Terms of service.</Link> & <br></br>
            <Link to="/privacy-policy" style={{ textDecoration: 'none', fontWeight: 'normal' }}>Privacy Policy.</Link>
          </Typography>
          <br />
          <Typography variant="p" className={classes.altText}>
            Already a member? <Link to="/login">Log In</Link>
          </Typography>
        </center>
      </Box>
    </div>
  );
}

export default ForgotPassword;
