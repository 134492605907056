import { axiosInstance } from 'helpers/base.service';

export const WORKSPACEURL = 'workspace/save';
export const WORKSPACESTATUS = 'workspace/status';
export const WORKSPACECONTACTINFO = 'workspace/create';
export const GETALLEDITIONS = 'editions';
export const PURCHASEPLAN = 'editions/actions/purchase';
export const GETSUBSCRIPTIONS = 'workspace/subscriptions';
export const GETSUBSCRIPTION = 'workspace/subscriptions';
export const USERS_SUBS_CUSTOMERS = 'workspace/subscriptions';
export const TRANSFER = 'workspace/transfer';


export function createWorkspace(payload) {
  return axiosInstance().post(WORKSPACEURL, payload);
}

export function createContactInfo(payload) {
  return axiosInstance().post(WORKSPACECONTACTINFO, payload);
}

export function getAllEditions() {
  return axiosInstance().get(GETALLEDITIONS);
}

export function purchasePlan(payload) {
  return axiosInstance().post(PURCHASEPLAN, payload);
}

export function getAllSubscriptions() {
  return axiosInstance().get(GETSUBSCRIPTIONS);
}

export function getSubscription(id) {
  return axiosInstance().get(`${GETSUBSCRIPTION}/${id}`);
}

export function workspacesStatus() {
  return axiosInstance().get(`${WORKSPACESTATUS}`);
}


export function customercancelSub(id, values) {
  return axiosInstance().patch(`${USERS_SUBS_CUSTOMERS}/${id}/cancel`, values);
}

export function changeTab(payload) {
  return new Promise((resolve, reject) => {
    if(payload){
      resolve(payload)
    }else{
      reject(new Error('no data'))
    }
  })
}


export function setGlobalValue(payload) {
  return new Promise((resolve, reject) => {
    if(payload){
      resolve(payload)
    }else{
      reject(new Error('no data'))
    }
  })
}

export function transferWorkspace(payload) {
  return axiosInstance().post(TRANSFER, payload);
}