import { axiosBlobInstance, axiosInstance } from 'helpers/base.service';

export const USERS_CUSTOMERS = 'users/customers';
export const SYSTEM_USERS = 'users/list';
export const ADD_SYSTEM_USERS = 'users/add';
export const EDIT_SYSTEM_USERS = 'users/edit';
export const DELETE_SYSTEM_USER = 'users/delete';
export const USERS_SUBS_CUSTOMERS = 'customers/subscriptions';
export const CUSTOMERS_TRANSACTIONS = 'customers/transactions';
export const CHANGE_SUBS = 'customers/editions/change';
export const SEND_REMINDER = 'customers/actions/send-reminder';
export const GETINVOICES = 'customers/invoices';
export const GETMESSAGES = 'customers/messages';
// export const CANCEL_SUB = 'customrs/messages';

//create customer users
export function createSystemUser(payload) {
    return axiosInstance().post(ADD_SYSTEM_USERS, payload);
}

//fetch customer 
export function fetchCustomers({page, rowsPerPage}) {
    return axiosInstance().get(`${USERS_CUSTOMERS}/?page=${page}&size=${rowsPerPage}`);
}

//fetch system users
export function fetchSystemUsers({page, rowsPerPage}) {
    return axiosInstance().get(`${SYSTEM_USERS}/?page=${page}&size=${rowsPerPage}`);
}

//fetch system users
export function updateSystemUser(payload) {
    return axiosInstance().put(EDIT_SYSTEM_USERS, payload);
}

//fetch system users
export function deleteSystemUser(payload) {
    return axiosInstance().delete(`${DELETE_SYSTEM_USER}/${payload.id}`);
}

//fetch system users
export function adminGetCustomerSubscription(customer_id) {
    return axiosInstance().get(`${USERS_SUBS_CUSTOMERS}/${customer_id}`);
}


export function adminGetSubscriptionDetails(customer_id, subs_id) {
    return axiosInstance().get(`${USERS_SUBS_CUSTOMERS}/${customer_id}/subscription/${subs_id}`);
}

//fetch system users
export function updateEdition(customer_id, payload) {
    return axiosInstance().post(`${CHANGE_SUBS}/${customer_id}`, payload);
}

//fetch system users
export function sendReminder(payload) {
    return axiosInstance().post(`${SEND_REMINDER}`, payload);
}

//fetch customer 
export function adminfetchCustomersTransactions({ page, rowsPerPage, memoizedState}) {
    return axiosInstance().get(`${CUSTOMERS_TRANSACTIONS}/${memoizedState.id}?page=${page}&size=${rowsPerPage}`);
}

export function downloadInvoice(cutomer_id, invoice_id) {
    return axiosBlobInstance.get(`${GETINVOICES}/${cutomer_id}/invoice/${invoice_id}/export`);
}


//fetch customer 
export function fetchMessages({ page, rowsPerPage}) {
    return axiosInstance().get(`${GETMESSAGES}?page=${page}&size=${rowsPerPage}`);
}


//fetch system users
export function sendToAll(payload) {
    return axiosInstance().post(`${SEND_REMINDER}/all`, payload);
}

export function CancelSubs(id, customer_id, values) {
    return axiosInstance().patch(`${USERS_SUBS_CUSTOMERS}/${id}/subscription/${customer_id}/cancel`, values);
}

export function undoCancelSub(id, customer_id, values) {
    return axiosInstance().patch(`${USERS_SUBS_CUSTOMERS}/${id}/subscription/${customer_id}/activate`, values);
}