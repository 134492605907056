import React from 'react';

import CardContent from '@mui/material/CardContent';

import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';
import SearchIcon from '@mui/icons-material/Search';
// Search
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';

// Tabs
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '40px 0',
    position: 'relative',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },
  cardHeader: {
    textalign: 'center',
    align: 'center',
    backgroundColor: 'white',
  },
  input: {
    display: 'block',
    zIndex: 99999999999,
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  title: {
    color: blue[800],
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    align: 'center',
  },
  button: {
    color: blue[900],
    margin: 10,
  },
  secondaryButton: {
    color: 'gray',
    margin: 10,
  },
  typography: {
    margin: theme.spacing.unit * 2,
    backgroundColor: 'default',
  },

  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  searchInput: {
    marginLeft: 8,
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  searchDivider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});
function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`;
  } if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
  return number;
}

class ImageUploadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainState: 'initial', // initial, search, gallery, uploaded
      imageUploaded: 0,
      selectedFile: null,
    };
  }

  handleUploadClick = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (_e) => {
      const san = returnFileSize(event?.target?.files[0].size).split(' ');
      if (san[0] > 2 && san[1] === 'MB') {
        alert('size should be less than 2MB');
      } else {
        this.setState({
          selectedFile: reader.result,
        });
        this.props.sendImage(reader.result);
      }
    };

    this.setState({
      mainState: 'uploaded',
      imageUploaded: 1,
    });
  };

  handleSearchClick = (_event) => {
    this.setState({
      mainState: 'search',
    });
  };

  handleGalleryClick = (_event) => {
    this.setState({
      mainState: 'gallery',
    });
  };

  handleSearchURL = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (_e) {
      this.setState({
        selectedFile: [reader.result],
      });
    }.bind(this);

    this.setState({
      selectedFile: event.target.files[0],
      imageUploaded: 1,
    });
  };

  handleImageSearch(url) {
    const filename = url.substring(url.lastIndexOf('/') + 1);
    this.setState({
      mainState: 'uploaded',
      imageUploaded: true,
      selectedFile: url,
      fileReader: undefined,
      filename,
    });
  }

  handleSeachClose = (_event) => {
    this.setState({
      mainState: 'initial',
    });
  };

  handleAvatarClick(value) {
    const filename = value.url.substring(value.url.lastIndexOf('/') + 1);
    this.setState({
      mainState: 'uploaded',
      imageUploaded: true,
      selectedFile: value.url,
      fileReader: undefined,
      filename,
    });
  }

  imageResetHandler = (_event) => {
    this.setState({
      mainState: 'initial',
      selectedFile: null,
      imageUploaded: 0,
    });
  };

  renderInitialState(status) {
    const { classes } = this.props;

    return (
      <CardContent>
        <Grid container sx={{ position: 'relative' }} justify="center" alignItems="center">
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={this.handleUploadClick}
          />
          <label htmlFor="contained-button-file">
            <Fab component="span" className={classes.button}>
              <center>
                {/* {this.handleWhoToRender()} */}
                {this.state.mainState === 'uploaded' ? this.renderUploadedState()
                  : <Avatar
                      alt="Remy Sharp"
                      src={this.props.initialImage}
                      style={{
                        width: '120px',
                        height: '120px',
                        border: 'thick solid #EFF0F1',
                        margin: '2rem 0',
                      }}
                  />}
              </center>
            </Fab>
          </label>
        </Grid>
      </CardContent>
    );
  }

  renderSearchState() {
    const { classes } = this.props;

    return (
      <Paper className={classes.searchRoot} elevation={1}>
        <InputBase className={classes.searchInput} placeholder="Image URL" />
        <IconButton
          className={classes.button}
          aria-label="Search"
          onClick={this.handleImageSearch}
        >
          <SearchIcon />
        </IconButton>
        <Divider className={classes.searchDivider} />
        <IconButton
          color="primary"
          className={classes.secondaryButton}
          aria-label="Close"
          onClick={this.handleSeachClose}
        >
          <CloseIcon />
        </IconButton>
      </Paper>
    );
  }

  renderGalleryState() {
    const { classes } = this.props;
    const listItems = this.props.imageGallery.map((url, i) => (
      <div
        key={i}
        role="button"
        onClick={() => { }}
        onKeyPress={(_value) => this.handleAvatarClick({ url })}
        style={{
          padding: '5px 5px 5px 5px',
          cursor: 'pointer',
        }}
      >
        <Avatar src={url} />
      </div>
    ));

    return (
      <Grid>
        {listItems}
        <IconButton
          color="primary"
          className={classes.secondaryButton}
          aria-label="Close"
          onClick={this.handleSeachClose}
        >
          <ReplayIcon />
        </IconButton>
      </Grid>
    );
  }

  renderUploadedState() {
    const { classes } = this.props;
    return (
      <>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={this.handleUploadClick}
        />
        <Avatar
          alt="Remy Sharp"
          src={this.state.selectedFile}
          style={{
            width: '120px',
            height: '120px',
            border: 'thick solid #EFF0F1',
            margin: '2rem 0',
          }}
        />
      </>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {(this.renderInitialState(this.state.mainState))}
        {this.state.mainState === 'uploaded'
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          && (
            <div className="deleteImage">
              <DeleteIcon sx={{ width: '12px' }} onClick={this.imageResetHandler} />
            </div>)
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);
