const FormModel = {
  formId: 'cloudwallet',
  formField: {
    email: {
      name: 'email',
      label: 'Enter your email',
      requiredErrorMsg: 'Email is required',
    },
    password: {
      name: 'password',
      label: 'Enter your password',
      requiredErrorMsg: 'Password is required',
    },
    domainName: {
      name: 'domainName',
      label: 'Domain Name',
      requiredErrorMsg: 'Domain Name is required',
    },
    token: {
      name: 'token',
      label: 'Enter token',
      requiredErrorMsg: 'Token is required',
    },
    companyName: {
      name: 'companyName',
      label: 'Company Name',
      requiredErrorMsg: 'Company Name is required',
    },
    country: {
      name: 'country',
      label: 'Country*',
      requiredErrorMsg: 'Country is required',
    },
    city: {
      name: 'city',
      label: 'city',
      requiredErrorMsg: 'City is required',
    },
    streetAddressOne: {
      name: 'streetAddressOne',
      label: 'street Address 1',
      requiredErrorMsg: 'Street Address One is required',
    },
    streetAddressTwo: {
      name: 'streetAddressTwo',
      label: 'street Address 2',
      requiredErrorMsg: 'Street Address 2 is required',
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Phone Number',
      requiredErrorMsg: 'Phone Number is required',
    },
    title: {
      name: 'title',
      label: 'Title',
      requiredErrorMsg: 'Title is required',
    },
    postalCode: {
      name: 'postalCode',
      label: 'Postal Code',
      requiredErrorMsg: 'Postal Code is required',
    },
    organisationName: {
      name: 'organisationName',
      label: 'Organisation Name',
      requiredErrorMsg: 'Organisation Name is required',
    },
    firstName: {
      name: 'firstName',
      label: 'First Name',
      requiredErrorMsg: 'First Name is required',
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      requiredErrorMsg: 'Last Name is required',
    },
    contactfirstName: {
      name: 'contactfirstName',
      label: 'First Name',
      requiredErrorMsg: 'First Name is required',
    },
    contactlastName: {
      name: 'contactlastName',
      label: 'Last Name',
      requiredErrorMsg: 'Last Name is required',
    },
    language: {
      name: 'language',
      label: 'language',
      requiredErrorMsg: 'Language is required',
    },
    alternativeEmail: {
      name: 'alternativeEmail',
      label: 'Alternative Email',
      requiredErrorMsg: 'Alternative Email is required',
    },
    contactEmail: {
      name: 'contactEmail',
      label: 'Domain Email',
      requiredErrorMsg: 'Email is required',
    },
    verifyPassword: {
      name: 'verifyPassword',
      label: 'Verify Password',
      requiredErrorMsg: 'Verify Password is required',
    },
  },
};

export default FormModel;
