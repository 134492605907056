import SupportTicketsDashboard from './support_tickets';

function AdminSupportTicketsDashboardpage() {
  return (
    <div
      className="dashboard-container"
    >
      <SupportTicketsDashboard />
    </div>
  );
}

export default AdminSupportTicketsDashboardpage;
