import {
  Box, Grid, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Linkedin from '../../assets/images/social_icons/linkedin.svg';
import Twitter from '../../assets/images/social_icons/twitter.svg';
import Logo from '../../assets/images/logo.svg';
import UnionLines from '../../assets/images/clouds_bg/union_lines.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  footer: {
    padding: '50px 7.2vw',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundImage: `url(${UnionLines})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: 0,
  },
  brands: {
    padding: '1rem 0 3rem',
    '@media (max-width: 568px)': {
      padding: '0',
    },
  },
  brandsItem: {
    display: 'flex',
  },
  brand: {
    fontStyle: 'normal',
    color: '#1565C0',
    fontSize: '12px',
    fontWeight: 'normal',
    paddingRight: '.9em',
  },
  bottomFooter: {
    paddingTop: '1.5rem',
    textAlign: 'right',
    '@media (max-width: 568px)': {
      paddingTop: '0.5rem',
    },
  },
  icons: {
    display: 'flex',
    justifyContent: 'right',
  },
  paragraph: {
    marginTop: '1rem',
    color: '#1565C0',
    fontWeight: 'normal',
    fontWize: '13px',
    lineHeight: '1.2em',
    letterSpacing: '-0.015em',
  },
});

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container spacing={2} className={classes.accountCards}>
        <Grid item xs={12} md={9}>
          <Box className={classes.brands}>
            <img src={Logo} alt="logo" />
            <Box className={classes.brandsItem}>
              <Link to="/about-us" style={{ textDecoration: 'none' }}>
                <Typography variant="inherit" className={classes.brand}>
                  Company.
                </Typography>
              </Link>
              <Link to="/support" style={{ textDecoration: 'none' }}>
                <Typography variant="inherit" className={classes.brand}>
                  Help desk.
                </Typography>
              </Link>.
              <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }}>
                <Typography variant="inherit" className={classes.brand}>
                  Terms and Conditions.
                </Typography>
              </Link>
              <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
                <Typography variant="inherit" className={classes.brand}>
                  Privacy Policy.
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box className={classes.bottomFooter}>
            <Box className={classes.icons}>
              <a href='https://twitter.com/Pay_4Cloud' target='_blank' rel="noreferrer"><img style={{ padding: '0 15px' }} src={Twitter} alt="" /></a>
              {/* <img style={{ padding: '0 15px' }} src={Google} alt="" /> */}
              {/* <img style={{ padding: '0 15px' }} src={Fb} alt="" /> */}
              {/* <img style={{ padding: '0 15px' }} src={Instagram} alt="" /> */}
              <a href='https://www.linkedin.com/showcase/pay4cloud/' target='_blank' rel="noreferrer"><img style={{ padding: '0 15px' }} src={Linkedin} alt="" /></a>
            </Box>
            <br />
            <Typography variant="inherit" className={classes.paragraph}>
              © Incentro, Inc. 2021. We love our users!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
