import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { downInvoice, getTransactions } from 'modules/payment/wallet/_redux/walletsActions';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'transactionNumber',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Number',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount(usd)',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'transactionType',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Type',
  },
  {
    id: 'paymentMode',
    numeric: false,
    disablePadding: false,
    label: 'Mode of payment',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

function EnhancedTableHead(props) {
  const {
    order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              sx={{ color: '#041c26', fontWeight: 'bold', fontSize: '.8em' }}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : ('')}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TransactionsTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected,] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense,] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const classes = useAuthStyles();

  const {
    actionsLoading, walletTransactions, downloadStatus, totalElements
  } = useSelector(
    (state) => ({
      actionsLoading: state.wallet.actionsLoading,
      subscriptions: state.workspace.subscriptions,
      walletTransactions: state.wallet.walletTransactions,
      downloadStatus: state.wallet.downloadStatus,
      lastError: state.wallet.lastError,
      totalElements: state.wallet.totalElements,
    }),
    shallowEqual,
  );

  React.useEffect(() => {
    dispatch(getTransactions({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage])

  const downLoadInvoice = (id) => {
    dispatch(downInvoice(id))
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); 
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{
        width: '100%', mb: 2, border: 'none', boxShadow: 'none',
      }}
      >
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={walletTransactions && totalElements}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {!actionsLoading
                && (
                  <>
                    {stableSort(walletTransactions, getComparator(order, orderBy))
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);

                        return (
                          <TableRow
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.transactionNumber}</TableCell>
                            <TableCell align="left">{row.date}</TableCell>
                            <TableCell align="left">{row.amount}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.transactionType}</TableCell>
                            <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.paymentMode}</TableCell>
                            <TableCell align="left" style={{ fontWeight: 'bold' }}>

                              <Button
                                aria-haspopup="true"
                                sx={{ backgroundColor: '#00acff', fontSize: '.8em', fontWeight: 500 }}
                                variant="contained"

                                disabled={row.invoiceId === null}
                                onClick={() => downLoadInvoice(row.invoiceId)}
                              >
                                {(downloadStatus && row.invoiceId !== null) ? <CircularProgress
                                  size={24}
                                  color="loader"
                                  className={classes.buttonProgress}
                                /> : 'View'
                                }


                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </>
                )
              }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}
