import { axiosInstance } from 'helpers/base.service';

export const PLANCHANGE = 'editions/actions/change';
export const CHANGEOPTIONS = 'customers/editions/change-options';
export const CHANGE = 'editions/actions/change-options';

export function planDowngrade(values) {
  return axiosInstance().post(`${PLANCHANGE}`, values);
}

export function planUpgrade(values) {
  return axiosInstance().post(`${PLANCHANGE}`, values);
}

export function downgradeChoices(customer_id) {
  return axiosInstance().get(`${CHANGEOPTIONS}/${customer_id}?changeType=DOWNGRADE`);
}

export function upgradeChoices(customer_id) {
  return axiosInstance().get(`${CHANGEOPTIONS}/${customer_id}?changeType=UPGRADE`);
}

export function getdowngradeChoices() {
  return axiosInstance().get(`${CHANGE}?changeType=DOWNGRADE`);
}

export function getupgradeChoices() {
  return axiosInstance().get(`${CHANGE}?changeType=UPGRADE`);
}


export function updateCurrentPlan(values){
  return new Promise((resolve, reject) =>{
    if(values){
      resolve(values)
    }else reject(new Error('no values'))
  })
}