import { handleResponseError } from 'helpers/base.service';
import { showApiMessages, successShowApiMessages } from 'modules/authentication/_redux/authActions';
import { modalActions } from 'modules/modals';
import * as requestFromServer from './adminCrud';
import { adminSlice, callTypes } from './adminSlice';

const { actions } = adminSlice;

export const fetchCustomers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchCustomers(queryParams)
    .then((response) => {
      const { data: {content, totalElements} } = response.data;
      dispatch(actions.customersFetched({content, totalElements}));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchSystemUsers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchSystemUsers(queryParams)
    .then((response) => {
      const { data: {content, totalElements} } = response.data;
      dispatch(actions.systemUsersFetched({content, totalElements}));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSystemUser = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSystemUser(queryParams)
    .then((response) => {
      const { data: {content} } = response.data;
      dispatch(actions.createdSystemUser(content));
      dispatch(successShowApiMessages(response))
      dispatch(fetchSystemUsers());
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSystemUser = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSystemUser(queryParams)
    .then((response) => {
      const { data: {content} } = response.data;
      dispatch(actions.updatedSystemUser(content));
      dispatch(successShowApiMessages(response))
      dispatch(fetchSystemUsers());
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSystemUser = (queryParams, modalID) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSystemUser(queryParams)
    .then((response) => {
      dispatch(actions.deleteSystemUser());
      dispatch(successShowApiMessages(response))
      dispatch(modalActions.hideModal(modalID))
      dispatch(fetchSystemUsers());
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const adminGetCustomerSubscription = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .adminGetCustomerSubscription(id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.adminGetCustomerSubscription(data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const adminGetSubscriptionDetails = (customer_id, subs_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .adminGetSubscriptionDetails(customer_id, subs_id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.adminGetSubscriptionDetails(data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateEdition = (customer_id, values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateEdition(customer_id, values)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.updateEdition(data));
      dispatch(successShowApiMessages(response))
      dispatch(adminGetCustomerSubscription(customer_id));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const sendReminder = (values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendReminder(values)
    .then((response) => {
      const { data } = response.data;
      dispatch(successShowApiMessages(response))
      setTimeout(() => {
        dispatch(modalActions.hideModal())
      }, 2000);
      dispatch(actions.sendReminder(data));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMessages = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchMessages(queryParams)
    .then((response) => {
      const { data: {content} } = response.data;
      dispatch(actions.fetchMessages(content));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const sendToAll = (values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendToAll(values)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.sendToAll(data));
      dispatch(successShowApiMessages(response))
      setTimeout(() => {
        dispatch(modalActions.hideModal())
      }, 2000);
      dispatch(fetchMessages())
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const adminfetchCustomersTransactions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .adminfetchCustomersTransactions(queryParams)
    .then((response) => {
      const { data: {content} } = response.data;
      dispatch(actions.adminGetCustomerTransactions(content));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const downloadInvoice = (customer_id, invoice_id) => (dispatch) => {
  dispatch(actions.startDownload({ callType: callTypes.action }));
  return requestFromServer
    .downloadInvoice(customer_id, invoice_id)
    .then((response) => {
      const { data } = response;
      function dataURItoBlob(data) {
        const byteString = window.atob(data);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf' }, 'file.pdf');
        return blob;
      }

      const blob = dataURItoBlob(data);
      const url = URL.createObjectURL(blob);

      // to open the PDF in a new window
      setTimeout(() => {
        window.open(url, '_blank');
      }, 800);
      dispatch(actions.invoiceDownloaded(data));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const cancelSub = (id, customer_id, values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .CancelSubs(id, customer_id, values)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.CancelSubs(data));
      dispatch(successShowApiMessages(response))
      dispatch(adminGetCustomerSubscription(id))
      setTimeout(() => {
        dispatch(modalActions.hideModal())
      }, 2000);    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const undoCancelSub = (id, customer_id, values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .undoCancelSub(id, customer_id, values)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.undoCancelSub(data));
      dispatch(successShowApiMessages(response))
      setTimeout(() => {
        dispatch(modalActions.hideModal())
      }, 2000);
      dispatch(adminGetCustomerSubscription(id))
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};