/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: '.9em',
    color: 'black',
    textTransform: 'capitalize',
  },
  option: {
    fontSize: '.9em',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #f9d5a5',
    fontSize: 16,
    padding: '13px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: '#1d179e',
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

function SelectField(props) {
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;

  const classes = useStyles();
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel className={classes.label} htmlFor="age-customized-select">{label}</InputLabel>
      <Select
        input={
          <BootstrapInput
            name="age"
            id="age-customized-select"
            className="select_input-h"
          />
        }
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        {...field}
        value={selectedValue || ''}
      >
        {data.map((item, index) => (
          <MenuItem className={classes.option} key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

// SelectField.defaultProps = {
//   data: [],
// };

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
