import { createTheme } from '@mui/material/styles';
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

export const breakpoints = createBreakpoints({
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
});

const CloudWalletTheme = createTheme({
  palette: {
    primary: {
      main: '#041C26',
    },
    secondary: {
      main: '#FE5000',
    },
    neutral: {
      main: '#00c0e1',
    },
    loader: {
      main: '#fff',
    },
    darkloader: {
      main: '#041C26',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadow: 'none',
          fontFamily: 'Montserrat',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '0',
          paddingRight: '0',
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    },
    // MuiTypography:{
    //   styleOverrides: {
    //     root: {
    //     fontSize: '20px',
    //     [breakpoints.down('md')]: {
    //       fontSize: '.9em',
    //     },

    //     },
    //   },
    // },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
          padding: '1rem',
          borderRadius: '10px',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [breakpoints.down('md')]: {
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#C4C4C4',
          [breakpoints.down('md')]: {
            whiteSpace: 'unset'
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '1em',
          padding: '0.4em 1.4em',
          [breakpoints.down('md')]: {
            padding: '7px 15px',
            fontSize: '.65em',
          },
          [breakpoints.up('md')]: {
          },
          [breakpoints.up('lg')]: {
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                paddingRight: 0,
              }
            },
          }
        },
        outlined: {
          background: '#233168',
          color: '#F2F2F2',
          padding: '0.4em 1.4em',
          fontWeight: '600',
          fontSize: '1em',
          fontFamily: 'Montserrat',
          boxShadow: 'none',
          border: 'none',
          textTransform: 'capitalize',
          borderRadius: '5px',
          [breakpoints.down('md')]: {
            padding: '8px 15px',
            fontSize: '.7em',
          },
          [breakpoints.up('md')]: {
          },
          [breakpoints.up('lg')]: {
          },
          '&:hover': {
            border: 'none',
            background: '#233168',
          },

        },
        label: {
          color: '#F2F2F2',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#041C26',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '16px 0',
        },
      },
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       padding: '0.2em .5em !important',
    //     },
    //   },
    // },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontSize: '.9em',
          [breakpoints.down('md')]: {
            flexDirection: "column",
            justifyContent: 'space-evenly'
          },

        }
      }
    },
    MuiCardContent:{
      styleOverrides: {
        root: {
          padding: '0',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '.8em',
        },
        head: {
          fontSize: '1em',
        }
      }
    },
    MuiListItem: {
      root: {
        padding: '0',
      },
    },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: 'Work Sans',
        fontWeight: '500',
      },
      inherit: {
        fontWeight: 700,
        fontFamily: 'Montserrat',
        fontSize: '.85em',
      },
      h2: {
        color: '#041C26',
        fontWeight: '700',
        fontSize: '1.7em',
        fontFamily: 'Montserrat',
        [breakpoints.down('md')]: {
        },

      },
      h1: {
        color: '#041C26',
        fontWeight: '700',
        fontSize: '1.25em',
        fontFamily: 'Montserrat',
        [breakpoints.down('md')]: {
          fontSize: '1.5em',
        },
        [breakpoints.up('md')]: {
          fontSize: '2.5em',
        },
        [breakpoints.up('lg')]: {
        },
      },
      h3: {
        color: '#041C26',
        fontFamily: 'Montserrat',
        fontSize: '1.15em',
        fontWeight: '700',
      },
      h4: {
        color: '#041C26',
        fontFamily: 'Montserrat',
        fontWeight: '700',
      },
      h5: {
        color: '#233168',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: '1.6em',
        [breakpoints.down('md')]: {
          fontSize: '1.15em',
        },
      },
      body2: {
        fontFamily: 'Work Sans',
        color: '#000',
        fontSize: '1em',
        fontWeight: '400',
      },
      p: {
        fontFamily: 'Work Sans',
        fontSize: '.9em',
        fontWeight: '500',
        marginBottom: '1rem',
        [breakpoints.down('md')]: {
          fontSize: '.8em',
        },
      },
      body3: {
        fontFamily: 'Work Sans',
        fontWeight: '600',
        fontSize: '1.4em',
        color: '#233168',
        [breakpoints.down('md')]: {
          fontSize: '1.15em',
        },
      },
    },
  },
},
});

export default CloudWalletTheme;
