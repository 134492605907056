import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import TextFieldItem from 'components/common/inputs/TextField';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { resetPassword } from '../_redux/authActions';

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .required('Password is required'),
  confirm_password: yup
    .string('Confirm password')
    .required('Confirm password is required')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

function ResetPassword() {
  const dispatch = useDispatch();

  const location = useLocation();
  const { actionsLoading } = useSelector((state) => state.oauth);

  const classes = useAuthStyles();
  return (
    <div style={{ padding: '200px 7.2vw', }}>
    <Box className={classes.login}>    
        <Typography
          className={classes.titleSubTitle}
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontSize: '1.5rem',
            fontWeight: '500',
            lineHeight: '29px',
            textAlign: 'center',
            letterSpacing: '-0.015em',
            color: '#041C26',
          }}
        >
          Reset your password
        </Typography>
        <Typography
          className={classes.titleSubTitle}
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontSize: '1rem',
            fontWeight: '300',
            lineHeight: '29px',
            textAlign: 'center',
            letterSpacing: '-0.015em',
            color: '#041C26',
          }}
        >
          Type your prefered password below
        </Typography>
        <ApiMessages/>
        <SuccessApiMessages/>
        <br />
        <Formik
          initialValues={{
            password: '',
            token: location.search.substring(5),
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(resetPassword(values));
          }}
        >
          {({
            handleSubmit, handleBlur, handleChange, errors, values, touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextFieldItem
                type="password"
                name="password"
                label="New Password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                validationField={touched.password}
                error={errors.password}
              />
               <TextFieldItem
                type="password"
                name="confirm_password"
                label="Confirm Password"
                value={values.confirm_password}
                onBlur={handleBlur}
                onChange={handleChange}
                validationField={touched.confirm_password}
                error={errors.confirm_password}
              />
              <center>
                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.submit}
                >
                  Set New Password
                  {actionsLoading && (
                    <CircularProgress
                      size={24}
                      color="loader"
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </center>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default ResetPassword;
