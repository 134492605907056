import { createSlice } from '@reduxjs/toolkit';

const initialAdminState = {
  listLoading: false,
  actionsLoading: false,
  customers: [],
  messages: [],
  system_users: [],
  customer_subscriptions: [],
  customer_subscription: {},
  customer_transactions: [],
  createdUser: {},
  updatedUser: {},
  deletedSystemUser: {},
  loading: false,
  updateEdition: {},
  sendReminder: {},
  downloadStatus: false,
  pdf: null,
  undocancelsub: null,
  totalElements: null,
  totalElementsUsers: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState: initialAdminState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = null;
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.downloadStatus = false;
      } else {
        state.actionsLoading = false;
        state.downloadStatus = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    customersFetched: (state, action) => {
      state.actionsLoading = false;
      state.customers = action.payload.content;
      state.totalElements = action.payload.totalElements;
      state.error = null;
    },
    fetchMessages: (state, action) => {
      state.actionsLoading = false;
      state.messages = action.payload;
      state.error = null;
    },
    systemUsersFetched: (state, action) => {
      state.actionsLoading = false;
      state.totalElementsUsers = action.payload.totalElements;
      state.system_users = action.payload.content;
      state.error = null;
    },
    createdSystemUser: (state, action) => {
      state.actionsLoading = false;
      state.createdUser = action.payload;
      state.error = null;
    },
    updatedSystemUser: (state, action) => {
      state.actionsLoading = false;
      state.updatedUser = action.payload;
      state.error = null;
    },
    deleteSystemUser: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

    },
    adminGetCustomerSubscription: (state, action) => {
      state.actionsLoading = false;
      state.customer_subscriptions = action.payload;
      state.error = null;
    },
    adminGetSubscriptionDetails: (state, action) => {
      state.actionsLoading = false;
      state.customer_subscription = action.payload;
      state.error = null;
    },
    updateEdition: (state, action) => {
      state.actionsLoading = false;
      state.updateEdition = action.payload;
      state.error = null;
    },
    sendReminder: (state, action) => {
      state.actionsLoading = false;
      state.sendReminder = action.payload;
      state.error = null;
    },
    sendToAll: (state, action) => {
      state.actionsLoading = false;
      state.sendReminder = action.payload;
      state.error = null;
    },
    adminGetCustomerTransactions: (state, action) => {
      state.actionsLoading = false;
      state.customer_transactions = action.payload;
      state.error = null;
    },
    startDownload: (state, action) => {
      state.error = null;
      state.lastError = null;
      state.downloadStatus = true;
    },
    InvoiceDownloaded: (state, action) => {
      state.downloadStatus = false;
      state.pdf = action.payload;
      state.error = null;
    },
    CancelSub: (state, action) => {
      state.actionsLoading = false;
      state.cancelsub = action.payload;
      state.error = null;
    },
    undoCancelSub: (state, action) => {
      state.actionsLoading = false;
      state.undocancelsub = action.payload;
      state.error = null;
    },
  },
});

const { reducer } = adminSlice;
export default reducer;
