import { modalConstants } from "./_constants";

export const modalActions = {
    add_user,
    edit_user,
    hideModal,
    view_subscription,
    delete_user,
    workspace_status,
    upgrade_subscription,
    downgrade_subscription,
    update_subscription,
    send_reminder,
    confirm_send_reminder,
    send_message,
    view_ticket,
    clear,
    send_to_all,
    cancel_sub,
    confirm_cancel_sub,
    undo_cancel_sub,
    undo_confirm_cancel_sub,
    confirm_domain_transfer
};

function add_user(content) {
    return { type: modalConstants.ADD_USER, content };
}

function view_subscription(content) {
    return { type: modalConstants.VIEW_SUBSCRIPTION, content };
}

function upgrade_subscription(content) {
    return { type: modalConstants.UPGRADE_SUBSCRIPTION, content };
}

function downgrade_subscription(content) {
    return { type: modalConstants.DOWNGRADE_SUBSCRIPTION, content };
}
function update_subscription(content) {
    return { type: modalConstants.UPDATE_SUBSCRIPTION, content };
}

function edit_user(content) {
    return { type: modalConstants.EDIT_USER, content };
}

function workspace_status(content) {
    return { type: modalConstants.WORKSPACE_STATUS, content };
}

function delete_user(content) {
    return { type: modalConstants.DELETE_USER, content };
}

function hideModal(content) {
    return { type: modalConstants.HIDE_MODAL, content };
}

function send_reminder(content) {
    return { type: modalConstants.SEND_REMINDER, content };
}

function confirm_send_reminder(content) {
    return { type: modalConstants.CONFIRM_SEND_REMINDER, content };
}


function cancel_sub(content) {
    return { type: modalConstants.CANCEL_SUB, content };
}

function confirm_cancel_sub(content) {
    return { type: modalConstants.CONFIRM_CANCEL_SUB, content };
}

function undo_cancel_sub(content) {
    return { type: modalConstants.UNDO_CANCEL_SUB, content };
}

function undo_confirm_cancel_sub(content) {
    return { type: modalConstants.UNDO_CONFIRM_CANCEL_SUB, content };
}

function send_message(content) {
    return { type: modalConstants.SEND_MESSAGE, content };
}

function view_ticket(content) {
    return { type: modalConstants.VIEW_TICKET, content };
}

function send_to_all(content) {
    return { type: modalConstants.SEND_TO_ALL, content };
}


function clear(content) {
    return { type: modalConstants.CLEAR, content };
}

function confirm_domain_transfer(content) {
    return { type: modalConstants.CONFIRM_DOMAIN_TRANSFER, content };
}
