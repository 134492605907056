import {
  Box, Grid,
  Typography,
} from '@mui/material';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { InputField } from 'components/FormFields';

export default function AddContactInformation(props) {
  const {
    formField: {
      contactfirstName, contactlastName, title,
      alternativeEmail, contactEmail,
    },
  } = props;

  return (
    <Box>
      <Typography
        variant="h4"
        style={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '1.4em',
          lineHeight: '40px',
          letterSpacing: '-0.015em',
          color: '#041C26',
        }}
      >
        Contact Information
      </Typography>
      <Typography
        variant="p"
        style={{
          fontSize: '.8em',
          lineHeight: '.9em',
          letterSpacing: '-0.015em',
          color: '#041C26',
        }}
      >
        The name and email address are used to create the
        initial administrator account for Google Workspace
      </Typography>
      <br />
      <br />
      <ApiMessages/>
      <Grid spacing={3}>
        <InputField
          name={title.name}
          label={title.label}
          fullWidth
        />
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs style={{ display: 'flex' }}>
            <InputField
              name={contactfirstName.name}
              label={contactfirstName.label}
              icon="user"
              hasIcon
            />
          </Grid>
          <Grid item xs style={{ display: 'flex' }}>
            <InputField
              name={contactlastName.name}
              label={contactlastName.label}
              hasIcon
              icon="user"
            />
          </Grid>
        </Grid>
        <InputField
          name={contactEmail.name}
          label={contactEmail.label}
          hasIcon
          icon="envelope"
        />
        <InputField
          name={alternativeEmail.name}
          label={alternativeEmail.label}
          hasIcon
          icon="envelope"
        />
      </Grid>
    </Box>
  );
}
