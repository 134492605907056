import { Button, CircularProgress, DialogTitle, Grid, Typography } from "@mui/material";
import TextFieldItem from 'components/common/inputs/TextField';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { Form, Formik } from "formik";
import * as yup from 'yup';
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from 'modules/modals';
import close from 'assets/images/pret.svg';
import { updateSystemUser } from "../_redux/admin/adminActions";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { ApiMessages } from "components/common/ApiMessages/apimessages";
import { SuccessApiMessages } from "components/common/SuccessApiMessages/successapimessages";

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phoneNumber: yup
    .string('Enter Phone number')
    .required('Phone number is required'),
  firstName: yup
    .string('Enter First name')
    .required('First name is required'),
  lastName: yup
    .string('Enter last name')
    .required('last name is required')
});


export const EditSystemUsers = (props) => {
  const { data } = props;
  const { actionsLoading } = useSelector((state) => state.admin);

  const [initialValues, setInitialValues] = useState({})

  const classes = useAuthStyles();
  
  const initsMemo = useMemo(() => initialValues, [initialValues]);

  useEffect(() => {
    const initialsV = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
    }

    setInitialValues(initialsV)
  }, [data.email, data.firstName, data.id, data.lastName, data.phoneNumber])


  const dispatch = useDispatch();

  return (
    <div className="site-modal">
      <div
        className="site-header"
      >
        <Typography
          sx={{
            position: 'absolute', right: 0, cursor: 'pointer'
          }}
        >
          <img src={close} onClick={() => dispatch(modalActions.hideModal())}
            alt="close button" />
        </Typography>
        <DialogTitle style={{ fontSize: '1.2em', fontWeight: '700', padding: '0' }} id="scroll-dialog-title">Edit System User </DialogTitle>
        <ApiMessages/>
        <SuccessApiMessages/>
      </div>
      <Formik
        initialValues={initsMemo}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          resetForm()
          dispatch(updateSystemUser(values))
        }}
      >
        {({ handleChange, touched, values, errors, handleBlur, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <TextFieldItem
                  name="firstName"
                  label="First Name"
                  icon="user"
                  hasIcon
                  onChange={handleChange}
                  onBlur={handleBlur}
                  validationField={touched.firstName}
                  value={values.firstName}
                  error={errors.firstName}
                  fullWidth={false}
                />
              </Grid>
              <Grid item xs>
                <TextFieldItem
                  name="lastName"
                  icon="user"
                  hasIcon
                  label="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  validationField={touched.lastName}
                  value={values.lastName}
                  error={errors.lastName}
                  fullWidth={false}
                />
              </Grid>
            </Grid>
            <TextFieldItem
              name="email"
              icon="envelope"
              hasIcon
              label="Email Address"
              onChange={handleChange}
              onBlur={handleBlur}
              validationField={touched.email}
              value={values.email}
              error={errors.email}
            />
            <PhoneInput
              country="ke"
              value={values.phoneNumber}
              onChange={(value, data, event, formattedValue) => setFieldValue('phoneNumber', formattedValue)}
              specialLabel="Phone Number"
              enableSearch={true}
              autocompleteSearch={true}
              style={{
                margin: '10px 0',
              }}
              inputStyle={{
                width: '100%',
                padding: '12px 53px',
              }}
              searchStyle={{
                width: '100%',
              }}
              inputProps={{
                name: 'phoneNumber',
                required: true,
              }}
            />
            {errors.phoneNumber && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">{errors.phoneNumber}</p>}
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>

              </Grid>
              <Grid style={{ textAlign: 'end' }} item xs>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.submit}
                >
                  Submit
                  {(actionsLoading) && (
                    <CircularProgress
                      size={24}
                      color="loader"
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>

          </Form>
        )}
      </Formik>
    </div>
  );
}