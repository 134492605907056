import { Button, CircularProgress } from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { modalActions } from 'modules/modals/_actions';
import { useDispatch, useSelector } from 'react-redux';
import { sendToAll } from '../_redux/admin/adminActions';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';


export const ConfirmReminder = ({ modalID, data }) => {
    const dispatch = useDispatch();

    const { actionsLoading } = useSelector((state) => state.admin);
    const { values } = data;

    const classes = useAuthStyles();
    const proceedToCancel = () => {
        dispatch(sendToAll(values))
    }

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal-twisted">
            <div className="select-file p-10">
                <SuccessApiMessages/>
                <h3>This message will be sent to all customers, do you want to proceed?‘</h3>
                <ApiMessages />
                <div className="btn-group-actions site-modal__actions">
                    <Button
                        style={{ border: 'thin solid #00ACFF', color: '#00ACFF' }}
                        onClick={() => proceedToCancel()}
                    >
                        Yes
                        {(actionsLoading) && (
                            <CircularProgress
                                size={24}
                                color="darkloader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        className="danger"
                        variant="outlined"
                        onClick={() => closeModal()}
                    >
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
};
