import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  box: {
    padding: '0 50px',
    textAlign: 'left',
  },
  bottomText: {
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: '900 ! important',
    textAlign: 'center',
  },
  bottomTextWrapper: {
    margin: '20px 0',
    textAlign: 'center',
  },
  textPolicy: {
    margin: '2rem 0',
    fontSize: '12px',
  },
}));

function RedirectGoogleAuth({ isLoginPage }) {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <center>
        <Typography variant="p" className={classes.textPolicy}>
          By continuing, you agree to Cloud Wallet{' '}
          <Link to="/terms-and-conditions" style={{ textDecoration: 'none', fontWeight: 'normal', color: '#364B9D' }}>Terms of service</Link> &<br></br>
          <Link to="/privacy-policy" style={{ textDecoration: 'none', fontWeight: 'normal', color: '#364B9D' }}>Privacy Policy.</Link>

        </Typography>
        <br />
        <div className={classes.bottomTextWrapper}>
          <Typography
            variant="p"
            className={classes.bottomText}
            style={{ fontWeight: 'normal' }}
          >
            {isLoginPage ? 'Got no account' : 'Already a member'}?{' '}
            <Link
              to={isLoginPage ? '/onboarding?q=2' : '/login'}
              style={{
                textDecoration: 'none',
                fontWeight: 'normal',
                color: '#364B9D',
              }
            }
            >
              {isLoginPage ? 'Sign Up' : 'Login'}
            </Link>
          </Typography>
        </div>
      </center>
    </Box>
  );
}

export default RedirectGoogleAuth;
