import { Button, CircularProgress } from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { modalActions } from 'modules/modals/_actions';
import { useDispatch, useSelector } from 'react-redux';
import { undoCancelSub } from '../_redux/admin/adminActions';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { useLocation } from 'react-router-dom';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';

export const UndoConfirmSubs = ({ modalID, data }) => {
    const dispatch = useDispatch();
    const location  = useLocation();

    const { actionsLoading } = useSelector((state) => state.admin);
    const {  id, values } = data;

    const classes = useAuthStyles();
    const proceedToCancel = () => {
            if(location.state !== null){
                dispatch(undoCancelSub(location.state.id, id, values))
            }
    }

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal-twisted">
            <div className="select-file p-10">
                <h3>Do you still want to proceed? The subscription will be activated!</h3>
                <ApiMessages />
                <SuccessApiMessages/>
                <div className="btn-group-actions site-modal__actions">
                    <Button
                        style={{ border: 'thin solid #00ACFF', color: '#00ACFF' }}
                        onClick={() => proceedToCancel()}
                    >
                        Yes
                        {(actionsLoading) && (
                            <CircularProgress
                                size={24}
                                color="darkloader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        className="danger"
                        variant="outlined"
                        onClick={() => closeModal()}
                    >
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
};
