import { useField } from 'formik';
import { InputAdornment, TextField } from '@mui/material';
import { at } from 'lodash';
import './index.scss';

export default function InputField(props) {
  const {
    type, disabled = false,
    errorText, hasIcon, icon, ...rest
  } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
    return error;
  }
  return (
    <TextField
      id="outlined-basic"
      size="small"
      variant="outlined"
      disabled={disabled}
      sx={{ margin: '8px 0 8px 0' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            { hasIcon && <i className={`fa fa-lg fa-fw fa-${icon} icon-input`} aria-hidden="true" />}
          </InputAdornment>
        ),
      }}
      type={type === undefined ? 'text' : type}
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
}
