import {
  Box, Button, Typography, CircularProgress,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import TextFieldItem from 'components/common/inputs/TextField';
import { useFormik } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import GoogleComponent from '../google';
import { login } from '../_redux/authActions';

const validationSchema = yup.object({
  username: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
});

function Login() {
  const dispatch = useDispatch();

  const {
    actionsLoading,
  } = useSelector(
    (state) => ({
      actionsLoading: state.oauth.actionsLoading,
    }),
    shallowEqual,
  );

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });
  const classes = useAuthStyles();

  return (
    <div style={{ padding: '200px 7.2vw', }}>
      <Box className={classes.login}>
        <Typography
          className={classes.loginTitle}
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            marginBotttom:'20px',
            fontWeight: '500',
            fontSize: '1.3em',
            lineHeight: '29px',
            textAlign: 'center',
            color: '#041C26',
          }}
        >
          Enter your details to login
        </Typography>
        <ApiMessages/>
        <GoogleComponent action="Sign in with Google" isLoginPage={true} />
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextFieldItem
            name="username"
            label="Email Address"
            icon="envelope"
            hasIcon
            value={formik.values.username}
            onChange={formik.handleChange}
            validationField={formik.touched.username}
            error={formik.errors.username}
          />
          <TextFieldItem
            name="password"
            label="Password"
            icon="lock"
            hasIcon
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            validationField={formik.touched.password}
            error={formik.errors.password}
          />
          <div>
            <Typography variant="p" style={{ fontWeight: 'normal' }} className={classes.altText}>
              Forgot Password? <Link to="/forgot-password">Reset</Link>
            </Typography>
          </div>

          <Button
            color="primary"
            variant="outlined"
            type="submit"
            className={classes.submit}
          >
            Login
            {actionsLoading && (
              <CircularProgress
                size={24}
                color="loader"
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </form>
      </Box>
    </div>
  );
}

export default Login;
