import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useStyles } from "components/homepage";
import Gmail from '../../assets/images/icons8-gmail.svg';
import Gdocs from '../../assets/images/icons8-google-docs.svg';
import Gcalendar from '../../assets/images/icons8-google-calendar.svg';
import Gmeet from '../../assets/images/icons8-google-meet.svg';
import Gdrive from '../../assets/images/icons8-google-drive.svg';
import { Link } from "react-router-dom";

const st = {
  boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
  padding: '1rem',
  borderRadius: '10px',
  background: '#FFFFFF',
  marginBottom: '1rem',
};


export const mapItems = () => (
  <Grid container style={{ justifyContent: 'space-around' }} item xs={12} md={12}>
    <Card style={st}>
      <img src={Gmail} alt="" />
    </Card>
    <Card style={st}>
      <img src={Gdocs} alt="" />
    </Card>
    <Card style={st}>
      <img src={Gcalendar} alt="" />
    </Card>
    <Card style={st}>
      <img src={Gmeet} alt="" />
    </Card>
    <Card style={st}>
      <img src={Gdrive} alt="" />
    </Card>
  </Grid>
);

export const EasySetUp = () => {

  const classes = useStyles();

  return (
    <Box className={classes.cloudProducts}>
      <Divider spacing={1}>
        <Typography className={classes.cloud} variant="h2">
          Google Productivity and Collaboration Tools
        </Typography>
      </Divider>
      <Grid container spacing={2} className={classes.imageWrapper}>
        {mapItems()}
      </Grid>
      <Divider spacing={1}>
        <Link to="/onboarding?q=1" style={{ textDecoration: 'none' }}>
          <Button variant="outlined" sx={{ margin: '0 30px' }}>Take a Tour</Button>
        </Link>
      </Divider>
    </Box>
  )
}