import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Legal() {

    return (
        <Box style={{ padding: '150px 7.2vw' }}>
            {/* Hero unit */}
            <hr></hr>
            <Typography
                component="h2"
                variant="h2"
                align="center"
                color="text.primary"
                style={{ paddingBottom: '15px' }}
            >
                TERMS AND CONDITIONS OF USE
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                These terms and conditions of use, all policies, and all addendums found on Pay4Cloud’s sites, applications, programs, tools, and services set out the terms under which Pay4Cloud provides you access to its Services. By signing onto this platform, you agree to comply with all the terms and conditions of use and policies when accessing or using our Services. Please read these terms and conditions carefully. By using our Services, you are bound by these terms and conditions.
                Pay4Cloud reserves the right to refuse services in its sole discretion.
                In these general terms and conditions, the words “we”, “us” and "website” shall be used interchangeably to refer to Pay4Cloud’s websites and mobile applications.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud reserves the right to refuse services in its sole discretion.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                In these general terms and conditions, the words “we”, “us” and "website” shall be used interchangeably to refer to Pay4Cloud’s websites and mobile applications.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                User Account
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You may be required to own an account with Pay4Cloud to use certain services. You may register for an account with our Pay4Cloud by completing and submitting the registration form on the Pay4Cloud. You may be required to log into your account and have a valid payment method associated with it.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You may not register with Pay4Cloud if you are under 18 years of age. By using our Pay4Cloud or agreeing to these general terms and conditions, you warrant and represent to us that you are at least 18 years of age. If you are below 18 years old, you may use our Pay4Cloud only with the involvement of a parent or guardian who is above 18 years of age.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You represent and warrant that all information provided in the registration form is complete and accurate. You are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You agree to provide and maintain accurate, current and complete information, including your contact information for purposes of communications from us; and your payment information. You undertake not to provide and/or use false or misleading information in connection to your account, or trade on the name or reputation of others. Pay4Cloud reserves the right to change or remove any information that it considers inappropriate or unlawful, or otherwise likely to expose Pay4Cloud to claims by third parties. Pay4Cloud reserves the right to take steps to verify the accuracy of information you have provided to us.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                By registering for an account with Pay4Cloud, you will be asked to provide an email address/user ID and password and you agree to:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>Keep your password confidential</li>
                <li>
                    notify us in writing immediately (using our contact details provided at below) if you become aware of any disclosure of your password; and
                </li>
                <li>be responsible for any activity on our Pay4Cloud arising out of any failure to keep your password confidential, and that you may be held liable for any losses arising out of such a failure.
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Your account is to be used exclusively by you. Authorizing any third parties to use your account is at your own risk and liability.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud in its sole discretion and without notice or explanation, may suspend and/or cancel your account, and/or edit your account details, at any time, provided if at the time of such action you have paid for products and/or services which you have not received, and are not in breach of these general terms and conditions or any other policies, such monies will be refunded to you.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You reserve the right to terminate your account on our Pay4Cloud by contacting us through the contact information provided herein.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                All information collected by Pay4Cloud will be collected, stored, processed and disseminated in accordance with the provisions of these terms and conditions, the privacy policy and any other policies, and the Data Protection Act, No.24 of 2019, Laws of Kenya.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Term of Agreement
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                This agreement shall come into force on the date of signing and shall remain in force until either party terminates it in accordance with this agreement.            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Placing Orders
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                In order to transact using our website, we will require the following information from you:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>Personal identification information including your name, address, identification documentation type and number, date of birth, age, address, gender, phone number, location, and photograph;</li>
                <li>
                    Information about your bank account number, debit and credit card information or other relevant banking information; and                 </li>
                <li>Any other information as we may deem relevant from time to time.

                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                All Purchase Orders accepted by Pay4Cloud are non-cancelable by the User and may not be modified without the prior written consent of Pay4Cloud.            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Payment Method
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We reserve the right to select the method by which to accept payment from you. These payment methods may include, but may not be limited mobile payments via mpesa , airtel money transfer , visa , mastercard and other global payment cards
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                The User shall provide at least the following options for making payment:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>Cheque; </li>
                <li>
                    On-line payment via web portal;</li>
                <li>Unlimited electronic cheque or debit card payment via telephone;
                </li>
                <li>Automatic draft withdrawal from a designated account; or
                </li>
                <li> Electronic funds transfer.
                </li>
            </ul>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Price
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We reserve the right to amend and revise the prices quoted. Prices for products and services may change from time to time at the sole discretion of the seller.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Termination
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Any party can terminate this agreement without cause by cancelling their subscription.
                If a party commits a material breach of any term in this agreement, the party affected by such breach will issue  written notice to that effect if the other Party shall commit any material breach of its obligations hereunder which is not capable of remedy or which shall not have been remedied within seven (7) days of the other Party having received a written complaint (delivered through email) specifying the nature of such breach and requiring its rectification.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Reviews, Comments, Communications, and Other Content
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You may post reviews, comments, communication and any other content in any forms including without limitation text, graphics, images, audio, video, audio-visual material, scripts, software and files; submit suggestions, ideas, questions or other information.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                All content shared with us by you for storage, publication, processing or onward transfer by Pay4Cloud must be accurate, complete, and true and must abide with the Community Guidelines and acceptable standards of civil, tasteful and appropriate. The content must not:
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Be illegal, obscene, pornographic, lewd, suggestive or sexually explicit, threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including the right to publicity), or otherwise injurious to third parties or objectionable;
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Contain depictions of violence, belligerence, blasphemous, racist, vexatious or causing unnecessary anxiety, inciting hatred on religious, political or other grounds, or discriminatory;            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Consist of or contain software viruses, political persuasions, commercial solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited commercial electronic messages.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Your content must not infringe on any person's legal rights, or be capable of giving rise to legal action against any person in any jurisdiction under any laws.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Your content must not infringe upon or breach:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>any intellectual property right, including copyrights, trademark right, patents, design right or other intellectual property right;</li>
                <li>any personal rights to privacy, protection of personal data, confidentiality, or any other rights protected under the Constitution and any other written law;</li>
                <li>any contractual obligation owed to any person;
                </li>
                <li>any court order; or
                </li>
                <li> the provisions of any existing law or regulation.
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You must not use Pay4Cloud to post on this Pay4Cloud any links to any secondary website or pages containing material that would constitute a breach of these general terms and conditions were it posted on this Pay4Cloud.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You warrant that all content submitted to our Pay4Cloud is not the subject of any legal action or proceedings, or the threat of such legal action or proceedings or any related claims.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud provides a review function to be used by users to give honest and accurate reviews of products. Users must abide by Pay4Cloud’s community guidelines and terms and conditions to ensure their reviews about the products and services are helpful. The user may not manipulate the Pay4Cloud in any way to allow fake, inaccurate, inauthentic reviews. Pay4Cloud must not instruct third parties to give positive reviews or ask a reviewer to edit, delete or alter their review in any way.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Any complaints, worries, concerns or allegations that a review does not comply with the terms and conditions should be notified to Pay4Cloud through P.O. Box 21372 - 00100-00100 Nairobi GPO.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Permissions to your content
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                If you post or submit content, and unless we indicate otherwise, you grant to us a worldwide, irrevocable, non-exclusive, royalty-free, perpetual, and fully sub-licensable license to use, reproduce, store, adapt, modify, perform, publish, translate, create derivative works from, display and distribute your content across our marketing channels throughout the world in existing or future media.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You grant to us the right to bring an action for infringement of the rights licensed under these terms and conditions.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You grant Pay4Cloud and any sub-licensees the right to use the name that you submit in connection with such content, if they choose. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify Pay4Cloud for all claims resulting from content you supply. Pay4Cloud has the right but not the obligation to monitor and edit or remove any activity or content. Pay4Cloud takes no responsibility and assumes no liability for any content posted by you or any third party.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You hereby waive all your moral rights in your content to the maximum extent allowed by applicable law; and you warrant and represent that all other moral rights in your content have been waived to the maximum extent permitted by applicable law.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Without prejudice to our other rights under these general terms and conditions, if you breach our rules on content in any way, or if we reasonably suspect that you have breached our rules on content, we may delete, unpublish or edit any or all of your content.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Intellectual Property
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Subject to the express provisions of these general terms and conditions:
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                All content included in or made available through any Pay4Cloud Service, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software is the property of Pay4Cloud or its content suppliers and protected by the Laws of Kenya and international copyright laws. The compilation of all content included in or made available through any  Pay4Cloud Service is the exclusive property of Pay4Cloud and protected by the Laws of Kenya and international copyright laws.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                All of Pay4Cloud’s logos and our other registered and unregistered trademarks are trademarks belonging to us; we give no permission for the use of these trademarks, and such use may constitute an infringement of our rights. Pay4Cloud’s trademarks and trade dress may not be used in connection with any product or service that is not Pay4Cloud’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Pay4Cloud.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                All other trademarks not owned by Pay4Cloud that appear in any Pay4Cloud Service are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Pay4Cloud and we cannot grant any license on their behalf.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We respect the intellectual property of others. If you believe that your intellectual property rights are being infringed, please contact us in the manner provided by these general terms and conditions.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                component="h3"
            >
                Takedown Notice
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud respects artist and content owner rights, it is our policy to respond to alleged infringement notices that comply with the Copyright Act Cap. 130 Laws of Kenya.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible via our website, please notify Pay4Cloud copyright agent as set forth in the Copyright Act. For your complaint to be valid under the Copyright Act, it must be in writing and provide the following information:
            </Typography>

            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                contain the full names and telephone, physical and email address of the complainant;
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>be signed by the complainant or his authorized agent;</li>
                <li>
                    describe in specific detail the copyright work subject to the alleged infringement or sought to be removed;</li>
                <li>identify the rights being infringed;

                </li>
                <li>set out the content sought to be removed with details of where the content is contained;

                </li>
                <li>attach an affidavit or any other declaration attesting to claim of ownership, validity of the rights, good faith and setting out any efforts to have entities responsible for making the content available to remove the content;

                </li>
                <li>be copied to the Kenya Copyright Board, Communication Authority and the recognised umbrella association of service providers.
                </li>
            </ul>
            <hr></hr>

        </Box>
    );
}

export default Legal;
