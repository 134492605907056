import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link as LinkTo } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViewModal from '../ViewDetails/view';
import { modalActions } from 'modules/modals';
import { useDispatch } from 'react-redux';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      padding: '10px 10px',
      fontFamily: 'Work Sans',
      fontSize: '15px',
      '& .MuiSvgIcon-root': {
        fontSize: '11px',
        fontFamily: 'WorkSans',
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&:hover': {
        backgroundColor: '#18a0fb',
        color: '#fff',
      },
    },
  },
}));

export default function ActionsMenus({ id, row, profile }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAct = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosedAction = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={openAct ? 'actionmenu' : undefined}
        aria-haspopup="true"
        sx={{ backgroundColor: '#00acff', fontSize: '.8em', fontWeight: 500 }}
        aria-expanded={openAct ? 'true' : undefined}
        variant="contained"
        onClick={handleClickAction}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={openAct}
        onClose={handleClosedAction}
      >
        <MenuItem>
          <ViewModal id={id} row={row} />
        </MenuItem>
        <MenuItem disableRipple>
          <LinkTo to="/edit/plan" state={{ upgrade: true }} style={{ textDecoration: 'none' }}>
            Upgrade Plan
          </LinkTo>
        </MenuItem>
        <MenuItem disableRipple>
          <LinkTo to={profile.editionLabel !== 'Business Starter' ? `/edit/plan` : '#'} state={{ upgrade: false }} style={{ textDecoration: 'none' }}>
            Downgrade Plan
          </LinkTo>
        </MenuItem>
        <MenuItem disableRipple>
          <LinkTo to="/edit-plan" state={{ upgrade: false }} style={{ textDecoration: 'none' }}>
            Edit
          </LinkTo>
        </MenuItem>
        {row && row.active &&
          <MenuItem onClick={() => dispatch(modalActions.cancel_sub({ row, profile }))} disableRipple>
            Cancel subscription
          </MenuItem>
        }
      </StyledMenu>
    </div>
  );
}
