import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function AboutUs() {

    return (
        <Box style={{ padding: '150px 7.2vw' }}>
            {/* Hero unit */}
            <Typography
                component="p"
                variant="p"
                align="center"
                color="text.primary"
                style={{ paddingTop: '15px', fontWeight: '500', fontSize: '1.25em' }}
            >
                About Pay4Cloud
            </Typography>
            <Typography
                variant="p"
                align="center"
                color="text.primary"
                component="p"
                style={{ padding: '0 15vw' }}
            >
                Throughout Incentro Africa’s history, we discovered that numerous organisations were facing challenges with regard to maintaining their Google Workspace expenditure. Bill shocks for monthly Google Workspace subscriptions meant there was a degree of uncertainty and unpredictability in the costing of the tool. Which would in turn lead to the inability to plan and budget accordingly. With highly trained IT and cloud specialists on hand, we created Pay4Cloud.
            </Typography>
            <Typography
                variant="p"
                align="center"
                color="text.primary"
                component="p"
                style={{ padding: '0 15vw' }}
            >
                Pay4Cloud is an Incentro designed solution for organisations that utilise Google Workspace. Currently using Google Workspace means expenses can fluctuate based on usage if you pay via a credit/debit card.
                Conversely committing to an annual plan ties organisations to a set package with little room for flexibility throughout the year. With Pay4Cloud we are aiming to put back  in control of their spending allowing them to use Google Workspace comfortably within their predefined budget. Pay4Cloud is perfect for small to medium organisations that want better control of their IT expenditures while offering additional support for users facing challenges in maintaining their spend. Additionally as a value added service our certified Google Workspace practitioners remain on hand for all Pay4Cloud clients to offer round the clock support and training.

            </Typography>
        </Box>
    );
}

export default AboutUs;
