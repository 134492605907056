import { useEffect, useState } from 'react';

function StatusRaw({ status }) {
  const [color, setColor] = useState();
  const [text, setText] = useState();


  useEffect(() => {
    switch (status) {
      case "ACTIVE":
        setText('active');
        setColor('#5ebd12');
        break
      case "SUSPENDED":
        setText('SUSPENDED')
        setColor('#FFC700');
        break
      case "Insufficient Balance":
        setText('Insufficient Balance')
        setColor('red');
        break;
      case true:
        setText('active');
        setColor('#5ebd12');
        break
      case false:
        setText('inactive');
        setColor('red');
        break
      case "No Subscription":
        setText('No Subscription');
        setColor('red');
        break
      default:
        setText(status);
        setColor('red');
        break;
    }
  }, [status]);

  return (
    <>
    <span
      style={{
        backgroundColor: color,
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: 500,
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '8px', 
      }}
      variant="contained"
    > {text}
    </span>    
    </>
  );
}

export default StatusRaw;


