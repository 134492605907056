import { Button, CircularProgress, DialogTitle, Grid, Typography } from "@mui/material";
import TextFieldItem from 'components/common/inputs/TextField';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { Form, Formik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from 'modules/modals';
import close from 'assets/images/pret.svg';
import { updateEdition } from "../_redux/admin/adminActions";
import { ApiMessages } from "components/common/ApiMessages/apimessages";
import { SuccessApiMessages } from "components/common/SuccessApiMessages/successapimessages";

const validationSchema = yup.object({
    licenses: yup
        .number('Enter Licenses')
        .required('Licenses is required'),
});

export const SendMessage = (props) => {
    const { data: { row: { licences }, customer_id } } = props
    const classes = useAuthStyles();
    const { actionsLoading, } = useSelector((state) => state.admin);

    const dispatch = useDispatch();

    return (
        <div className="site-modal">
            <div className="site-header">
                <Typography
                    sx={{
                        position: 'absolute', right: 0, cursor: 'pointer'
                    }}
                >
                    <img src={close} onClick={() => dispatch(modalActions.hideModal())}
                        alt="close button" />
                </Typography>
                <DialogTitle style={{ fontSize: '1.2em', fontWeight: '700', padding: '0' }} id="scroll-dialog-title">Update Subscription</DialogTitle>
                <ApiMessages/> 
                <SuccessApiMessages/>
            </div>
            <Formik
                initialValues={{
                    editionId: '',
                    licenses: licences,
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    dispatch(updateEdition(customer_id, values))
                }}
            >
                {({ handleChange, touched, values, errors, handleBlur, setFieldValue, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item xs>
                                <TextFieldItem
                                    name="licenses"
                                    label="Licenses"
                                    type="number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationField={touched.licenses}
                                    value={values.licenses}
                                    error={errors.licenses}
                                    fullWidth={false}
                                />

                            </Grid>
                        </Grid>

                        <Grid container alignItems="center" spacing={3}>
                            <Grid style={{ textAlign: 'end' }} item xs>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.submit}
                                >
                                    Submit
                                    {(actionsLoading) && (
                                        <CircularProgress
                                            size={24}
                                            color="loader"
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>

                    </Form>
                )}
            </Formik>
        </div>
    );
}