import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { modalActions } from 'modules/modals';
import { useDispatch } from 'react-redux';
import { Messages } from './messages';

const SuperAdminMessages = () => {
  const dispatch = useDispatch()
  const sendMessage = () => {
    dispatch(modalActions.send_to_all())
  }
  return (
    <div className="dashboard-container"
    >
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box style={{ marginBottom: '2rem' }}>
          <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}>Admin</Typography>
          <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}>Messages</Typography>
        </Box>
        <Box style={{ marginBottom: '2rem' }}>
          <Button onClick={() => sendMessage()} style={{
            border: 'thin solid #00ACFF', color: '#00ACFF', padding: '.1em .9em', borderRadius: '6px',
          }
          }>
            <AddIcon color="#606060" />
              Bulk
          </Button>
        </Box>
      </Box>
      <div className='super-customer'>
        <Messages />
      </div>
    </div>
  );
};

export default SuperAdminMessages;
