import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  // stepper: {
  //   padding: theme.spacing(3, 0, 5),
  // },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0',
    padding: '10px 0 50px',
    alignItems: 'end',
  },
  buttonBack: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    border: '2px solid #1d179e',
    padding: '8px 90px',
    color: '#3639a4',
    backgroundColor: 'transparent',
    fontSize: '17px;',
    textTransform: 'capitalize',
  },
  buttonSave: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    padding: '10px 60px',
    color: '#ffff',
    fontSize: '17px;',
    textTransform: 'capitalize',
  },
  wrapper: {
    margin: '0 15px',
  },
  buttonProgress: {
    margin: '0 0 0 15px',
  },
}));
