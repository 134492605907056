import {
    Box, Button, Card, Grid, Link, Typography,
  } from '@mui/material';
  import { getTransactions } from 'modules/payment/wallet/_redux/walletsActions';
  import { useEffect } from 'react';
  import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TransactionsTable from '../Admin/Table/table';
  
  function MyWalletTransactionsDashboard() {
    const currentUser = useSelector((state) => state.oauth);
    const dispatch = useDispatch();
    const { actionsLoading: userloading, currentUserProfile } = currentUser;  
    return (
      <Box>
        <Box style={{ marginBottom: '2rem', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography style={{ fontSize: '.8em', color: '#C4C4C4' }}>My</Typography>
            <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}>Transactions</Typography>
          </Box>
        </Box>
        {/* {userloading && <CircularProgress />} */}
        {currentUserProfile?.companyName === null && (
          <>
            <Typography style={{ fontSize: '1.2em' }}>You've not created a workspace yet</Typography>
            <br />
            <Link href="/onboarding?q=2">
              <Button variant="outlined">Create WorkSpace</Button>
            </Link>
          </>
        )}
        {currentUserProfile?.companyName !== null && !userloading && (
          <Grid container xs={12} style={{ alignItems: 'stretch', width: '100%' }}>
            <Grid item xs md={12} style={{ alignItems: 'stretch', display: 'block', paddingTop: '30px' }}>
              <Card
                style={{
                  display: 'block',
                  width: '100%',
                  margin: '0',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <TransactionsTable/>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
  
  export default MyWalletTransactionsDashboard;
  