import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useDispatch, useSelector } from 'react-redux';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import { getAllEditions } from 'modules/adduser/_redux/accounts/accountsActions';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import PriceModal from './pricingModal';
import './index.scss';
import Cookies from 'js-cookie';

function CurrentPlan() {
  const dispatch = useDispatch();
  const current = Cookies.get('currentPlan');
  const onSelectPlan = (tier) => {
    Cookies.set('currentPlan', JSON.stringify(tier));
  };
  const [currentPlan, setCurrent] = useState({})
  const { allEditions, actionsLoading } = useSelector((state) => state.workspace);
  useEffect(() => {
      if(allEditions?.length === 0){
        dispatch(getAllEditions());
      }
  }, [allEditions, dispatch]);


  useEffect(() => {
    if(current  === undefined){
      window.location.href = '/pricing'
    }else{
      setCurrent(JSON.parse(current))
    }
}, [current , dispatch]);


  return (
    <div style={{ padding: '150px 7.2vw' }}>
      {/* Hero unit */}
      <Typography
        component="p"
        variant="p"
        align="center"
        color="text.primary"
        style={{ paddingTop: '15px', fontWeight: '500', fontSize: '1.25em' }}
      >
        Confirm Your Edition
      </Typography>
      <Typography
        variant="p"
        align="center"
        color="text.primary"
        component="p"
        style={{ padding: '10px 15vw 20px' }}
      >
        Workspace plans start as low as $6 per user per month for Business
        Starter, $12 per user per month for
        Business Standard, and $18 per
        user per month for Business Plus. Quickly build an effective pricing
        table for your potential
        customers with this layout.
      </Typography>
      <Grid container spacing={5} style={{ marginTop: '50px' }} alignItems="stretch">
        {actionsLoading ? <center><p>Loading Plan</p></center> : allEditions?.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            className={`${currentPlan?.id === tier?.id ? 'pricing__card-active pricing__card' : 'pricing__card'}`}
            style={{ cursor: 'pointer' }}
            key={tier?.label}
            xs={11.5}
            sm={tier?.label === 'Enterprise' ? 12 : 6}
            md={3}
          >
            <Card
              style={{
                background: 'white',
                color: 'black',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <CardHeader
                title={tier?.label}
                subheader={tier?.subheader ? tier?.subheader : <br />}
                titleTypographyProps={{
                  component: 'h3', variant: 'h3', align: 'center', color: 'black',
                }}
                action={
                    tier?.label === 'Business Standard' ? <StarIcon /> : null
                  }
                subheaderTypographyProps={{
                  align: 'center',
                  color: 'text.primary',
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >{tier?.label === 'Enterprise' ? (
                  <Typography variant="h6" color="text.secondary">Contact Sales Team</Typography>
                ) : (
                  <>
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      ${tier?.amount}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {tier?.rateLabel}
                    </Typography>
                  </>
                )}
                </Box>
                <CardActions style={{ justifyContent: 'center' }}>
                  <PriceModal
                    text="View More"
                    fullWidth={true}
                    title={currentPlan?.title}
                    testid="get-started-starter"
                    nextStage="/payment-plan"
                    variant="outlined"
                  />
                </CardActions>
                <center>
                  <Link to={`${tier?.label === 'Enterprise' ? '/support' : '/payment-plan'}`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="outlined"
                      data-testid={tier?.testid}
                      fullWidth
                      onClick={() => onSelectPlan(tier)}
                    >
                      {(currentPlan?.title || currentPlan?.label) === tier?.label ? 'Selected Plan' : 'Select'}
                    </Button>
                  </Link>
                </center>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default CurrentPlan;
