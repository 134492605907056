import MyWalletTransactionsDashboard from './transaction';

function MyWalletTransactionspage() {
  return (
    <div
      className="dashboard-container"
    >
      <MyWalletTransactionsDashboard />
    </div>
  );
}

export default MyWalletTransactionspage;
