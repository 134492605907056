import AdminTransactionsDashboard from './transaction';

function AdminCustomerTransactionspage() {
  return (
    <div
      className="dashboard-container"
      maxWidth={false}
    >
      <AdminTransactionsDashboard />
    </div>
  );
}

export default AdminCustomerTransactionspage;
