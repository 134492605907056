import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import Write from '../../assets/images/write.svg';

function GridCard({
  title,
  content,
  number,
  image,
}) {
  return (
    <Grid item xs={12} md={3}>
      <Card
        sx={{ minWidth: '275' }}
        style={{
          height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
        }}
      >
        <CardContent>
          <span style={{ display: 'flex', width: '100%', padding: '0 10px 0 0' }}><img alt="" src={Write} />
            <p style={{
              color: '#C4C4C4', fontSize: '36px', fontWeight: '700', fontFamily: 'Montserrat', padding: '0 15px 0 15px',
            }}
            >
              0{number}.
            </p><img alt="" src={image} />
          </span>
          <Typography
            sx={{ padding: '0 0 10px' }}
            variant="h3"
            gutterBottom
          >
            {title}
          </Typography>
          <Divider />
          <Typography sx={{ padding: '.8em 0' }} variant="body2">{content}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default GridCard;
