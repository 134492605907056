import { Button, CircularProgress } from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { setGlobalValue } from 'modules/adduser/_redux/accounts/accountsActions';
import { modalActions } from 'modules/modals/_actions';
import { useDispatch, useSelector } from 'react-redux';

export const ConfirmTransfer = ({ modalID, data }) => {
    const dispatch = useDispatch();
    const { reason} = data
    const { actionsLoading } = useSelector((state) => state.admin);

    const confirmTransfer = () => {
        dispatch(setGlobalValue(1));
    };

    const classes = useAuthStyles();


    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal-twisted">
            <p>{reason}</p>
            <p>Do you wish to transfer the workspace to Pay4cloud?</p>
            <div className="select-file p-10">

                <div className="btn-group-actions site-modal__actions">
                    <Button
                        color="primary"
                        onClick={() => confirmTransfer()} 
                        variant="outlined"
                    >
                        Yes
                        {(actionsLoading) && (
                            <CircularProgress
                                size={24}
                                color="loader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        className="danger"              
                        variant="outlined"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};
