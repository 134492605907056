import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import gmail from '../../../assets/images/gmail.png';
import meet from '../../../assets/images/meet.png';
import drive from '../../../assets/images/icons8-google-drive.svg';
import chat from '../../../assets/images/chat.png';
import calendar from '../../../assets/images/icons8-google-calendar.svg';
import docs from '../../../assets/images/icons8-google-docs.svg';
import app from '../../../assets/images/app.jpg';
import cloud from '../../../assets/images/cloud.jpg';
import { useEffect } from 'react';

export default function PlansAccordion({ title, open }) {
  const [expanded, setExpanded] = useState('panel1, panel2');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
  [1,2,3,4,5,6,7].map(item =>{
    return handleChange(`${item.panel}item`)
  })
  setExpanded('panel1');

  }, [open])
  
  return (
    <div>
      {/* gmail */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={gmail} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />Gmail for Business Email</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Custom email for your business</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Phishing and spam protection that blocks more than 99.9% of attacks</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Ad free email experience</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* meet */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={meet} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />Meet Video and voice conferencing</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            100
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            150
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            500
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            500
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Meeting length(maximum)</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>US or international dial-in phone numbers</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>
            Digital whiteboarding
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Noise cancellation
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>
            Meeting recordings saved to Google Drive
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* drive */}
      {/* chat */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={drive} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />Secure cloud storage</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            30 GB / user
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            2 TB / user
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            5 TB / user
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            As much as you need
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Drive for desktop</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            24 hours
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Support for over 100 file types</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>
            Shared drives for your team
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>
            Target audience sharing
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* meet */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={chat} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />Chat, Team messaging</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Turn history on or off by default</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Support for over 100 file types</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* calendar */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={calendar} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />Calendar, Shared calendars</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Appointment booking pages</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Browse and reserve conference rooms</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* calendar */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={docs} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />Docs, Sheets, Slides Collaborative content creation</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Keep Shared Notes</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Sites website builder</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Forms survey builder</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>Interoperability with Office files</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{ display: 'flex ', width: '98.3%' }}>
          <Typography sx={{ width: '40%', flexShrink: 0, padding: '0 0 0 40px' }}>
            Easier analysis with Smart Fill, Smart Cleanup, and Answers
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}><CheckIcon style={{ color: '#18A0FB' }} /></Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* app */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={app} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />AppSheet, Build apps without code</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            <CheckIcon style={{ color: '#18A0FB' }} />
          </Typography>
        </AccordionSummary>
      </Accordion>
      {/* app */}
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0 }}><img src={cloud} style={{ width: '30px', verticalAlign: 'middle', margin: '0 11px 0 0' }} alt="" />Cloud Search Smart search</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            __</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>1st party data
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>1st party data
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            1st & 3rd party data
          </Typography>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}
