import {
  CardActions, CardContent, CardHeader, Typography,
} from '@mui/material';

function DashboardCard({
  title, subheader, children, styleButtonJustify, buttons,
}) {
  return (
    <>
      <CardHeader
        title={title}
        style={{ padding: '16px 16px 10px 16px' }}
        subheaderTypographyProps={{ fontSize: '18px', fontWeight: 'bold' }}
        subheader={(subheader ? `www.${subheader}` : '')}
      />
      <CardContent style={{ padding: '0 16px' }}>
        {children}
      </CardContent>
      <CardActions style={{ justifyContent: styleButtonJustify, padding: '10px 16px 16px' }}>
        {buttons}
      </CardActions>
    </>
  );
}

export default DashboardCard;

export function CardText({
  title,
  subTitle,
  marginRight,
  marginLeft,
}) {
  return (
    <Typography
      variant="body3"
      style={{
         marginRight, marginLeft,
      }}
    >
      {title}
      <br />
      <span style={{ color: '#C4C4C4', fontSize: '18px' }}>
        {subTitle}
      </span>
    </Typography>);
}
