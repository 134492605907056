import AdminDashboard from './Admin';
import SuperAdminDashboard from './SuperAdmin';
import './dashboard.scss';

function DashboardHomepage() {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div
      className="dashboard-container"
    >
      {(user?.role_titles !== undefined  ? user?.role_titles.includes('Administrator') : user?.authorities.includes('ADMINISTRATOR')) ? <AdminDashboard /> : <SuperAdminDashboard /> }
    </div>
  );
}

export default DashboardHomepage;
