import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VideoComponent from 'components/video';
import GridCard from './card';
import ArrowGrey from '../../assets/images/arrow-grey.svg';
import NumberTwo from '../../assets/images/numbertwo.svg';
import Third from '../../assets/images/third.svg';

import EasySetupCloudsBg from '../../assets/images/clouds_bg/setup_clouds.svg';
import { Link } from 'react-router-dom';
import { breakpoints } from 'theme/theme';
import { EasySetUp } from 'components/easySetup/easySetup';

export const useStyles = makeStyles({
  hero: {
    display: 'flex',
    padding: '0px 7.2vw 5vw 7.2vw',
    marginTop: '10%',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
      height: 'auto',
      padding: '0 7.2vw 0',
      marginTop:'20%'
    }
  },
  heroContent: {
    width: '30%',
  },
  title: {
    padding: '0 0 50px',
  },
  bannerText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1.2em',
    lineHeight: '40px',
    paddingBottom: '25px',
    color: '#041C26',
    [breakpoints.down('md')]: {
      fontSize: '1.2em',
      lineHeight: '26px',
    },
    [breakpoints.up('md')]: {
    },
    [breakpoints.up('lg')]: {
    },
  },
  text: {
    textAlign: 'justify',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
  },
  herofirstcolumn: {
    padding: '0 10vw 0 0',
    [breakpoints.down('md')]: {
      padding: '7vh 0',
      margin: 'auto !important',
    },
    [breakpoints.up('md')]: {
      padding: '0 10vw 0 0',
      marginTop:'20%'

    },
    [breakpoints.up('lg')]: {
      padding: '0 10vw 0 0',
      marginTop:'20%'
    },
  },
  herobuttons: {
    [breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  video: {
    padding: '0 50px 0',
    margin: 'auto !important',
    textAlign: 'center',
    borderRadius: '20px',
    [breakpoints.down('md')]: {
      padding: '50px 0',
    },
    [breakpoints.up('md')]: {
    },
    [breakpoints.up('lg')]: {
    },
  },
  imageWrapper: {
    justifyContent: 'center',
    marginTop: '30px !important',
    marginRight: '0 !important',
    marginLeft: '0 !important',
    marginBottom: '30px !important',
    padding: '30px 0',
    backgroundImage: 'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="23"><image width="4" height="4" xlink:to="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNCIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNCA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSIyIiBjeT0iMiIgcj0iMiIgZmlsbD0iI0U1RTVFNSIvPgo8L3N2Zz4K" /></svg>\');',
  },
  imageItem: {
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
    padding: '1rem',
    borderRadius: '10px',
    background: '#FFFFFF',
  },
  cloud: {
    margin: '0 42px',
    [breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  cloudProducts: {
    padding: '50px 7.2vw',
  },
  hrLine: {
    height: '1px',
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    flexShrink: 0,
  },
  accountSetupHeader: {
    textAlign: 'center',
    fontStyle: 'normal',
  },
  accountSetupHeaderSubTitle: {
    fontSize: '.8em',
    lineHeight: '17px',
    textTransform: 'uppercase',
    fontWeight: '700 !important',
    color: '#C4C4C4',
  },
  accountSetupHeaderTitle: {
    fontWeight: 'bold',
    lineHeight: '42px',
    color: '#000000',
  },
  accountCards: {
    justifyContent: 'space-around',
    textAlign: 'left',
  },
  accountSetupContent: {
    marginTop: '50px',
    textAlign: 'center',
  },

  accountWrapper: {
    backgroundColor: '#F4F8FB',
    padding: '50px 7.2vw 70px',
    margin: '0',
    backgroundImage: `url(${EasySetupCloudsBg})`,
    backgroundRepeat: 'no-repeat',
  },
});




function Homepage() {

  const classes = useStyles();

  return (
    <div maxWidth="lg">
      <Grid className={classes.hero}>
        <Grid item xs={12} sm={4} md={6} className={classes.herofirstcolumn}>
          <Typography className={classes.title} variant="h1">A platform built to give you back control of your Google Workspace expenses!</Typography>
          <p className={classes.bannerText}>
          Spend what you can afford with confidence and ease.
          </p>
          <div className={classes.herobuttons}>
            <Link to="/onboarding?q=1" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" sx={{ margin: '30px 0 0 0' }}>
                Get Started
              </Button>
            </Link>
            <Link to="/pricing" style={{ textDecoration: 'none' }}>
              <Button
                sx={{ marginLeft: { xs: '2px', md: '20px' } }}
                style={{
                  marginTop: '30px',
                  border: 'thin solid #233168',
                  color: '#233168',
                  cursort: 'pointer',
                }}
              >
                Our Products
              </Button>
            </Link>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          className={classes.video}
          sx={{ display: { xs: 'block', md: 'block' }, textAlign: 'center' }}
        >
          <VideoComponent />
        </Grid>
      </Grid>

      <EasySetUp/>

      <Box className={classes.accountWrapper}>
        <Box className={classes.accountSetupHeader}>
          <Typography
            className={classes.accountSetupHeaderSubTitle}
          >
            EASY SETUP
          </Typography>
          <Typography variant="h2">
            Create an account in just two minutes
          </Typography>
        </Box>
        <Box className={classes.accountSetupContent}>
          <Grid container spacing={2} className={classes.accountCards}>
            <GridCard
              image={ArrowGrey}
              number="1"
              title="Create your account"
              content="Sign up for Pay4Cloud via your Gmail account or enter your personal details."
            />
            <GridCard
              image={NumberTwo}
              number="2"
              title="Charge your wallet"
              content="Enter your payment details and fund your wallet with your budget."
            />
            <GridCard
              image={Third}
              number="3"
              title="Create/link project"
              content="Connect your project and start enjoying products and services."
            />
          </Grid>
          <br /><br />
          <Link to="/onboarding?q=1" style={{ textDecoration: 'none' }}>
            <Button variant="outlined" sx={{ margin: '0 30px', padding: { xs: '4px 1rem', md: '8px 10rem' } }}>
              Get Started
            </Button>
          </Link>
        </Box>
      </Box>
    </div>
  );
}

export default Homepage;
