import {
  Divider,
  List,
  Typography,
  Box,
  ListItem,
  Checkbox,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useAuthStyles } from 'assets/scss/components/_auth';
import TextFieldItem from 'components/common/inputs/TextField';
import { checkWalletBalance } from 'modules/payment/wallet/_redux/walletsActions';
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { planUpgrade, planDowngrade } from 'modules/pricing/_redux/planActions';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';
import { breakpoints } from 'theme/theme';

const useStyles = makeStyles(() => ({
  wrapper: {
    textAlign: 'left',
    alignSelf: 'center',
    marginTop: '10%',
    marginBottom: '10%',
    [breakpoints.down('md')]: {
      width: '100%',
      marginTop: '30%',
      marginBottom: '30%'
    }
  },
  content: {
    paddingLeft: '0.5rem',
  },
  costText: {
    alignSelf: 'center',
  },
}));

const validationSchema = yup.object({
  licenses: yup
    .number('Enter Number of Licenses')
    .required('Number of licences is required'),
});

function ChangePlan() {
  const location = useLocation();
  const { upgrade } = location.state;
  const classes = useStyles();
  const authClasses = useAuthStyles();
  const [, setLicences] = useState();
  const current = Cookies.get('currentPlan');
  const [currentPlan, setCurrent] = useState({});
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.oauth);
  const { currentUserProfile } = currentUser;
  const { actionsLoading: load } = useSelector((state) => state.plan);


  const {
    subscriptions,
  } = useSelector(
    (state) => ({
      subscriptions: state.workspace.subscriptions,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if(current  === undefined){
      window.location.href = '/pricing'
    }else{
      setCurrent(JSON.parse(current))
    }
}, [current , dispatch]);

// useEffect(() => {
//   dispatch(getAllSubscriptions())
// }, [dispatch])


  const {
    wallet: { walletBalance },
  } = useSelector(
    (state) => ({
      walletBalanceLoading: state.wallet.actionsLoading,
      wallet: state.wallet,
    }),
    shallowEqual,
  );
  // useEffect(() => {
  //   if (!currentPlan || currentPlan === '' || currentPlan === undefined) {
  //     window.location.href = '/pricing';
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    dispatch(checkWalletBalance());
  }, [dispatch]);

  useEffect(() => {
    if ((currentPlan !== undefined) && (walletBalance !== undefined)) {
      const { amount } = currentPlan;
      setLicences(parseInt(walletBalance.currentBill / amount));
    }
  }, [walletBalance, currentPlan]);


  return (
    <div className={classes.wrapper}>
      <Box className={authClasses.root}>
      <ApiMessages/>
      <SuccessApiMessages/>
        <Formik
          initialValues={{
            licenses: subscriptions[0]?.licences,
            editionId: currentPlan?.id,
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (upgrade) {
              dispatch(planUpgrade(values));
            } else if (!upgrade) {
              dispatch(planDowngrade(values));
            }
          }}
        >
          {({
            handleSubmit, handleBlur, handleChange,
            errors, values, touched,
          }) => (
            <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Typography
                variant="h4"
                style={{ fontWeight: 'normal', fontSize: '1.2em' }}
                sx={{ pb: { xs: '0.5rem', md: '1rem' } }}
              >
                Payment Plan and Licenses
              </Typography>
              <Box sx={{ p: 5, border: '1px solid grey', borderRadius: '6px' }}>
                <Box sx={{ display: 'flex' }}>
                  <Checkbox disabled checked />
                  <Typography variant="h5" style={{ marginTop: '5px', fontSize: '1.2em', fontWeight: 'normal' }}>
                    {currentPlan?.label || currentPlan?.title}
                  </Typography>
                </Box>
                <Box className={classes.content}>
                  <List>
                    {(
                      currentPlan?.details || currentPlan?.description
                    )?.map((plan) => (
                      <ListItem key={Math.random()}>
                        <ListItemIcon>
                          <CheckIcon sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary={plan} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <br />
                  <Grid container alignItems="center" spacing={3} style={{ justifyContent: 'space-between' }}>
                    <Grid item md={3} xs style={{ display: 'flex' }}>
                      <TextFieldItem
                        name="licenses"
                        label="No. of License(s)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validationField={touched.licenses}
                        error={errors.licenses}
                        value={values?.licenses}
                        type="number"
                      />
                    </Grid>
                    {
                      (currentUserProfile?.paymentPlan !== null || currentPlan)
                      && (
                        currentUserProfile?.licenses !== null || values.licenses
                      ) && (
                        <Grid item md={4} xs style={{ display: 'flex' }}>
                          <Typography className={classes.costText}>
                            Estimated Monthly Cost $
                            {
                              Number(currentPlan?.amount || currentPlan?.price) * Number(
                                currentUserProfile?.licenses || values.licenses,
                              )}
                            USD/Month ({values.licenses || currentUserProfile?.licenses} license)
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                </Box>
              </Box>
              <br />
              <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                <Button variant="outlined" color="primary" type="submit">
                {upgrade ? 'Upgrade' : 'Downgrade'}
                  {(load) && (
                    <CircularProgress
                      size={24}
                      color="loader"
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default ChangePlan;
