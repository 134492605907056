import {
    Box, Card, Grid, Typography,
  } from '@mui/material';
import TicketsTable from './Table/table';
  
  function SupportTicketsDashboard() {
    return (
      <Box>
        <Box style={{ padding: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography style={{ fontSize: '1.2em', color: '#C4C4C4' }}> Tickets</Typography>
          </Box>
        </Box>
          <Grid container xs={12} style={{ alignItems: 'stretch', width: '100%' }}>
            <Grid item xs md={12} style={{ alignItems: 'stretch', display: 'block', paddingTop: '30px' }}>
              <Card
                style={{
                  display: 'block',
                  width: '100%',
                  margin: '0',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <TicketsTable />
              </Card>
            </Grid>
          </Grid>
      </Box>
    );
  }
  
  export default SupportTicketsDashboard;
  