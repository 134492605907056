import {
  Divider,
  List,
  Typography,
  Box,
  ListItem,
  Checkbox,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useAuthStyles } from 'assets/scss/components/_auth';
import TextFieldItem from 'components/common/inputs/TextField';
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { checkWalletBalance } from 'modules/payment/wallet/_redux/walletsActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllEditions, getAllSubscriptions } from 'modules/adduser/_redux/accounts/accountsActions';
import { updateCurrentPlan } from 'modules/pricing/_redux/planActions';
import { breakpoints } from 'theme/theme';
import Cookies from 'js-cookie';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';

const useStyles = makeStyles(() => ({
  wrapper: {
    textAlign: 'left',
    alignSelf: 'center',
    padding: '0',
    marginBottom: '10%',
    marginTop: '10%',
    [breakpoints.down('md')]: {
      width: '100%',
      padding: '0 7.2vw',
      marginTop: '30%',
      marginBottom: '30%'
    }
  },
  content: {
    paddingLeft: '0.5rem',
  },
  costText: {
    alignSelf: 'center',
  },
}));

function CurrentSubscription() {
  const classes = useStyles();
  const authClasses = useAuthStyles();
  const [currentPlan, setPlan] = useState({});
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.oauth);
  const { currentUserProfile } = currentUser;

  const plan = Cookies.get('currentPlan');

  const {
    subscriptions, allEditions
  } = useSelector(
    (state) => ({
      subscriptions: state.workspace.subscriptions,
      allEditions: state.workspace.allEditions,
      lastError: state.workspace.lastError,
    }),
    shallowEqual,
  );


  useEffect(() => {
    dispatch(getAllEditions());
  }, [dispatch]);

  // get user details
  useEffect(() => {
    dispatch(checkWalletBalance());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAllSubscriptions())
  }, [dispatch])


  useEffect(() => {
    if (allEditions !== null && subscriptions) {

      if (plan) {
        const setCurrentEdition = () => {
          const getCurrentEdition = allEditions.filter(item => item.label.toLowerCase() === subscriptions[0]?.editionLabel.toLowerCase())
          setPlan(getCurrentEdition && getCurrentEdition[0])
          dispatch(updateCurrentPlan(getCurrentEdition && getCurrentEdition[0]));
          Cookies.set('currentPlan', JSON.stringify(getCurrentEdition && getCurrentEdition[0]));
        }
        setCurrentEdition()
      }
    }
  }, [allEditions, dispatch, plan, subscriptions])

  return (
    <div className={classes.wrapper}>
      <Box className={authClasses.root}>
        <ApiMessages />
        <SuccessApiMessages />
        <Formik
          initialValues={{
            licenses: subscriptions[0]?.licences,
            editionId: currentPlan?.id,
          }}
          enableReinitialize
          onSubmit={(values) => {
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
              <Typography
                variant="h4"
                style={{ fontWeight: 'normal', fontSize: '1.2em' }}
                sx={{ pb: { xs: '0.5rem', md: '1rem' } }}
              >
                Payment Plan and Licenses
              </Typography>
              <Box sx={{ p: 5, border: '1px solid grey', borderRadius: '6px' }}>
                <Box sx={{ display: 'flex' }}>
                  <Checkbox disabled checked />
                  <Typography variant="h5" style={{ marginTop: '5px', fontSize: '1.2em', fontWeight: 'normal' }}>
                    {currentPlan?.label || currentPlan?.title}
                  </Typography>
                </Box>
                <Box className={classes.content}>
                  <List>
                    {(
                      currentPlan?.details || currentPlan?.description
                    )?.map((plan) => (
                      <ListItem key={Math.random()}>
                        <ListItemIcon>
                          <CheckIcon sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary={plan} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <br />
                  <Grid container alignItems="center" spacing={3} style={{ justifyContent: 'space-between' }}>
                    <Grid item md={3} xs style={{ display: 'flex' }}>
                      <TextFieldItem
                        name="licenses"
                        label="No. of License(s)"
                        onChange={props.handleChange}
                        value={props.values.licenses}
                        type="number"
                        disabled={true}
                      />
                    </Grid>
                    {
                      (currentUserProfile?.paymentPlan !== null || currentPlan)
                      && (
                        currentUserProfile?.licenses !== null || props.values.licenses
                      ) && (
                        <Grid item md={4} xs style={{ display: 'flex' }}>
                          <Typography className={classes.costText}>
                            Estimated Monthly Cost $
                            {
                              currentPlan?.amount * Number(
                                currentUserProfile?.licenses || props.values.licenses,
                              )}
                            USD/Month ({props.values.licenses || currentUserProfile?.licenses} license)
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                </Box>
              </Box>
              <br />
              <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                <Link state={{ upgrade: false }} to="/edit-plan" style={{ textDecoration: 'none' }}>
                  <Button
                    color="primary"
                    style={{ border: 'thin solid #18A0FB' }}
                    sx={{ pl: '1rem', pr: '1rem', mr: '1.3rem' }}
                  >
                    Edit License
                  </Button>&nbsp;
                </Link>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default CurrentSubscription;
