import {
  Box, Button, CircularProgress, Grid, Typography,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import TextFieldItem from 'components/common/inputs/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import ImageUpload from 'components/imageUpload';
import { Link, Link as LinkTo } from 'react-router-dom';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { profileEdited } from '../_redux/authActions';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';
import CloseIcon from '@mui/icons-material/Close';

function EditProfile() {
  const currentUser = useSelector((state) => state.oauth);
  const dispatch = useDispatch();
  const { currentUserProfile, actionsLoading } = currentUser;
  // get user details

  const classes = useAuthStyles();

  return (
    <div
      maxWidth={false}
      className={classes.container}
    >
      {/* <Box style={{padding: '20px 0'}}>
        <span style={{display: 'flex', alignItems: 'center'}}><Link style={{margin: '0 5px', display: 'flex', alignItems: 'center'}} to="/dashboard"> <ArrowBackIcon color="#606060"/></Link>Back</span>
      </Box> */}
      <Box className={classes.root}>
        <Box style={{ padding: '20px 0', width: '100%' }}>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}><Link style={{ margin: '0 5px', display: 'flex', alignItems: 'center' }} to="/dashboard"> <CloseIcon color="primary" /></Link></span>
        </Box>
        <Typography style={{ fontSize: '1.2em', fontWeight: 'normal' }}>My Profile</Typography>
        <ApiMessages />
        <SuccessApiMessages />
        <Formik
          htmlFor="amazing"
          enableReinitialize
          initialValues={{
            email: currentUserProfile?.email,
            firstName: currentUserProfile?.firstName,
            lastName: currentUserProfile?.lastName,
            phoneNumber: currentUserProfile?.phoneNumber,
            profileImageData: currentUserProfile?.profileImageData,
          }}
          onSubmit={(values) => {
            dispatch(profileEdited(values));
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <ImageUpload sendImage={(values) => props.setFieldValue('profileImageData', values)} initialImage={currentUserProfile?.profileImageData} cardName="Input Image" />
              <Grid container alignItems="center" spacing={3} style={{ marginLEft: 0 }}>
                <Grid item xs>
                  <TextFieldItem
                    name="firstName"
                    label="First Name"
                    icon="user"
                    hasIcon
                    value={props.values?.firstName}
                    fullWidth={false}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    validationField={props.touched.firstName}
                    error={props.errors.firstName}
                  />
                </Grid>
                <Grid item xs>
                  <TextFieldItem
                    name="lastName"
                    icon="user"
                    hasIcon
                    label="Last Name"
                    value={props.values?.lastName}
                    fullWidth={false}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    validationField={props.touched.lastName}
                    error={props.errors.lastName}
                  />
                </Grid>
              </Grid>
              <TextFieldItem
                name="email"
                icon="envelope"
                hasIcon
                label="Email"
                value={props.values?.email}
                fullWidth={false}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                validationField={props.touched.email}
                error={props.errors.email}
              />
              <PhoneInput
                country="ke"
                value={props.values.phoneNumber}
                onChange={(value, data, event, formattedValue) => props.setFieldValue('phoneNumber', formattedValue)}
                specialLabel="Phone Number"
                enableSearch={true}
                autocompleteSearch={true}
                style={{
                  margin: '10px 0',
                }}
                inputStyle={{
                  width: '100%',
                  padding: '12px 53px',
                }}
                searchStyle={{
                  width: '100%',
                }}
                inputProps={{
                  name: 'phoneNumber',
                  required: true,
                }}
              />
              <Grid container sx={{ padding: '30px 0' }} alignItems="center" spacing={3} style={{ marginLEft: 0 }}>
                <Grid item xs>
                  <LinkTo to={currentUserProfile?.role === "Administrator" ? '/subscription' : '/dashboard/system-users'} style={{ textDecoration: 'none' }}>
                    <Button
                      color="primary"
                      // className={classes.submit}
                      variant="outlined"

                      fullWidth
                    >
                      {currentUserProfile?.role === "Administrator" ? 'View subscription' : 'View Users'}
                    </Button>
                  </LinkTo>
                </Grid>
                <Grid item xs>
                  <Button
                    color="primary"
                    // className={classes.submit}
                    style={{
                      border: 'thin solid #18A0FB', color: '#18A0FB',
                    }}
                    fullWidth
                    type="submit"
                  >
                    Save Changes
                    {(actionsLoading) && (
                      <CircularProgress
                        size={24}
                        color="darkloader"
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default EditProfile;
