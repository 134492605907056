import {
  CardActions, CardContent, CardHeader, Typography,
} from '@mui/material';

function DashboardCard({
  title, subheader, children, styleButtonJustify, buttons,
}) {
  return (
    <>
      <CardHeader
        title={title}
        style={{ padding: '0 !important' }}
        titleTypographyProps={{ fontSize: '1.2em' }}
        subheader={subheader}
      />
      <CardContent>
        {children}
      </CardContent>
      <CardActions style={{ justifyContent: styleButtonJustify }}>
        {buttons}
      </CardActions>
    </>
  );
}

export default DashboardCard;

export function CardText({
  title,
  subTitle,
  marginRight,
  marginLeft,
}) {
  return (
    <Typography
      variant="body3"
      style={{
        marginRight, marginLeft,
      }}
    >
      {title}
      <br />
      <span style={{ color: '#C4C4C4', fontSize: '18px' }}>
        {subTitle}
      </span>
    </Typography>
  );
}
