import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { modalActions } from 'modules/modals';
import StatusTickets from 'components/common/Status/statustTickets';
import { supportsTicketsFetched } from 'modules/support/_redux/support/supportActions';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'createdDate',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
  },
  {
    id: 'ticketId',
    numeric: false,
    disablePadding: false,
    label: 'Ticket Id',
  },
  {
    id: 'supportType',
    numeric: false,
    disablePadding: false,
    label: 'Ticket Type',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'actionBy',
    numeric: false,
    disablePadding: false,
    label: 'Action By',
  },
  {
    id: 'readBy',
    numeric: false,
    disablePadding: false,
    label: 'Read By',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'status',
  },

  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

function EnhancedTableHead(props) {
  const {
    order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              sx={{ color: '#041c26', fontWeight: 'bold', fontSize: '.8em' }}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : ('')}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TicketsTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected,] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense,] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const { support_tickets, listLoading, totalElements } = useSelector((state) => state.support);

  React.useEffect(() => {
    dispatch(supportsTicketsFetched({ page, rowsPerPage }))
  }, [dispatch, page, rowsPerPage])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    if(totalElements < rowsPerPage){
      return;
  } else 
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{
        width: '100%', mb: 2, border: 'none', boxShadow: 'none',
      }}
      >
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={support_tickets && support_tickets.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {!listLoading ? (
                <>
                  {stableSort(support_tickets, getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);

                      return (
                        <TableRow
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.createdDate}</TableCell>
                          <TableCell align="left">{row.ticketId}</TableCell>
                          <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.supportType}</TableCell>
                          <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.description}</TableCell>
                          <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.email}</TableCell>
                          <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.actionBy}</TableCell>
                          <TableCell align="left" style={{ fontWeight: 'bold' }}>{row.readBy}</TableCell>
                          <TableCell align="left" style={{ fontWeight: 'bold' }}><StatusTickets status={row.status} /></TableCell>
                          <TableCell align="left" style={{ fontWeight: 'bold' }}>
                            <Button
                              aria-haspopup="true"
                              sx={{ backgroundColor: '#00acff', fontSize: '.8em', padding: '5px 10px', fontWeight: 500 }}
                              variant="contained"

                              disabled={row.invoiceId === null}
                              onClick={() => dispatch(modalActions.view_ticket({ row }))}
                            >
                              View More
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              )
                :
                <p style={{ padding: '20px' }}>Loading...</p>
              }

            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5,10, 15, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}
