import { handleResponseError } from 'helpers/base.service';
import * as requestFromServer from './accoutsCrud';
import { workpacesSlice, callTypes } from './accountsSlice';
import { getCurrentUserProfile, showApiMessages, successShowApiMessages } from 'modules/authentication/_redux/authActions';
import { modalActions } from 'modules/modals';

const { actions } = workpacesSlice;

export const checkDomainWorkspace = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createWorkspace(queryParams)
    .then((response) => {
      dispatch(successShowApiMessages(response))
      localStorage.setItem('accountSaved', JSON.stringify(queryParams));
      const { data } = response.data;
      const { exist } = data;
      if (exist) {
        dispatch(modalActions.confirm_domain_transfer({reason: 'The domain is already linked to a workspace account'}))
      }
      dispatch(getCurrentUserProfile());
      dispatch(actions.workspaceCreated({ data }));
    })
    .catch((error) => {
      handleResponseError(error);
      dispatch(showApiMessages(error))
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createContactInfo = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createContactInfo(queryParams)
    .then((response) => {
      dispatch(successShowApiMessages(response))
      localStorage.setItem('accountContactInfo', JSON.stringify(queryParams));
      const { data } = response.data;
      dispatch(actions.workspaContactInfoCreated({ data }));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllEditions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllEditions(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.editionsFetched(data));
    })
    .catch((error) => {
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const purchasePlan = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .purchasePlan(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(successShowApiMessages(response))
      dispatch(actions.purchasePlanCreated({ data }));
      setTimeout(() => {
        window.location.href = '/wallet-status';
      }, 2000);
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      error.clientMessage = "Can't create workspace";
      handleResponseError(error);
      setTimeout(() => {
        if (error.response.data.messages[0] === 'Workspace not found') {
          window.location.href = '/dashboard';
        } else {
          handleResponseError(error);
        }
      }, 1000);

      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllSubscriptions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllSubscriptions(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.subscriptionsFetched(data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSubscription = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSubscription(id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.subscriptionFetched(data));
    })
    .catch((error) => {
      error.clientMessage = "Can't create workspace";
      // handleResponseError(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const workspacesStatus = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .workspacesStatus(id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.workspacesStatusUpdated(data));
    })
    .catch((error) => {
      // handleResponseError(error);
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const customercancelSub = (id, values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .customercancelSub(id, values)
    .then((response) => {
      const { data } = response.data;
      dispatch(getAllSubscriptions());
      dispatch(successShowApiMessages(response))
      setTimeout(() => {
        dispatch(modalActions.hideModal())
      }, 2000);
      dispatch(actions.customercancelSub(data));
    })
    .catch((error) => {
      dispatch(showApiMessages(error))
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const changeTab = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changeTab(queryParams)
    .then((response) => {
      dispatch(actions.changeTab(response));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setGlobalValue = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setGlobalValue(queryParams)
    .then((response) => {
      dispatch(actions.setGlobalValue(response));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const transferWorkspace = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .transferWorkspace(queryParams)
    .then((response) => {
      dispatch(successShowApiMessages(response))
      const { data } = response.data;
      setTimeout(() => {
        window.location.href = '/pricing';
      }, 2000);
      dispatch(actions.workspaceCreated({ data }));
    })
    .catch((error) => {
      setTimeout(() => {
        window.location.href = '/pricing';
      }, 2000);
      handleResponseError(error);
      dispatch(showApiMessages(error))
      error.clientMessage = "Can't create workspace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
