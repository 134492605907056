import axios from 'axios';
import { authInstance, axiosInstance } from 'helpers/base.service';
export const REG = '/users/register';
export const OAUTH = '/oauth/token';
export const CURRENTPROFILE = '/users/profile';
export const GMAILACCOUNTSIGNUPURL = 'users/register/phone-number';
export const FORGOTPASSWORD = 'oauth/forgot-password';
export const RESENDOTP = 'verification/re-send';
export const RESETPASSWORD = 'oauth/reset-password';
export const SETPASSWORD = 'oauth/reset-password';
export const CONFIRMUSER = 'verification/otp';
export const CONFIRMUSINGTOKEN = 'verification/token';
export const PROFILEEDIT = 'users/profile';


export function register(payload) {
  return axiosInstance().post(REG, payload);
}

export function login(payload) {
  return authInstance.post(OAUTH, payload);
}

export function getCurrentUserProfile() {
  return axiosInstance().get(CURRENTPROFILE);
}

export function googleLogin(payload) {
  return axios({
    method: 'POST',
    url: 'https://api.pay4cloud.com/users/gmail/signin',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    data: payload,
  });
}

export function googleSignupWithAdditionalData(payload) {
  return axiosInstance().post(GMAILACCOUNTSIGNUPURL, payload);
}

export function forgotPassword(payload) {
  return axiosInstance().post(FORGOTPASSWORD, payload);
}

export function resendOtp(payload) {
  return axiosInstance().post(RESENDOTP, payload);
}

export function setPassword(payload) {
  return axiosInstance().post(SETPASSWORD, payload);
}

export function resetPassword(payload) {
  return axiosInstance().post(RESETPASSWORD, payload);
}

export function confirmUser(payload) {
  return axiosInstance().post(CONFIRMUSER, payload);
}

export function confirmUserUsingToken(payload) {
  return axiosInstance().post(CONFIRMUSINGTOKEN, payload);
}

export function showApiMessages(payload) {
  return new Promise((resolve, reject) => {
    if(payload){
      resolve(payload)
    }else{
      reject(new Error('no data'))
    }
  })
}

export function successShowApiMessages(payload) {
  return new Promise((resolve, reject) => {
    if(payload){
      resolve(payload)
    }else{
      reject(new Error('no data'))
    }
  })
}

export function editProfile(payload) {
  return axiosInstance().patch(PROFILEEDIT, payload);
}

