import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthStyles } from 'assets/scss/components/_auth';
import { workspacesStatus } from 'modules/adduser/_redux/accounts/accountsActions';
import { modalActions } from 'modules/modals/_actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const WorkspaceStatus = ({ modalID, data }) => {
    const dispatch = useDispatch();
    const { actionsLoading,workspaceStatus  } = useSelector((state) => state.workspace);
    const location = useNavigate();
 
    useEffect(() => {
        dispatch(workspacesStatus())
    }, [dispatch])


    const classes = useAuthStyles();
    const proceedToPurchase = () => {
        location('/pricing', '');
        dispatch(modalActions.hideModal(modalID));
    }

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal-twisted">
            <div className="select-file p-10">
                <h3>  {actionsLoading ? <CircularProgress
                    size={24}
                    color="darkloader"
                    className={classes.buttonProgress}
                /> : `${workspaceStatus?.status ? 'You can Proceed with the purchase' : workspaceStatus?.reason }`}</h3>
                <div className="btn-group-actions site-modal__actions">
                    <Button
                        style={{ border: 'thin solid #00ACFF', color: '#00ACFF' }}
                        onClick={() => proceedToPurchase()}
                        disabled={!workspaceStatus?.status}
                    >
                        {actionsLoading ? 'Checking workspace status' : 'Continue'}
                        {(actionsLoading) && (
                            <CircularProgress
                                size={24}
                                color="darkloader"
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                    <Button
                        color="primary"
                        className="danger"
                        variant="outlined"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};
