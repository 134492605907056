import { Provider } from 'react-redux';
import { MemoizedHeader } from 'components/header/header';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CloudWalletTheme from 'theme/theme';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import './assets/scss/style.scss';
import Login from 'modules/authentication/login';
import AddGoogleWorkSpaceUser from 'modules/adduser/AddGoogleWorkSpaceUser';
import Homepage from 'components/homepage';
import PricingPage from 'modules/pricing';
import Footer from 'components/footer';
import SuccessOnSignup from 'modules/authentication/signup/successOnSignup';
import NotFoundPage from 'components/common/inputs/404';
import './assets/fonts/stylesheet.css';
import PaymentPlan from 'modules/payment/plan/PaymentPlan';
import LoadWalletPayment from 'modules/payment/wallet/LoadWalletPayment';
import ForgotPassword from 'modules/authentication/passwordReset/ForgotPassword';
import ResetPassword from 'modules/authentication/passwordReset/resetPassword';
import DashboardHomepage from 'components/dashboard/homepage';
import ConfirmPageOnEmailVerification from 'modules/authentication/signup/confirmPageOnAccountVerification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrentPlan from 'modules/pricing/currentPlan';
import ProtectedRoute from 'routes/protectedRoutes';
import CurrentSubscription from 'modules/payment/plan/subscription';
import PaymentSuccess from 'modules/payment/paymentSuccess/paymentSuccess';
import MyWalletHomepage from 'components/mywallet/wallet';
import EditProfile from 'modules/authentication/userProfile/editProfile';
import EditPlan from 'modules/pricing/editPlan';
import EditPaymentPlan from 'modules/payment/plan/EditPaymentPlan';
import BigClouds from './assets/images/clouds_bg/big_cloud_bg.svg';
import SmallClouds from './assets/images/clouds_bg/small_cloud_bg.svg';
import MediumClouds from './assets/images/clouds_bg/medium_cloud_bg.svg';
import extraSmallClouds from './assets/images/clouds_bg/extra_small_cloud_bg.svg';
import SideCloud from './assets/images/clouds_bg/side_cloud_bg.svg';
import MyWalletTransactionspage from 'components/mywallet/TransActions';
import MyWalletInvoicespage from 'components/mywallet/Invoices';
import ChangePlan from 'modules/payment/plan/changePlan';
import Support from 'modules/support';
import SystemUsersContainer from 'components/dashboard/SystemUsers';
import { ModalRoot } from 'modules/modals';
import { Logout } from '@mui/icons-material';
import { Container } from '@mui/material';
import { CustomerSubScriptions } from 'components/dashboard/SuperAdmin/customerSubScriptions';
import Googlesignup from 'modules/authentication/google/google';
import AdminCustomerTransactionspage from 'components/dashboard/SuperAdmin/TransActions';
import AdminSupportTicketsDashboardpage from 'components/dashboard/SuperAdmin/support';
import SuperAdminMessages from 'modules/admin/messages';
import SetPassword from 'modules/authentication/passwordReset/setPassword';
import ResendOtp from 'modules/authentication/passwordReset/ResendOtp';
import AboutUs from 'modules/aboutus/about';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './trusted-security-policies';
import Legal from 'modules/legal';
import Privacy from 'modules/legal/privacy';


const useStyles = makeStyles(() => ({
  content: {
    width: '100%',
    maxWidth: '100%',
    padding: '0',
    backgroundImage: `url(${extraSmallClouds})`,
    backgroundPosition: 'right 412px top 43px',
    backgroundRepeat: 'no-repeat',
  },
  content_withbg_1: {
    backgroundImage: `url(${BigClouds})`,
    backgroundPosition: 'top 225px left 40px',
    backgroundRepeat: 'no-repeat',
  },
  content_withbg_2: {
    backgroundImage: `url(${MediumClouds})`,
    backgroundPosition: 'top 12% left 45%',
    backgroundRepeat: 'no-repeat',
  },
  content_withbg_3: {
    backgroundImage: `url(${SmallClouds})`,
    backgroundPosition: 'right 650px top 60px',
    backgroundRepeat: 'no-repeat',
  },
  content_side_withbg_4: {
    backgroundImage: `url(${SideCloud})`,
    backgroundPosition: 'right 131px top 580px',
    backgroundRepeat: 'no-repeat',
  },
}));

function App({ store, persistor }) {
  const classes = useStyles();
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <ThemeProvider theme={CloudWalletTheme}>
          <CssBaseline />
          <ToastContainer autoClose={5000} />
          <BrowserRouter>
            <ModalRoot />
            <Container
              maxWidth={false}
              className={classes.content}
              role="main"
            >
              <div className={classes.content_withbg_1}>
                <div className={classes.content_withbg_2}>
                  <div className={classes.content_withbg_3}>
                    <div className={classes.content_side_withbg_4}>
                      <MemoizedHeader />
                      <Routes>
                        <Route path="/" element={<Homepage />} exact />
                        <Route path="/login" element={<Login />} exact />
                        <Route path="/forgot-password" element={<ForgotPassword />} exact />
                        <Route path="/auth/set-password" element={<ResetPassword />} exact />
                        <Route path="/set-password" element={<SetPassword />} exact />
                        <Route path="/onboarding" element={<AddGoogleWorkSpaceUser />} exact />
                        <Route path="/signup/success" element={<SuccessOnSignup />} exact />
                        <Route path="/signup/resend-otp" element={< ResendOtp />} exact />
                        <Route path="/confirm" element={<ConfirmPageOnEmailVerification />} exact />
                        <Route path="/pricing" element={<PricingPage />} exact />
                        <Route path="/about-us" element={<AboutUs />} exact />
                        <Route path="/terms-and-conditions" element={<Legal />} exact />
                        <Route path="/privacy-policy" element={<Privacy />} exact />
                        <Route path="/support" element={<Support />
                        } exact />
                        <Route path="/google-signup" element={<Googlesignup />} exact />
                        <Route
                          path="/logout"
                          element={<ProtectedRoute
                            path="/logout"
                            component={Logout}
                            exact
                          />}
                          exact
                        />
                        <Route path="/dashboard/customer/:id/subscriptions" element={<ProtectedRoute path="/dashboard/customer/:id/subscriptions" component={CustomerSubScriptions} exact />} exact />
                        <Route path="/dashboard/system-users" element={<ProtectedRoute path="/dashboard/system-users" component={SystemUsersContainer} exact />} exact />
                        <Route path="/payment-plan" element={<ProtectedRoute path="/payment-plan" component={PaymentPlan} exact />} exact />
                        <Route path="/edit-plan" element={<ProtectedRoute path="/edit-plan" component={EditPaymentPlan} exact />} exact />
                        <Route path="/edition/current-plan" element={<ProtectedRoute path="/edition/current-plan" component={CurrentPlan} exact />} exact />
                        <Route path="/edit/plan" element={<ProtectedRoute component={EditPlan} exact />} exact />
                        <Route path="/change-plan" element={<ProtectedRoute path="/change-plan" component={ChangePlan} exact />} exact />
                        <Route path="/subscription" element={<ProtectedRoute path="/subscription" component={CurrentSubscription} exact />} exact />
                        <Route path="/wallet-status" element={<ProtectedRoute path="/wallet-status" component={LoadWalletPayment} exact />} exact />
                        <Route path="/payment/success" element={<ProtectedRoute path="/payment/success" component={PaymentSuccess} />} />
                        <Route path="/my-wallet" element={<ProtectedRoute component={MyWalletHomepage} />} />
                        <Route path='/my-wallet/transactions' element={<ProtectedRoute component={MyWalletTransactionspage} />} />
                        <Route path='/dashboard/tickets' element={<ProtectedRoute component={AdminSupportTicketsDashboardpage} />} />
                        <Route path='/dashboard/messages' element={<ProtectedRoute component={SuperAdminMessages} />} />
                        <Route path='/dashboard/customers/transactions/:id' element={<ProtectedRoute component={AdminCustomerTransactionspage} />} />
                        <Route path='/my-wallet/invoices' element={<ProtectedRoute component={MyWalletInvoicespage} />} />
                        <Route
                          exact
                          path="/dashboard"
                          element={<ProtectedRoute
                            exact
                            path="/dashboard"
                            component={DashboardHomepage}
                          />}
                        />
                        <Route path="/edit-profile" element={<ProtectedRoute path="/edit-profile" component={EditProfile} />} exact />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            <Footer />
            {/* <DashboardFooter /> */}
          </BrowserRouter>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}
export default App;
