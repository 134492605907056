import {
  Button, CircularProgress, Grid, MenuItem, Select, TextareaAutosize, Typography,
} from '@mui/material';
import { useAuthStyles } from 'assets/scss/components/_auth';
import TextFieldItem from 'components/common/inputs/TextField';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import 'modules/authentication/signup/signup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { supportTicketAdd } from './_redux/support/supportActions';
import { ApiMessages } from 'components/common/ApiMessages/apimessages';
import { SuccessApiMessages } from 'components/common/SuccessApiMessages/successapimessages';
import { _renderHelperText } from 'utils';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import './index.scss'
import { useRecaptcha } from 'react-hook-recaptcha';

const sitekey = '6LchDLUiAAAAAKA1yMCxtjD7lygkMUEtSBOH8q1z'; // change to your site key
const containerId = 'test-recaptcha'; // this id can be customized

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  title: yup
    .string('Enter your title')
    .required('Title is required'),
  firstName: yup
    .string('Enter your firstName')
    .required('First Name is required'),
  lastName: yup
    .string('Enter your last Name')
    .required('Last Name is required'),
  description: yup
    .string('Enter your description')
    .required('Description is required'),
  phoneNumber: yup
    .string('Enter your Phone Number')
    .required('Phone Number is required'),
  supportType: yup
    .string('Enter your support Type')
    .required('Support Type is required'),

});

const choices = ['Billing', 'Sale', 'Technical Support']
const Support = () => {

  const [first, setfirst] = useState()
  const successCallback = (response) => {
    const inputNameValue = document.querySelector('#name').value;
    alert(`Hello ${inputNameValue} \n Recaptcha Response is: ${response}`);
    setfirst(response)
  };

  const { recaptchaLoaded, execute, reset } = useRecaptcha({
    containerId,
    successCallback,
    sitekey,
    size: 'invisible',
  });

  useEffect(() => {
    executeCaptcha()
   if(recaptchaLoaded){
    console.log(first)
    
   }
  }, [recaptchaLoaded])
  
  const executeCaptcha = () => {
    if (recaptchaLoaded) {
      reset();
      execute();
    }
  };

  const classes = useAuthStyles();
  const dispatch = useDispatch();

  const { actionsLoading } = useSelector((state) => state.support);


  const submitSupport = useCallback(async (values, resetForm) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      title: values.title,
      supportType: values.supportType,
      description: values.description,
      phoneNumber: values.phoneNumber,
    }
    dispatch(supportTicketAdd(payload, resetForm))


  }, [dispatch]);


  return (
    <div className='support' style={{ padding: '0 7.2vw' }}>
      <Grid container spacing={3} style={{ marginLeft: 0, width: '100%' }}>
        <Grid item xs={12} md={4}>
          <Typography fontSize="43px" fontWeight="bold" color="#233168">Hit Us Up!</Typography>
          <Typography pt="4rem">You’ve taken the first step towards embracing change. Well done!</Typography>
          <br />
          <Typography>Give us a call at:</Typography>
          <Typography fontSize="43px" fontWeight="bold" color="#233168">+254 (0) 746621402</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <ApiMessages />
          <SuccessApiMessages />
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              title: '',
              supportType: '',
              description: '',
              phoneNumber: '',
              recaptcha: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              executeCaptcha()
              submitSupport(values, resetForm)
            }}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <TextFieldItem
                  name="title"
                  icon="envelope"
                  hasIcon
                  label="Title"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  validationField={props.touched.title}
                  value={props.values.title}
                  error={props.errors.title}
                />
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs>
                    <TextFieldItem
                      name="firstName"
                      label="First Name"
                      icon="user"
                      hasIcon
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      validationField={props.touched.firstName}
                      value={props.values.firstName}
                      error={props.errors.firstName}
                      fullWidth={false}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextFieldItem
                      name="lastName"
                      icon="user"
                      hasIcon
                      label="Last Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      validationField={props.touched.lastName}
                      value={props.values.lastName}
                      error={props.errors.lastName}
                      fullWidth={false}
                    />
                  </Grid>
                </Grid>
                <TextFieldItem
                  name="email"
                  icon="envelope"
                  hasIcon
                  label="Email Address"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  validationField={props.touched.email}
                  value={props.values.email}
                  error={props.errors.email}
                />
                <PhoneInput
                  country="ke"
                  value={props.values.phoneNumber}
                  onChange={(value, data, event, formattedValue) => props.setFieldValue('phoneNumber', formattedValue)}
                  specialLabel="Phone Number"
                  enableSearch={true}
                  autocompleteSearch={true}
                  style={{
                    margin: '10px 0',
                  }}
                  inputStyle={{
                    width: '100%',
                    padding: '12px 53px',
                  }}
                  searchStyle={{
                    width: '100%',
                  }}
                  inputProps={{
                    name: 'phoneNumber',
                    required: true,
                  }}
                />
                {_renderHelperText(props.errors.phoneNumber)}
                <Select
                  name="supportType"
                  size="small"
                  placeholder="Support Type"
                  variant="outlined"
                  onChange={props.handleChange}
                  sx={{ margin: '8px 0 8px 0', width: '50%' }}
                  value={props.values.supportType}
                >

                  {choices.map((item, index) => (
                    <MenuItem className={classes.option} key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {_renderHelperText(props.errors.supportType)}
                <TextareaAutosize
                  aria-label="minimum height"
                  name="description"
                  label="Description"
                  onChange={props.handleChange}
                  minRows={3}
                  placeholder="Write your description"
                  style={{
                    width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #CACACA', marginTop: '1.5rem',
                  }}
                />
                {_renderHelperText(props.errors.description)}
             
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={!recaptchaLoaded}
                  className={classes.submit}
                  sx={{ margin: '2rem 0 2rem 0' }}
                >
                  Submit
                  {(actionsLoading) && (
                    <CircularProgress
                      size={24}
                      color="loader"
                      className={classes.buttonProgress}
                    />
                  )}

                </Button>
                <div id={containerId} />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default Support;
