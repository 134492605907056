import { createSlice } from '@reduxjs/toolkit';

const initialAdminState = {
  listLoading: false,
  support_tickets: [],
  actionsLoading: false,
  markasreso: false,
  inprogress: false,
  totalElements: null
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const supportSlice = createSlice({
  name: 'support',
  initialState: initialAdminState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = null;
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;

      }
    },
    startCallMarkAsResolved: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.markasreso = true;
      }
    },
    startCallInProgress: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.inprogress = true;

      }
    },
    supportsTicketsFetched: (state, action) => {
      state.listLoading = false;
      state.support_tickets = action.payload.content;
      state.totalElements = action.payload.totalElements;
      state.markasreso = false;
      state.inprogress = false;
      state.error = null;
    },
    supportTicketAdd: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    supportTicketMarkAsRead: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    supportTicketMarkAsResolved: (state, action) => {
      state.markasreso = false;
      state.error = null;
    },
    supportTicketMarkInProgress: (state, action) => {
      state.inprogress = false;
      state.error = null;
    },
  },
});

const { reducer } = supportSlice;
export default reducer;
