import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  loading: null,
  data: null,
  currentUserProfileStatus: null,
  currentUserProfile: null,
  editProfile: null,
  getUserProfileImageStatus: null,
  userProfileImage: null,
  googleSignupWithAdditionalData:  null,
  googleSignupStatus: null,
  actionsLoading: false,
  setPassword: null,
  usermessageloading: false,
  showApiMessages: null,
  successShowApiMessages: null,
  resendOtp: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const oauthenticationSlice = createSlice({
  name: 'oauth',
  initialState: initialAuthState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = null;
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    startShowApiMessages: (state, action) => {
      state.error = null;
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.usermessageloading = true;
      }
    },
    loginUser: (state, action) => {
      state.actionsLoading = false;
      state.loginUser = action.payload;
      state.error = null;
    },
    register: (state, action) => {
      state.actionsLoading = false;
      state.register = action.payload;
      state.error = null;
    },
    googleLogin: (state, action) => {
      state.actionsLoading = false;
      state.googleLogin = action.payload;
      state.error = null;
    },
    googleSignupHandler: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    googleLoginHandler: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    googleSignup: (state, action) => {
      state.actionsLoading = false;
      state.googleSignup = action.payload;
      state.error = null;
    },
    googleSignupWithAdditionalData: (state, action) => {
      state.actionsLoading = false;
      state.googleSignupWithAdditionalData = action.payload;
      state.error = null;
    },
    forgotPassword: (state, action) => {
      state.actionsLoading = false;
      state.forgotPassword = action.payload;
      state.error = null;
    },
    resetPassword: (state, action) => {
      state.actionsLoading = false;
      state.resetPassword = action.payload;
      state.error = null;
    },
    setPassword: (state, action) => {
      state.actionsLoading = false;
      state.setPassword = action.payload;
      state.error = null;
    },
    confirmUser: (state, action) => {
      state.actionsLoading = false;
      state.confirmUser = action.payload;
      state.error = null;
    },
    resendOtp: (state, action) => {
      state.actionsLoading = false;
      state.resendOtp = action.payload;
      state.error = null;
    },
    confirmUserUsingToken: (state, action) => {
      state.actionsLoading = false;
      state.confirmUserUsingToken = action.payload;
      state.error = null;
    },
    currentUserProfile: (state, action) => {
      state.actionsLoading = false;
      state.currentUserProfile = action.payload;
      state.error = null;
    },
    getUserProfileImage: (state, action) => {
      state.actionsLoading = false;
      state.userProfileImage = action.payload;
      state.error = null;
    },
    showApiMessages: (state, action) => {
      state.usermessageloading = false
      state.showApiMessages = action.payload;
      state.error = null;
    },
    successShowApiMessages: (state, action) => {
      state.usermessageloading = false
      state.successShowApiMessages = action.payload;
      state.error = null;
    },
    profileEdited: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.editProfile = action.payload;
    },
  },
});

const { reducer } = oauthenticationSlice;
export default reducer;
