import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Privacy() {

    return (
        <Box style={{ padding: '150px 7.2vw' }}>
            <hr></hr>
            <Typography
                id="privacy"
                component="h2"
                variant="h2"
                align="center"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                DATA PRIVACY
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Account holders agree to the processing of their personal data in accordance with the terms of Pay4Cloud’s Privacy Policy.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud shall process all personal data obtained through the Pay4Cloud and related services in accordance with the terms of our Privacy Policy and the Laws of Kenya.
            </Typography>
            <Typography
                component="h3"
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                I. Information collected, usage, and sharing
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>Personal identification information including your name, address, identification documentation type and number, date of birth, age, address, gender, phone number, location, and photograph;</li>
                <li>
                    describe in specific detail the copyright work subject to the alleged infringement or sought to be removed;</li>
                <li>Information about your bank account number, debit and credit card information or other relevant banking information;
                </li>
                <li>Transaction records every time you transact using our website;
                </li>
                <li>Information about the type of your device, third party information relevant to us for the provision of services, (eg. paid subscriptions and details of those accounts); and
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud will only use the information to (this is not an exhaustive list):
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>Process goods and services purchased from us or from third parties on our platform;</li>
                <li>Reply to you when you contact us with any inquiries or concerns;</li>
                <li>Update you on our products and services;
                </li>
                <li>Billing you for using our products and services;
                </li>
                <li>Verify your identity to the fullest extent possible; and
                </li>
                <li>Carry out credit checks and analysis.
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We will not share your information with any third party outside our organization except where it is necessary to fulfill your requests.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Unless you ask us otherwise, we may contact you via email with updates on our services and products, changes in terms and conditions, and policies.
            </Typography>
            <Typography
                component="h3"
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                II. Access to and Control over Your Information
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                At any time, you may opt out of any communication from us by contacting us via email or through the mailing address provided here.
            </Typography>
            <Typography
                component="h3"
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                III. Cookies
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >Our website uses cookies and related tracking technologies.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud may collect and store cookies on your device every time you visit our website to enable us to recognize and identify you when you visit our website in the future.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We may also use this information collectively to customize our services and products to your personal needs. If you want this option, you will be prompted to select the type of cookies you wish to allow us to collect.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Pay4Cloud shall be directly responsible to you for any misuse of your personal data.
            </Typography>
            <Typography
                component="h3"
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                Links to Other Websites
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                This site may contain links to other websites. We are not responsible for any content, opinions or representations expressed by such sites or the accuracy of such information. Presence of any link to a website on our site does not imply our affiliation with, approval of, or endorsement  by us of those websites. If you decide to leave our site and visit those third-party sites, you do so at your own risk. We strongly recommend that you read and understand the terms and conditions and accompanying policies in those sites.
            </Typography>
            <Typography
                component="h3"
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                Security
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                The information you voluntarily provide Pay4Cloud through use of this website will be stored on  our servers located within our offices.
            </Typography>

            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We follow generally accepted industry standards and guidelines set out under the Data Protection Act No.24 of 2019 Laws of Kenya and its accompanying Regulations, to protect your personally identifiable information, both during transmission and once we receive it; including the use of firewalls and other Internet security measures.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                However, no method of transmission over the Internet or method of electronic storage is 100% secure, and CloudPayments cannot guarantee its absolute security. Accordingly, CloudPayments expressly disclaims any such obligation.
            </Typography>
            <Typography
                component="h3"
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                Due diligence and audit rights
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >We have zero tolerance for money-laundering and shall operate a strict anti-money laundering compliance program and reserve the right to perform due diligence checks on all users of the Pay4Cloud.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >You agree to provide to us all such information, documentation and access to your business premises as we may require:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>in order to verify your adherence to, and performance of, your obligations under this Agreement;
                </li>
                <li>Reply to you when you contact us with any inquiries or concerns;</li>
                <li>for the purpose of disclosures pursuant to a valid order by a court or other governmental body; or
                </li>
                <li>as otherwise required by law or applicable regulation.
                </li>
            </ul>
            <Typography
                component="h3"
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
            >
                Pay4Cloud’s Obligations
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >You acknowledge that:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>we do not confirm the identity of all Pay4Cloud users, check their credit worthiness or bona fides, or otherwise vet them;
                </li>
                <li>we do not check, audit or monitor all information contained in listings;</li>
                <li>We are not party to any contract for the sale or purchase of products advertised on Pay4Cloud;.
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >We do not warrant or represent:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>the completeness or accuracy of the information published on our Pay4Cloud;
                </li>
                <li>that the material on the Pay4Cloud is up to date;</li>
                <li>that the Pay4Cloud will operate without fault; or
                </li>
                <li>that the Pay4Cloud will operate without fault; or
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We reserve the right to discontinue or alter any or all of our Pay4Cloud services, and to stop publishing our Pay4Cloud, at any time in our sole discretion without notice or explanation; and you will not be entitled to any compensation or other payment upon the discontinuance or alteration of any Pay4Cloud services, or if we stop publishing the Pay4Cloud.:
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                We do not guarantee any commercial results concerning the use of the Pay4Cloud.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                To the maximum extent permitted by applicable law and subject to the provisions of this agreement, we exclude all representations and warranties relating to the subject matter of these general terms and conditions, our Pay4Cloud and the use of our Pay4Cloud.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Modification of Terms And Conditions
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                From time to time Pay4Cloud may, and shall, modify the general terms and conditions for any one or more of any of the following purposes:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>To comply with amendments to the laws and regulations governing it;
                </li>
                <li>To effect correction or recertification of any ambiguities, defective provisions, inaccuracies, errors or omissions herein; and</li>
                <li>that the Pay4Cloud will operate without fault; or
                </li>
                <li>For any other purpose as we may deem necessary from time to time.
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                The revised general terms and conditions shall apply from the date of publication on the Pay4Cloud website.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >
                Notice of Change in Terms and Conditions
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >In the event of a material change to the terms and conditions of this Agreement, Pay4Cloud will notify the User in writing prior to auto renewal or prior to their accessing of the website. The users will be required to consent to the new terms.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Limitations and exclusions of liability
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Nothing in these general terms and conditions will:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>limit any liabilities in any way that is not permitted under applicable law; or
                </li>
                <li>exclude any liabilities or statutory rights that may not be excluded under applicable law.</li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                The limitations and exclusions of liability set out in this section and elsewhere in these general terms and conditions:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>are subject to the provisions of this section  above; and
                </li>
                <li>govern all liabilities arising under these general terms and conditions or relating to the subject matter of these general terms and conditions, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty, except to the extent expressly provided otherwise in these general terms and conditions.</li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                In respect of the services offered to you free of charge we will not be liable to you for any loss or damage of any nature whatsoever.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Our aggregate liability to you in respect of any contract to provide services to you under these general terms and conditions shall not exceed the total amount paid and payable to us under the contract. Each separate transaction on the Pay4Cloud shall constitute a separate contract for the purpose of this section.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Notwithstanding the paragraph above, we will not be liable to you for any loss or damage of any nature, including in respect of:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>any losses occasioned by any interruption or dysfunction to the website;
                </li>
                <li>any losses arising out of any event or events beyond our reasonable control;</li>
                <li>any business losses, including (without limitation) loss of or damage to profits, income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities or goodwill;</li>
                <li>any loss or corruption of any data, database or software; or</li>
                <li>any special, indirect or consequential loss or damage.</li>
            </ul>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Indemnification
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You hereby indemnify us, and undertake to keep us indemnified, against:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>any and all losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by us to any third party in settlement of a claim or dispute) incurred or suffered by us and arising directly or indirectly out of your use of our Pay4Cloud or any breach by you of any provision of these general terms and conditions or the Pay4Cloud codes, policies or guidelines; and
                </li>
                <li>any VAT liability or other tax liability that we may incur in relation to any sale, supply or purchase made through our Pay4Cloud, where that liability arises out of your failure to pay, withhold, declare or register to pay any VAT or other tax properly due in any jurisdiction.</li>
            </ul>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Breaches of these general terms and conditions
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                If we permit the registration of an account on our Pay4Cloud it will remain open indefinitely, subject to these general terms and conditions. If you wish to terminate your account, contact us in the manner provided herein.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                If you breach these general terms and conditions, or if we reasonably suspect that you have breached these general terms and conditions or any Pay4Cloud codes, policies or guidelines in any way we may:
            </Typography>
            <ul style={{ fontSize: '.9em', fontWeight: 500 }}>
                <li>temporarily suspend your access to our Pay4Cloud;
                </li>
                <li>permanently prohibit you from accessing our Pay4Cloud;</li>
                <li>block computers using your IP address from accessing our Pay4Cloud;
                </li>
                <li>contact any or all of your internet service providers and request that they block your access to our Pay4Cloud;
                </li>
                <li>suspend or delete your account on our Pay4Cloud; and/or
                </li>
                <li>commence legal action against you, whether for breach of contract or otherwise.
                </li>
            </ul>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Where we suspend, prohibit or block your access to our Pay4Cloud or a part of our Pay4Cloud you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).
            </Typography>

            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Entire agreement
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                These general terms and conditions and the Pay4Cloud codes, policies and guidelines shall constitute the entire agreement between you and us in relation to your use of our Pay4Cloud and shall supersede all previous agreements between you and us in relation to your use of our Pay4Cloud.
            </Typography>

            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Severability
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                If a provision of these general terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                If any unlawful and/or unenforceable provision of these general terms and conditions would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Assignment
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You hereby agree that we may assign, transfer, sub-contract or otherwise deal with our rights and/or obligations under these general terms and conditions.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                You may not without our prior written consent assign, transfer, sub-contract or otherwise deal with any of your rights and/or obligations under these general terms and conditions.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Third party rights
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                A contract under these general terms and conditions is for our benefit and your benefit, and is not intended to benefit or be enforceable by any third party.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                The exercise of the parties' rights under a contract under these general terms and conditions is not subject to the consent of any third party.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Law and jurisdiction
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                These general terms and conditions shall be governed by and construed in accordance with the laws of Kenya.
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Any disputes relating to these general terms and conditions shall be settled by arbitration pursuant to the Constitution of Kenya 2010, the Arbitration Act 1995.
            </Typography>
            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Your Acceptance of These Terms and Conditions
            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                Any disputes relating to these general terms and conditions shall be settled by arbitration pursuant to the Constitution of Kenya 2010, the Arbitration Act 1995.
            </Typography>

            <Typography
                variant="h3"
                align="left"
                color="text.primary"
                style={{ paddingBottom: '10px' }}
                component="h3"
            >Contact us

            </Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >
                If you have any questions regarding these Terms and Conditions, the operations of this site, or you dealings in this site, please contact us at: <b>+254 (0) 746 621 402</b></Typography>
            <Typography
                variant="p"
                align="left"
                color="text.primary"
                component="p"
            >These terms and conditions last updated and operation as of 20/09/2022. By accessing this website or using any of its components, you acknowledge that you have read, understood and accepted the Terms and Conditions, and all accompanying policies and guidelines.
            </Typography>
        </Box>
    );
}

export default Privacy;
