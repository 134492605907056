import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import PlansAccordion from '../accordion';
import close from '../../../assets/images/pret.svg';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'react-redux';
import { Accordion, AccordionSummary } from '@mui/material';

function PriceModal(props) {
  const {
    variant, tier, text, testid, nextStage, title
  } = props;
  const [open, setOpen] = useState(false);
  const [openall, ] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const onSelectPlan = (tier) => {
    const selected = { selected: true, ...tier };
    Cookies.set('currentPlan', JSON.stringify({ ...selected }));
  };

  const {
     subscriptions,
  } = useSelector(
    (state) => ({
      actionsLoading: state.workspace.actionsLoading,
      subscriptions: state.workspace.subscriptions,
      lastError: state.workspace.lastError,
    }),
    shallowEqual,
  );

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // getting an error form yarn test
  const handleNextStage = () => {
    onSelectPlan(tier)
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Button
        onClick={handleClickOpen('paper')}
        style={{
          border: 'thin solid #18A0FB', color: '#18A0FB', padding: '4px .8em',
        }}
        data-testid={testid}
        fullWidth={props?.fullWidth}
      >
        {text}
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Typography
          onClick={handleClose}
          sx={{
            position: 'absolute', right: 0, padding: ' 45px 45px ', cursor: 'pointer',
          }}
        >
          <img src={close} alt="close button" />
        </Typography>
        <Typography sx={{ width: '100%', flexShrink: 0, padding: ' 45px 45px 45px 25px ' }}>
          Business Starter, Business Standard, and Business
          Plus plans can be purchased for a maximum of 300 users.
          There is no minimum or maximum user limit for Enterprise plans.
        </Typography>
        <div style={{ position: 'relative' }}>
          <DialogTitle id="scroll-dialog-title">
            Productivity & Collaboration
          </DialogTitle>
        </div>
        <br />
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Accordion style={{ boxShadow: 'none' }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: '38%', flexShrink: 0 }}>
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0, }}>{title === 'Business Starter' ? <span style={{ background: '#18a0fb', color: '#fff', fontWeight: 'bold', borderRadius: '10px', padding: '10px' }}>Business Starter</span> : 'Business Starter'}</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>{title === 'Business Standard' ? <span style={{ background: '#18a0fb', color: '#fff', fontWeight: 'bold', borderRadius: '10px', padding: '10px' }}>Business Standard</span> : 'Business Standard'}</Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            {title === 'Business Plus' ? <span style={{ background: '#18a0fb', color: '#fff', fontWeight: 'bold', borderRadius: '10px', padding: '10px' }}>Business Plus</span> : 'Business Plus'}
          </Typography>
          <Typography sx={{ width: '14%', flexShrink: 0 }}>
            {title === 'Enterprise' ? <span style={{ background: '#18a0fb', color: '#fff', fontWeight: 'bold', borderRadius: '10px', padding: '10px' }}>Enterprise</span> : 'Enterprise'}
          </Typography>
        </AccordionSummary>
      </Accordion>
            <PlansAccordion title={title} open={openall} />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '30px' }}>
          <Button role="button" variant={variant} onClick={handleClose}>Back to Plans</Button>
          <Link to={subscriptions?.length > 0 ? `/dashboard` : '/edition/current-plan'} style={{ textDecoration: 'none', margin: '0 10px'}}>
          <Button role="button" variant={variant} onClick={handleNextStage}>{nextStage ? 'Continue' : 'Subscribe'}</Button>
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PriceModal;
